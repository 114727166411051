import React, { Component } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { errorToast } from "../blintzbox/ToastNotification";
import { MESSAGE } from "../../constants";
import GraphHeader from "./GraphHeader";
import CsvButton from "../input/CsvButton";

class Revenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(moment().subtract(5, "month")),
      endDate: new Date(),
      location: "",
      locScrollList: [],
      monthData: [],
      revenueData: [],
      graphDataSet: null,
      graphOptions: null,
      yAxisData: null,
      csvData: null,
    };
  }

  async componentDidMount() {
    const { startDate, endDate } = this.state;
    const newStartDate = moment(startDate).format("L");
    const newEndDate = moment(endDate).format("L");
    await this.props.getLocationList("", 0, 10000000, false);
    const completeList = [{ id: "", name: "All Locations" }];
    this.props.locations &&
      this.props.locations.list &&
      this.props.locations.list.map((ele) => completeList.push(ele));
    this.setState({ locList: completeList });
    await this.props.getRevenueStatics("", newStartDate, newEndDate, false);
    this.datablintzboxGraph();
  }

  datablintzboxGraph = async () => {
    const month = [];
    const revenue = [];
    const { revenue_statics } = this.props;
    (await revenue_statics) &&
      revenue_statics.forEach((ele) => {
        month.push(ele.name + "," + ele.year);
        revenue.push(parseFloat(ele.count).toFixed(2));
      });
    await this.setState({
      monthData: month,
      revenueData: revenue,
    });
    await this.yAxisVAlues();
    this.graphData();
    this.csvFileData();
  };
  yAxisVAlues = () => {
    const { revenueData } = this.state;
    const maxRevenue = Math.max(...revenueData);
    let maxValueData;
    maxValueData = Math.floor(maxRevenue / 5) * 5 + 5;
    this.setState({
      yAxisData: maxValueData,
    });
  };
  csvFileData = async () => {
    const { monthData, revenueData } = this.state;
    const csvArray = [];
    await monthData.forEach((ele, i) => {
      csvArray.push({
        month: ele,
        revenue: revenueData[i],
      });
    });
    this.setState({
      csvData: csvArray,
    });
  };
  graphData = async () => {
    const { monthData, revenueData, yAxisData } = this.state;
    const data = {
      labels: monthData,
      datasets: [
        {
          label: "Revenue($)",
          fill: false,
          lineTension: 0.03,
          backgroundColor: "#ffd938",
          borderColor: "#ffd938",
          pointBorderColor: "#ffd938",
          pointBackgroundColor: "white",
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          data: revenueData,
        },
      ],
    };
    const options = {
      hover: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              max: yAxisData,
              stepSize: yAxisData / 5,
              fontColor: "black",
            },
            gridLines: {
              borderDash: [2, 5],
              color: "#e6e6e6",
              zeroLineColor: "#e6e6e6",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              borderDash: [0, 5],
              color: "#e6e6e6",
            },
            ticks: {
              fontColor: "#646464",
            },
          },
        ],
      },
    };
    await this.setState({
      graphDataSet: data,
      graphOptions: options,
    });
  };
  handleDateChange = (e, filter) => {
    this.setState({
      [filter]: e,
    });
  };
  getFilteredList = (e) => {
    this.setState({
      location: e.value,
    });
  };
  handleSubmit = async () => {
    const { startDate, endDate, location } = this.state;
    if (startDate && endDate) {
      const newStartDate = moment(startDate).format("L");
      const newEndDate = moment(endDate).format("L");
      await this.props.getRevenueStatics(location, newStartDate, newEndDate);
      this.datablintzboxGraph();
      this.csvFileData();
    } else {
      errorToast(1, MESSAGE.DATE_ERROR);
    }
  };
  render() {
    const {
      startDate,
      endDate,
      locList,
      graphDataSet,
      graphOptions,
      csvData,
    } = this.state;
    return (
      <div className="admin-right-detail-box user-management-detail-detail-box user-detail-box-with-header right-detail-bottom-gap dashboard-graph-box">
        <div className="user-detail-box-header">
          <h3>Revenue</h3>
          <div className="user-detail-box-header-right graph-detail-box-header">
            <GraphHeader
              handleSubmit={this.handleSubmit}
              handleDateChange={this.handleDateChange}
              getFilteredList={this.getFilteredList}
              startDate={startDate}
              endDate={endDate}
              locList={locList}
            />
            <CsvButton csvData={csvData} heading="REVENUE" />
          </div>
        </div>
        <div className="user-detail-box graph-detail-box">
          <div className="graph-detail-middle-box">
            <div className="graph-detail-left-side graph-revenue-left-side">
              <p>Income</p>
            </div>
            <div className="graph-detail-right-side">
              <Line data={graphDataSet || {}} options={graphOptions || {}} />
              <div className="graph-detail-footer">
                <p>Year</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.locationList || {},
  revenue_statics: state.revenue_statics || [],
});

export default connect(mapStateToProps, actions)(Revenue);
