import { beginLoaderCall, endLoaderCall } from ".";
import {
  getPanelReportData,
  getStaticsData,
  getRevenueData,
  getAllOrdersCount,
  getAllUsersCount,
} from "../services";
import {
  DASHBOARD_PANEL_REPORT,
  BLINTZBOX_STATICS,
  REVENUE_STATICS,
  DASHBOARD_ORDER_COUNT,
  DASHBOARD_CUSTOMER_COUNT,
} from "../actions";

export const getTotalCustomerCount = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return getAllUsersCount()
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: DASHBOARD_CUSTOMER_COUNT,
        data: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const getTotalOrdersCount = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return getAllOrdersCount()
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: DASHBOARD_ORDER_COUNT,
        data: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const getPanelReport = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return getPanelReportData()
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: DASHBOARD_PANEL_REPORT,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const getblintzboxStatics = (location, start, end) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getStaticsData(location, start, end)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: BLINTZBOX_STATICS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const getRevenueStatics = (location, start, end, status = true) => (
  dispatch
) => {
  if (status) dispatch(beginLoaderCall());
  return getRevenueData(location, start, end)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: REVENUE_STATICS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};
