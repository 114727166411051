import React from "react";
import DASHBOARD_BOOKING from "../../assets/images/svg/dashboard-1.svg";
import DASHBOARD_CUSTOMER from "../../assets/images/svg/dashboard-2.svg";
import DASHBOARD_INCOME from "../../assets/images/svg/dashboard-3.svg";
import { connect } from "react-redux";

import history from "../../store/history";
import { BOOKING_ROUTE, USER_ROUTE } from "../../constants";

const HeaderStatics = (props) => {
  const { totalCustomers, totalOrderData } = props;
  console.log("totalOrderData", totalOrderData);
  const handleRedirect = (route) => {
    history.push(route);
  };

  const handleValue = (value, type) => {
    if (value) {
      let oldValue = parseInt(value);
      const length = oldValue.toString().length;
      let newValue;
      switch (true) {
        case length < 7 && length > 3:
          newValue = parseInt(oldValue / 1000) + "K";
          break;
        case length < 10 && length > 6:
          newValue = parseInt(oldValue / 1000000) + "M";
          break;
        case length > 9:
          newValue = parseInt(oldValue / 1000000000) + "M";
          break;
        default:
          newValue = type === "income" ? parseFloat(value).toFixed(2) : value;
      }
      return newValue;
    }
  };
  return (
    <div className="dashboard-detail-wrapper">
      <div
        className="col-dashboard-detail-box"
        onClick={() => handleRedirect(BOOKING_ROUTE)}
      >
        <div className="dashboard-detail-box">
          <div className="dashboard-detail-head-section">
            <strong title={totalOrderData.totalOrders || 0}>
              {handleValue(totalOrderData.totalOrders, "booking") || 0}
            </strong>
            <p>Total Orders</p>
          </div>
          <img src={DASHBOARD_BOOKING} alt="dashboard-1" />
        </div>
      </div>
      <div
        className="col-dashboard-detail-box"
        onClick={() => handleRedirect(USER_ROUTE)}
      >
        <div className="dashboard-detail-box">
          <div className="dashboard-detail-head-section">
            <strong title={totalCustomers.totalUser || 0}>
              {handleValue(totalCustomers.totalUser, "user") || 0}
            </strong>
            <p>Total Customers</p>
          </div>
          <img src={DASHBOARD_CUSTOMER} alt="dashboard-1" />
        </div>
      </div>
      <div
        className="col-dashboard-detail-box"
        onClick={() => handleRedirect(BOOKING_ROUTE)}
      >
        <div className="dashboard-detail-box">
          <div className="dashboard-detail-head-section">
            <strong
              title={`₹${
                parseFloat(totalOrderData.totalRevenue).toFixed(2) || 0
              }`}
            >
              ${handleValue(totalOrderData.totalRevenue, "income") || 0}
            </strong>
            <p>Total Revenue</p>
          </div>
          <img src={DASHBOARD_INCOME} alt="dashboard-3" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  panelData: state.panelReportList || {},
  totalOrderData: state.totalOrderCount || {},
  totalCustomers: state.totalCustomers || {},
});

export default connect(mapStateToProps)(HeaderStatics);
