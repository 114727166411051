export const INVALID_PASSWORD = 'Use 8 or more characters';
export const INVALID_PHONE_NUMBER = 'Invalid Phone Number';
export const REQUIRE_PHONE_NUMBER = 'Enter Phone Number';
export const ENTER_EMAIL = 'Please enter email address';
export const INVALID_FULLNAME = 'Invalid Full Name';
export const REQUIRE_FULLNAME = 'Enter Full Name';
export const REQUIRE_PASSWORD = 'Enter Password';
export const INVALID_EMAIL = 'Invalid Email';
export const EMAIL_EXIST = 'Already exists!';
export const REQUIRE_EMAIL = 'Enter Email';
