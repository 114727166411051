import { beginLoaderCall, endLoaderCall, USER_DETAIL } from ".";
import {
  USER_ID,
  AUTH_TOKEN,
  MESSAGE,
  DEVICE_TYPE,
  DASHBOARD_ROUTE,
} from "../constants";
import { logInUser } from "../services";
import { setItem } from "../utils";
import { errorToast } from "../components/blintzbox/ToastNotification";
/**
 * Action to logged in
 * @param  {Object} payload
 * @param  {boolean} cb
 */
export const login = (payload, history) => (dispatch) => {
  dispatch(beginLoaderCall());
  payload.deviceType = DEVICE_TYPE;
  // dispatch(endLoaderCall());
  // const userInfo = {
  //   firstName: "asdf",
  //   email: "sadfasdf",
  //   lastName: "asdfads",
  //   userId: "34303",
  //   userName: "asdfsd",
  // };
  // setItem(
  //   AUTH_TOKEN,
  //   "AE0u-Nf-RVkiGbDuqDCAkuzJ16U0ELnlKUJP4pZ8UyJNS4zAoppUTHvKIhV_Vk7ydvps0JQvQ9pt7gh8E47cbi-lj_SG8PL398X-TTg7BPunLw6lgR6AlSZDmwLOhz1vf2LRYscmzjiM3R2wHp5WZtDi0FtiICjqbDzik3N-iHWkfHxW9dKaDXKHqy9HMF5YaeKzAzDiiSlyWh1_FEhdKMJeV1pKAHgLEw"
  // );
  // setItem(USER_ID, "34303");
  // dispatch({
  //   type: USER_DETAIL,
  //   payload: userInfo,
  // });
  // history.push(DASHBOARD_ROUTE);
  console.log("payload", payload);
  return logInUser(payload)
    .then((response) => {
      dispatch(endLoaderCall());
      console.log("RESPONSE", response);
      const userInfo = response.user;
      setItem(AUTH_TOKEN, response.user.refreshToken);
      setItem(USER_ID, response.user.uid);
      dispatch({
        type: USER_DETAIL,
        payload: userInfo,
      });
      history.push(DASHBOARD_ROUTE);
    })
    .catch(() => {
      errorToast(3, MESSAGE.INVALID_EMAIL);
      dispatch(endLoaderCall());
    });
};
