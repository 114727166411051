import React, { useEffect, useState } from "react";
import moment from "moment";

export const TimeView = ({ makePaymentAt, fontColors }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    try {
      const diff = moment().diff(moment(makePaymentAt.toDate()), "minutes");
      setTimeLeft(diff + " min");
      let secTimer = setInterval(() => {
        const diff = moment().diff(moment(makePaymentAt.toDate()), "minutes");
        setTimeLeft(diff + " min");
      }, 1000);
      return () => clearInterval(secTimer);
    } catch (e) {
      console.log("e", e);
    }
  }, []);

  //   const updateTime = () => {
  //     setTimeLeft(moment().format("LTS"));
  //   };

  return (
    <div className="col-sm-6" style={{ marginTop: 10, color: fontColors }}>
      {/* {moment().format("LTS")} */}
      {timeLeft && timeLeft}
    </div>
  );
};
