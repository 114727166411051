import * as firebase from "firebase";
// import firestore from "firebase/firestore";

// const settings = {timestampsInSnapshots: true}

// const config = {
//     apiKey: "YOUR_API_KEY",
//     authDomain: "YOUR_AUTH_DOMAIN",
//     databaseURL: "YOUR_DATABASE_URL",
//     projectId: "YOUR_PROJECT_ID",
//     storageBucket: "YOUR_STORAGE_BUCKET",
//     messagingSenderId: "YOUR_MESSAGING_ID"
//   };

const config = {
  apiKey: "AIzaSyCMccaZYaewrIMsXGb_bDwqqPFH17L8Hr4",
  authDomain: "pizzataste-51bf2.firebaseapp.com",
  databaseURL: "https://pizzataste-51bf2.firebaseio.com",
  projectId: "pizzataste-51bf2",
  storageBucket: "pizzataste-51bf2.appspot.com",
  messagingSenderId: "5119383491",
  appId: "1:5119383491:web:825480e6d9e8caef",
  measurementId: "G-BT626XT4DZ",
};

firebase.initializeApp(config);

// firebase.firestore().settings(settings);

export default firebase;
