import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";

import { setupInterceptors } from "./axiosInterceptors";
import { muiTheme } from "./themes/muiTheme";
import store from "./store/configureStore";
import "./assets/scss/index.scss";
import App from "./components/App";

setupInterceptors();
const Start = () => (
  <MuiThemeProvider muiTheme={muiTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>
);

ReactDOM.render(
  // <React.StrictMode>
  //   <Start />
  // </React.StrictMode>,
  <Start />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
