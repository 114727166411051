import axios from 'axios';

import { GET_CREDIT , GET_PROMO_CODE , EDIT_PROMO_CODE} from '../constants';

export const getCredit = () => axios.get(GET_CREDIT);

export const editCredit = (id , payload) => axios.put(GET_CREDIT  + '/' + id , payload);

export const getPromoCode = () => axios.get(GET_PROMO_CODE);

export const editPromoCode = (id , payload) => axios.put(EDIT_PROMO_CODE + id , payload);

