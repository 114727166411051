import axios from 'axios';

import { AMENITIES_URL } from '../constants';

/**
 * Function to get amenities list
 */
export const fetchAmenitiesList = (search, page, limit, sortKey, sorttype) =>
  axios.get(`${AMENITIES_URL}?search=${search || ''}&page=${page || 0}&limit=${limit || 10}${sortKey ? `&${sortKey}=${sorttype}` : ''}`);

/**
 * Function to get amenity details
 * @param  {integer} amenity id
 */
export const getAmenityInfo = id => axios.get(AMENITIES_URL + '/' + id);

/**
 * Function to add amenity
 * @param  {Object} payload
 */
export const addAmenity = payload => axios.post(AMENITIES_URL, payload);

/**
 * Function to update amenity details
 * @param  {Object} payload
 */
export const updateAmenityInfo = (id, payload) =>
  axios.put(AMENITIES_URL + '/' + id, payload);

/**
 * Function to delete amenity details
 * @param  {Object} payload
 */
export const deleteAmenityInfo = id => axios.delete(AMENITIES_URL + '/' + id);
