import { BEGIN_LOADER_CALL, END_LOADER_CALL } from './actionType';

/**
 * Action for handle Ajax call
 */
export const beginLoaderCall = () => ({
  type: BEGIN_LOADER_CALL,
  loading: true
});

export const endLoaderCall = () => ({
  type: END_LOADER_CALL,
  loading: false
});
