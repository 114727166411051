import React, { Component } from "react";
import Header from "../components/rightPanel/Header";
import PromoCode from "./PromoCode";
import { SEASONAL_PROMO_CODE_ROUTE } from "../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as constants from "../constants";
import moment from "moment";
import * as actions from "../actions";
import { connect } from "react-redux";

class PastOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      startDate: "",
      endDate: "",
      active: false,
      isEditable: false,
      id: null,
      highlight1: false,
      highlight2: false,
      highlight3: false,
    };
  }

  componentDidMount = async () => {
    const { getSeasonalCredit } = this.props;
    await getSeasonalCredit();
    const { creditdata } = this.props;
    const details = creditdata && creditdata[0];
    this.setState({
      amount: details && details.amount,
      startDate: details && moment(details.fromDate).toDate("MM/DD/YYYY"),
      endDate: details && moment(details.toDate).toDate("MM/DD/YYYY"),
      active: details && details.isActive,
      id: details && details.creditId,
    });
  };

  checkActive(event) {
    this.setState({
      active: !this.state.active,
    });
  }

  updateCredit = (e) => {
    e.preventDefault();
    this.setState({ isEditable: !this.state.isEditable });
    const { amount, startDate, endDate, active, id } = this.state;
    const newAmount = amount.toString().replace("$ ", "");
    const data = {
      amount: newAmount,
      fromDate: moment(startDate).utc().format(),
      toDate: moment(endDate).utc().format(),
      isActive: active,
    };
    {
      this.state.isEditable && this.props.editSeasonalCredit(id, data);
    }
  };

  handleChange = (e) => {
    const value = e.target.value.replace("$ ", "");
    if (parseInt(value) >= 0 || value === "") {
      if (constants.discountRegex.test(value)) {
        this.setState({
          amount: (value.length > 0 ? "$ " : "") + value,
          enableButton: value.length > 0 && value !== "." ? false : true,
        });
      }
    }
  };

  handleStartDateChange = (e) => {
    this.setState({
      startDate: new Date(e),
    });
  };

  handleEndDateChange = (e) => {
    this.setState({
      endDate: new Date(e),
    });
  };

  handleAmountClick = () => {
    this.setState({
      highlight1: true,
      highlight2: false,
      highlight3: false,
    });
  };

  handleStartDateClick = () => {
    this.setState({
      highlight2: true,
      highlight1: false,
      highlight3: false,
    });
  };

  handleEndDateClick = () => {
    this.setState({
      highlight3: true,
      heightlight1: false,
      highlight2: false,
    });
  };

  render() {
    const { active } = this.state;
    return (
      <div>
        <Header heading="PROMO CODE" routeLink={SEASONAL_PROMO_CODE_ROUTE} />
        <div className="seasonal-code-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="outer-box-wrapper credit">
                <div className="box-header d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">Wallet Credit</h3>
                  <p>Assigned to all new app signups</p>
                  <div className="outerDivFull d-flex align-items-center">
                    <span
                      className={
                        active ? "active-toggle mr-3" : "inactive-toggle mr-3"
                      }
                    >
                      {active ? `Active` : `InActive`}
                    </span>
                    <div className="switchToggle">
                      <input
                        type="checkbox"
                        id="switch2"
                        checked={active}
                        disabled={!this.state.isEditable}
                        onClick={(event) => this.checkActive(event)}
                      />
                      <label for="switch2">Toggle</label>
                    </div>
                  </div>
                </div>
                <div className="promo-form">
                  <form action="">
                    <div class="form-group">
                      <div
                        className={`floating-input edit-floating-input ${
                          this.state.highlight1 && this.state.isEditable
                            ? "move active label-heightlight"
                            : ""
                        }`}
                      >
                        <label>Amount</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Amount"
                          readOnly={!this.state.isEditable}
                          value={this.state.amount}
                          onChange={(e) => this.handleChange(e)}
                          onClick={() => this.handleAmountClick()}
                        />
                      </div>
                    </div>
                    <div
                      class="form-group small-calendar-form-group"
                      onClick={() => this.handleStartDateClick()}
                    >
                      <div
                        className={`floating-input edit-floating-input ${
                          this.state.highlight2 && this.state.isEditable
                            ? "move active label-heightlight"
                            : ""
                        }`}
                      >
                        <label>From Date</label>
                        <DatePicker
                          placeholderText="From Date"
                          dateFormat="MM/dd/yyyy"
                          name="clickDate"
                          readOnly={!this.state.isEditable}
                          className="form-control custom-date-picker"
                          selected={this.state.startDate}
                          onChange={(e) => this.handleStartDateChange(e)}
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                    <div
                      class="form-group small-calendar-form-group"
                      onClick={() => this.handleEndDateClick()}
                    >
                      <div
                        className={`floating-input edit-floating-input ${
                          this.state.highlight3 && this.state.isEditable
                            ? "move active label-heightlight"
                            : ""
                        }`}
                      >
                        <label>To Date</label>
                        <DatePicker
                          placeholderText="To Date"
                          className="form-control custom-date-picker"
                          readOnly={!this.state.isEditable}
                          disabled={
                            this.state.startDate === "" ||
                            this.state.startDate === null
                              ? true
                              : false
                          }
                          selected={this.state.endDate}
                          onChange={(e) => this.handleEndDateChange(e)}
                          minDate={this.state.startDate}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="action-btn">
                      <button
                        className="btn primary-btn"
                        onClick={(e) => this.updateCredit(e)}
                      >
                        {!this.state.isEditable ? `Edit` : `Save`}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <PromoCode />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  creditdata: state.seasonalcredit.creditdata,
});

export default connect(mapStateToProps, actions)(PastOrders);
