import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  BOOKING_ROUTE,
  PAST_BOOKING_HEADERS,
  getActionValue,
} from "../constants";
import BookingDetail from "../components/blintzbox/BookingDetail";
import Header from "../components/rightPanel/Header";
import * as actions from "../actions";
import { BOOKING_STATUS } from "../../src/constants";
import Loader from "../components/loader/Loader";
import history from "../store/history";
import moment from "moment";
import AssemlbyContainer from "../components/orders/assembly/Assembly";
import { firestore } from "firebase";
import firebase from "../config/FirebaseConfig";
import MakeLine from "../components/orders/makeline/makeline";
import WorkTable from "../components/orders/workTable/WorkTable";
import OnRack from "../components/orders/onRack/OnRack";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const BookingHoc = (WrapperComponent) => {
  class BookingParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        bookingData: null,
        bookingDetailId: null,
        upcomingSearch: "",
        pastSearch: "",
        cancelledSearch: "",
        addRemoveDeleteModal: false,
        openCancelModal: false,
        bookingRefundData: null,
        bookingDetailsHeader: "READY",
        bookingTabVal: history.location.state
          ? history.location.state.bookingTabVal
          : "current",
        csvData: null,
        sortKey: "sortByStartDate",
        sortType: "ASC",
        businessPartnerId: null,
        dateType: { startDate: "", endDate: "" },
        currentSearch: "",
        listOrders: [],
        assemlyOrders: [],
        finalOrders: [],
        makeLineOrders: [],
        workTableOrders: [],
        deliveryBoys: [],
        onRackOrders: [],
      };
      this.initialState = this.state;
    }

    async componentDidMount() {
      this.fetchOrders();
      this.fetchDeliveryBoys();
    }

    fetchDeliveryBoys = () => {
      try {
        const deliveryBoy = firebase
          .firestore()
          .collection("users")
          .where("available", "==", true)
          .where("roleType", "array-contains", "DELIVERYBOY");
        this.unsubscribe = deliveryBoy.onSnapshot((querySnapshot) => {
          let deliveryBoys = [];
          querySnapshot.forEach((doc) => {
            deliveryBoys.push({ ...doc.data(), key: doc.id });
          });
          console.log("deliveryBoys", deliveryBoys);
          this.setState({
            deliveryBoys,
          });
        });
      } catch (e) {
        console.log("Error", e);
      }
    };

    fetchOrders = () => {
      try {
        const orderCollection = firebase
          .firestore()
          .collection("orders")
          .where("open", "==", true)
          .where("kitchenId", "==", "NwH9XlbSrK49IQ46UZjR");
        this.unsubscribe = orderCollection.onSnapshot((querySnapshot) => {
          let orderList = [];
          querySnapshot.forEach((doc) => {
            orderList.push({ ...doc.data(), key: doc.id });
          });
          this.setupOorders(orderList);
        });
      } catch (e) {
        console.log("Error", e);
      }
    };

    setupOorders = (orderList) => {
      let listOrders = [];

      const sortOrders = orderList.sort((a, b) => {
        const dateA = new firestore.Timestamp(
          a.updatedAt.seconds,
          a.updatedAt.nanoseconds
        ).toDate();
        const dateB = new firestore.Timestamp(
          b.updatedAt.seconds,
          b.updatedAt.nanoseconds
        ).toDate();
        return dateB - dateA;
      });
      sortOrders.forEach((order, index) => {
        const {
          items,
          key,
          liveStatus,
          liveStatusInt,
          createdAt,
          updatedAt,
          makePaymentAt,
          deliveryBoyId,
        } = order;
        let i = 0;

        items.forEach((item, itemIndex) => {
          const { quantity, isCombo = false, pizzaItem } = item;
          if (isCombo === true) {
            pizzaItem.forEach((data, indexNumber) => {
              if (data.category === "1" || data.category === "2") {
                listOrders.push({
                  ...item,
                  pizzaItem: data,
                  key,
                  liveStatus,
                  liveStatusInt,
                  createdAt,
                  makePaymentAt,
                  updatedAt,
                  deliveryBoyId,
                  index: `${index + 1}-${i + indexNumber}`,
                  cellKey: `${key}_${index + 1}-${i + indexNumber}`,
                });
              }
            });
          } else {
            for (let e = 0; e < quantity; e++) {
              if (item.category !== "3") {
                listOrders.push({
                  ...item,
                  key,
                  liveStatus,
                  liveStatusInt,
                  createdAt,
                  makePaymentAt,
                  updatedAt,
                  index: `${index + 1}-${e + itemIndex}`,
                  cellKey: `${key}_${index + 1}-${e + itemIndex}`,
                });
              }
            }
          }
        });
      });
      console.log("sortOrders::::::: ", sortOrders);

      const onRackOrders = sortOrders.filter((element) => {
        return (
          element.liveStatus === "PACKED" ||
          element.liveStatus === "DISPATCHED" ||
          element.liveStatus === "RECEIVED" ||
          element.liveStatus === "PREPARING" ||
          element.liveStatus === "BAKING"
        );
      });

      const finalOrders = listOrders.filter((element) => {
        return (
          element.liveStatus === "PACKED" || element.liveStatus === "DISPATCHED"
        );
      });

      const filter = listOrders.filter((element) => {
        return element.liveStatus !== "PACKED";
      });

      const workTableOrders = listOrders.filter((element) => {
        // if (element.liveStatus === "RECEIVED") {
        //   const [play, { stop }] = useSound(soundUrl, { volume: 1 });
        // }
        return element.liveStatus === "RECEIVED";
      });

      const makeLineOrders = listOrders.filter((element) => {
        return element.liveStatus === "PREPARING";
      });

      const assemlyOrders = listOrders.filter((element) => {
        return element.liveStatus === "BAKING";
      });

      this.setState({
        listOrders: filter,
        finalOrders,
        makeLineOrders,
        workTableOrders,
        onRackOrders,
        assemlyOrders,
      });
    };

    handleChangeTab = async (filter) => {
      console.log("on Tab Switch");
    };

    handle = () => {
      useFullScreenHandle();
    };

    render() {
      const { loading } = this.props,
        {
          bookingTabVal,
          makeLineOrders,
          workTableOrders,
          deliveryBoys,
          onRackOrders,
          assemlyOrders,
        } = this.state;
      const date = new Date();
      return (
        <>
          <FullScreen handle={this.handle}>
            <Header heading="ORDERS" routeLink={BOOKING_ROUTE} />
            {/* <ComponentToPrint /> */}
            <div className="admin-right-data-wrapper admin-tab-view-data-wrapper">
              <button onClick={this.handle.enter}>Enter fullscreen</button>
              <Tabs
                defaultActiveKey={bookingTabVal}
                onSelect={(e) => this.handleChangeTab(e)}
                className="custom-tabs-wrapper"
              >
                <Tab eventKey="current" title="WORK TABLE">
                  <WorkTable
                    heading={`NEW ORDER(S)(${workTableOrders.length})`}
                    listOrders={workTableOrders}
                  />
                </Tab>
                <Tab eventKey="makeline" title="MAKELINE">
                  <MakeLine
                    heading={`MAKELINE(${makeLineOrders.length})`}
                    listOrders={makeLineOrders}
                  />
                </Tab>
                <Tab eventKey="assemply" title="ASSEMBLY">
                  <AssemlbyContainer
                    heading={`ASSEMBLY(${assemlyOrders.length})`}
                    listOrders={assemlyOrders}
                  />
                </Tab>

                <Tab eventKey="upcoming" title="ON RACK">
                  <OnRack
                    containerType={"onRack"}
                    heading={`READY(${onRackOrders.length})`}
                    listOrders={onRackOrders}
                    deliveryBoys={deliveryBoys}
                    headerList={PAST_BOOKING_HEADERS}
                  />
                </Tab>
              </Tabs>
            </div>
            <div className="admin-right-footer">
              <p className="admin-right-footer-text">
                © {date.getFullYear()} Blintz, All Rights Reserved.
              </p>
            </div>
          </FullScreen>
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    paginationPage: state.paginationPage.paginationPage,
    bookingPriceDetails: state.bookingPriceDetails.bookingPriceDetails || [],
    userDetail: state.userById,
    bookingCsv: state.bookingList.csvBooking || {},
    blintzboxDetail: state.blintzboxDetails || {},
    currentBooking: state.bookingList.currentBooking || {},
    loading: state.loaderStatus.loading,
    partnersAPi: state.partnerApiList || {},
    bookingImages: state.bookingImages.bookingImages,
  });
  return connect(mapStateToProps, actions)(BookingParent);
};

export default BookingHoc;
