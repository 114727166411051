export const USER_HEADERS = [
  { id: 0, label: "Name", hasSortIcon: true, key: "displayName", bold: true },
  {
    id: 6,
    label: "Phone Brand",
    hasSortIcon: false,
    key: "brand",
  },
  {
    id: 7,
    label: "Device Version",
    hasSortIcon: false,
    key: "deviceVersion",
  },
  { id: 1, label: "Email", hasSortIcon: true, key: "email" },
  { id: 2, label: "Phone Number", hasSortIcon: false, key: "phone" },
  {
    id: 3,
    label: "Address",
    hasSortIcon: false,
    key: "userAddress",
  },
  { id: 4, label: "Token", hasSortIcon: false, key: "token" },
  { id: 5, label: "Login Date", hasSortIcon: true, key: "loginDate" },
];

export const MENU_HEADERS = [
  { id: 6, label: "Name", hasSortIcon: true, key: "name" },
  { id: 1, label: "Detail", hasSortIcon: false, key: "detail" },
  { id: 2, label: "Price", hasSortIcon: true, key: "price" },
  {
    id: 3,
    label: "Size",
    hasSortIcon: false,
    key: "size",
  },
  { id: 0, label: "Category", hasSortIcon: true, key: "category" },
  // { id: 4, label: "Status", hasSortIcon: true, key: "status", bold: false },
  { id: 5, label: "Active", hasSortIcon: true, key: "active", bold: false },
];

export const BLINTZBOX_HEADERS = [
  { id: 0, label: "Name", hasSortIcon: true, key: "name", bold: true },
  {
    id: 1,
    label: "Location",
    hasSortIcon: false,
    key: "locationId",
    hasId: true,
  },
  { id: 2, label: "Address", hasSortIcon: false, key: "address" },
  { id: 5, label: "Status", hasSortIcon: true, key: "isActive" },
];

export const SPONSOR_HEADERS = [
  { id: 0, label: "Logo", hasSortIcon: false, key: "logoUrl", isImage: true },
  { id: 1, label: "Sponsor name", hasSortIcon: true, key: "name", bold: true },
  {
    id: 3,
    label: "Brand Primary",
    name: "BrandPrimary",
    hasSortIcon: false,
    key: "theme",
  },
  {
    id: 4,
    label: "Brand Secondary",
    name: "BrandSecondary",
    hasSortIcon: false,
    key: "theme",
  },
];

export const PRICING_PLAN_HEADERS = [
  {
    id: 0,
    label: "Plan Duration(Minutes)",
    hasSortIcon: true,
    key: "duration",
    extension: " min",
  },
  { id: 1, label: "Plan Cost", hasSortIcon: true, key: "cost", extension: "$" },
];

export const PARTNERS_HEADERS = [
  { id: 0, label: "Logo", hasSortIcon: false, key: "logoUrl", isImage: true },
  { id: 1, label: "Name", hasSortIcon: true, key: "name" },
  { id: 3, label: "Promo Code", hasSortIcon: true, key: "promo" },
];

export const AMENITIES_HEADERS = [
  { id: 0, label: "Logo", hasSortIcon: false, key: "image", isImage: true },
  { id: 1, label: "Amenity Name", hasSortIcon: true, key: "name" },
];

export const LOCATION_HEADERS = [
  { id: 0, label: "Location", hasSortIcon: true, key: "name" },
  { id: 1, label: "Address", hasSortIcon: false, key: "address" },
  { id: 5, label: "Status", hasSortIcon: false, key: "is_active" },
];

export const STATUS_LIST = [
  { id: 1, label: "Active", value: true },
  { id: 2, label: "Inactive", value: false },
];

export const COMING_BOOKING_HEADERS = [
  {
    id: 8,
    label: "Partner API Code",
    hasSortIcon: false,
    key: "business_partner",
  },
  { id: 0, label: "Booking Id", hasSortIcon: false, key: "id", bold: true },
  { id: 1, label: "Transaction ID", hasSortIcon: false, key: "transactionId" },
  {
    id: 3,
    label: "Booking Start Date",
    hasSortIcon: true,
    key: "datetimeStart",
    isDate: true,
  },
  {
    id: 4,
    label: "Date of Reservation",
    hasSortIcon: false,
    key: "createdAt",
    isDate: true,
  },
  { id: 5, label: "blintzbox Name", hasSortIcon: true, key: "blintzbox" },
  { id: 6, label: "Payment Value", hasSortIcon: true, key: "amount" },
  { id: 7, label: "Status", hasSortIcon: true, key: "status" },
];

export const PAST_BOOKING_HEADERS = [
  // {
  //   id: 7,
  //   label: "Name",
  //   hasSortIcon: false,
  //   key: "name",
  // },
  { id: 1, label: "Order Id", hasSortIcon: false, key: "orderId" },
  // { id: 0, label: "Booking Id", hasSortIcon: false, key: "id", bold: true },
  {
    id: 3,
    label: "Time",
    hasSortIcon: true,
    key: "time",
    isDate: true,
  },
  {
    id: 4,
    label: "Quantity",
    hasSortIcon: false,
    key: "quantity",
    isDate: false,
  },
  // { id: 5, label: "blintzbox Name", hasSortIcon: true, key: "blintzbox" },
  { id: 6, label: "Assign To", hasSortIcon: true, key: "deliveryBoyId" },
];

export const CANCEL_BOOKING_HEADERS = [
  {
    id: 7,
    label: "Partner API Code",
    hasSortIcon: false,
    key: "business_partner",
  },
  { id: 0, label: "Booking Id", hasSortIcon: false, key: "id", bold: true },
  { id: 1, label: "Transaction ID", hasSortIcon: false, key: "transactionId" },
  {
    id: 3,
    label: "Booking Start Date",
    hasSortIcon: true,
    key: "datetimeStart",
    isDate: true,
  },
  {
    id: 4,
    label: "Cancel Date",
    hasSortIcon: false,
    key: "createdAt",
    isDate: true,
  },
  { id: 5, label: "blintzbox Name", hasSortIcon: true, key: "blintzbox" },
  { id: 6, label: "Refund Amount", hasSortIcon: true, key: "amount" },
];

export const COMING_USER_HEADERS = [
  { id: 0, label: "Booking Id", hasSortIcon: false, key: "id", bold: true },
  { id: 1, label: "blintzbox Name", hasSortIcon: false, key: "blintzbox" },
  { id: 2, label: "Location", hasSortIcon: false, key: "blintzbox" },
  {
    id: 3,
    label: "Date & Time",
    hasSortIcon: false,
    key: "datetimeStart",
    isDate: true,
  },
  { id: 4, label: "Payment Status", hasSortIcon: true, key: "status" },
];

export const PAST_USER_HEADERS = [
  { id: 0, label: "Booking Id", hasSortIcon: false, key: "id", bold: true },
  { id: 1, label: "blintzbox Name", hasSortIcon: false, key: "blintzbox" },
  { id: 2, label: "Location", hasSortIcon: false, key: "blintzbox" },
  {
    id: 3,
    label: "Date & Time",
    hasSortIcon: false,
    key: "datetimeStart",
    isDate: true,
  },
  { id: 4, label: "Payment Value", hasSortIcon: false, key: "amount" },
];

export const CANCEL_USER_HEADERS = [
  { id: 0, label: "Booking Id", hasSortIcon: false, key: "id", bold: true },
  { id: 1, label: "blintzbox Name", hasSortIcon: false, key: "blintzbox" },
  { id: 2, label: "Location", hasSortIcon: false, key: "blintzbox" },
  {
    id: 3,
    label: "Date & Time",
    hasSortIcon: false,
    key: "datetimeStart",
    isDate: true,
  },
  { id: 4, label: "Refund Amount", hasSortIcon: false, key: "amount" },
];

export const UPCOMING_BOOKING = "UPCOMING BOOKINGS";

export const PARTNERS_API_HEADER = [
  { id: 0, label: "Name", hasSortIcon: false, key: "name" },
  { id: 1, label: "Partner Code", hasSortIcon: true, key: "code" },
  { id: 2, label: "Key", hasSortIcon: false, key: "api_key" },
];
