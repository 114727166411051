import { ERROR_TOAST, SUCCESS_TOAST } from './actionType';

/**
 * Action for error toast
 */
export const errorToast = (message, status) => dispatch => {
  dispatch({
    type: ERROR_TOAST,
    payload: { message, status }
  });
};

/**
 * Action for success toast
 */
export const successToast = (message, status) => dispatch => {
  dispatch({
    type: SUCCESS_TOAST,
    payload: { message, status }
  });
};
