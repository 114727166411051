import React, { Fragment } from 'react';
import { QR_CODE } from '../../constants';


const QrCode = () => {
  const downloadImg = () => {
    var FileSaver = require('file-saver');
    FileSaver.saveAs(QR_CODE, "QR_Code.png");
  }
  return (
    <Fragment>
      <div className='admin-right-header'>
        <h2 className='admin-heading'>QR Code</h2>
      </div>
      <div className='admin-right-data-wrapper admin-tab-view-data-wrapper'>
        <div className='admin-qr-data-wrapper'>
          <div className='admin-qr-data-inner-box'>
            <img src={QR_CODE} alt='qr-code' className='qr-image' id='qr-image' />
            <button type='button' className='btn download-btn' onClick={() => downloadImg()}>
              Download
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QrCode;
