import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { EmailValidate } from '../../utils';
import * as actions from '../../actions';
import { TextBox } from '../input';
import Banner from '../Banner';
import {
  REQUIRE_EMAIL,
  INVALID_EMAIL,
  SUCCESS,
  SIGN_IN
} from '../../constants';

const ForgotPassword = props => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  const dispatch = useDispatch();
  /**
   * Function to handle on change
   */
  const handleOnChange = event => {
    setError(error && '');
    setEmail(event.target.value);
  };

  /**
   * Function to handle on blur
   */
  const handleOnBlur = () => {
    if (email) {
      if (!EmailValidate(email)) {
        setError(INVALID_EMAIL);
      }
    } else {
      setError(REQUIRE_EMAIL);
    }
  };

  /**
   * Function to submit form
   */
  const handleSubmit = async () => {
    if (email) {
      if (!EmailValidate(email)) {
        setError(INVALID_EMAIL);
      } else {
        const isSent = await dispatch(actions.forgotPassword(email));
        isSent &&
          props.history.push({
            pathname: SUCCESS,
            state: { email }
          });
      }
    } else {
      setError(REQUIRE_EMAIL);
    }
  };

  return (
    <div className='signin-wrapper'>
      <div className='main-container signup-section'>
        <div className='signin-container'>
          <Banner />
          <div className='login-container'>
            <div className='heading-wrap'>
              <h1>FORGOT PASSWORD</h1>
              <span className='caption'>
                Enter your email address to reset your password.
              </span>
            </div>
            <div className='form-group'>
              <TextBox
                type='text'
                name='email'
                label='Email'
                autoFocus={true}
                className='form-control'
                error={error}
                value={email || ''}
                onBlur={() => handleOnBlur()}
                onChange={event => handleOnChange(event)}
              />
              <span className='error'>{error}</span>
            </div>

            <div className='form-action btn-text-group form-action-mb'>
              <button
                id='submitCall'
                className='btn primary-btn btn-block'
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
              <Link
                className='forgot-text'
                to={SIGN_IN}
                title='Forgot Password?'
              >
                Back to Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
