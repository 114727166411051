import axios from "axios";
import { PANEL_REPORT, STATICS_REPORT, REVENUE_REPORT } from "../constants";
import firebase from "../config/FirebaseConfig";
import { firestore } from "firebase";
import moment from "moment-timezone";
/**
 * Function to get panel report
 */
export const getPanelReportData = () => axios.get(PANEL_REPORT);

export const getAllUsersCount = async () => {
  try {
    const snapshot = await firebase.firestore().collection("users").get();
    let users = [];
    snapshot.docs.forEach((doc) => {
      const user = doc.data();
      const dateA = new firestore.Timestamp(
        user.loginDate && user.loginDate.seconds,
        user.loginDate && user.loginDate.nanoseconds
      ).toDate();

      users.push({ ...user, loginDate: moment(dateA).format("lll") });
    });
    console.log("users", users);
    return { totalUser: users.length, users: users };
  } catch (e) {
    console.log("Error", e);
  }
};

export const getAllOrdersCount = async () => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("orders")
      .where("liveStatus", "==", "DELIVERED")
      .where("kitchenId", "==", "NwH9XlbSrK49IQ46UZjR")
      .get();
    let totalOrders = 0;
    let totalRevenue = 0;
    snapshot.docs.forEach((doc) => {
      const { items, totalPrice } = doc.data();
      totalRevenue = totalRevenue + totalPrice;
      items.forEach((item) => {
        if (item.category !== "3") {
          totalOrders = totalOrders + item.quantity;
        }
      });
    });
    console.log("totalOrders", totalOrders, totalRevenue);
    return { totalOrders, totalRevenue };
  } catch (e) {
    console.log("Error", e);
  }
};

/**
 * Function to get blintzbox Statics report
 */
export const getStaticsData = (location, start, end) =>
  axios.get(
    `${STATICS_REPORT}?startDate=${start}&endDate=${end}${
      location ? `&locationId=${location}` : ""
    }`
  );

/**
 * Function to get Revenue report
 */
export const getRevenueData = (location, start, end) =>
  axios.get(
    `${REVENUE_REPORT}?startDate=${start}&endDate=${end}${
      location ? `&locationId=${location}` : ""
    }`
  );
