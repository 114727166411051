import { PRICING_PLANES_LIST, PRICING_PLAN_DETAIL } from '../actions';

export const pricingList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === PRICING_PLANES_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const pricingDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === PRICING_PLAN_DETAIL) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
