import { beginLoaderCall, endLoaderCall } from ".";
import {
  getCredit,
  editCredit,
  getPromoCode,
  editPromoCode,
} from "../services";
import {
  GET_CREDIT,
  EDIT_CREDIT,
  GET_PROMO_CODE,
  EDIT_PROMO_CODE,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

export const getSeasonalCredit = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return getCredit()
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: GET_CREDIT,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const editSeasonalCredit = (id, payload) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    editCredit(id, payload)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: EDIT_CREDIT,
          payload: response,
        });
        successToast(1, MESSAGE.CREDIT_UPDATE);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(2, response.response.data.message);
        } else {
          errorToast(2, MESSAGE.CREDIT_UPDATE_ERROR);
        }
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

export const getPromoCodeDetail = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return getPromoCode()
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: GET_PROMO_CODE,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const editPromoCodeDetail = (id, payload) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    editPromoCode(id, payload)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: EDIT_PROMO_CODE,
          payload: response,
        });
        successToast(1, MESSAGE.PROMO_UPDATE);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(2, response.response.data.message);
        } else {
          errorToast(2, MESSAGE.PROMO_UPDATE_ERROR);
        }
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};
