import React, { Component } from "react";
import signinBck from "../../assets/images/signin-back.png";
import editImg from "../../assets/images/svg/edit.svg";
import ColorPicker from "./ColorPicker";
import { getClassName, isUrl } from "../../constants";
import Footer from "../rightPanel/Footer";

class AddEditSponsorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themePicker: {
        BrandPrimary: { status: true, style: { display: "none" } },
        BrandSecondary: { status: true, style: { display: "none" } },
        Neutral: { status: true, style: { display: "none" } },
        NeutralLight: { status: true, style: { display: "none" } },
        NeutralDark: { status: true, style: { display: "none" } },
        NeutralExtraDark: { status: true, style: { display: "none" } },
        White: { status: true, style: { display: "none" } },
        Success: { status: true, style: { display: "none" } },
        Error: { status: true, style: { display: "none" } }
      },
      sponsorData: {},
      currentClass: '',
      nameClass: { name: false },
      sponsorThemeData: this.getCurrentState()
    };
    this.initialState = this.state;
  }


  getCurrentState = () => {
    if (this.props.sponsors && this.props.sponsors.theme)
      return JSON.parse(this.props.sponsors.theme).Color;
    //if theme availabe while edit sponsonsr else update props theme
    else
      return {
        BrandPrimary: '#FFD938',
        BrandSecondary: '#d14e23',
        Neutral: '#78909C',
        NeutralLight: '#ECEFF1',
        NeutralDark: '#455A64',
        NeutralExtraDark: '#2C363C',
        White: '#FFFFFF',
        Error: '#EE6161',
        Success: '#8FCD4C'
      };
  };
  static getDerivedStateFromProps(props, state) {
    if (
      props.editSponsorStatus &&
      ((state.sponsorData && props.sponsors.id !== state.sponsorData.id) ||
        state.locationData === null)
    ) {
      return {
        sponsorData: props.sponsors
      };
    } else return null;
  }

  // update textinputs
  updateField = (e, fileStatus, status = false) => {
    if (fileStatus) {
      //updating uploaded file details
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        // converting it to base_64
        const data = this.state.sponsorData;
        data.fileName = file.name;
        data.logoUrl = reader.result;
        this.setState({ sponsorData: data });
      };
      reader.readAsDataURL(file);
    } else {
      let { name, value } = e.target;
      if (status) {
        const data = { [name]: value };
        this.updateState('sponsorThemeData', data); //updating color picker data
      } else {
        const data = this.state.sponsorData;
        data[name] = value;
        this.setState({ sponsorData: data }); //updating name
      }
    }
  };

  updateState = async (state, value) => {
    await this.setState(prevState => ({
      [state]: { ...prevState[state], ...value }
    }));
  };

  updateColorField = (color, name) => {
    this.updateState("sponsorThemeData", { [name]: color.hex });
  };

  //hide and show color picker dropdown
  handleOpenColorPicker = e => {
    const { name } = e.target;
    let { sponsorData, themePicker } = this.state;
    if (e.target.name === "name") this.updateState("nameClass", { name: true });
    else {
      this.setState({ currentClass: e.target.name });
      if (!sponsorData || sponsorData.name === null)
        this.updateState("nameClass", { name: false });
      if (themePicker[e.target.name].style.display === "none")
        this.setState(prevState => ({
          themePicker: {
            ...prevState.themePicker,
            [name]: { status: true, style: { display: "block" } }
          }
        }));
    }
  };

  handleCancelColorPicker = async e => {
    let { themePicker, sponsorThemeData } = this.state;
    const themePickerNew = themePicker, sponsorThemeDataNew = sponsorThemeData;
    if (this.state.sponsorThemeData[e])
      themePickerNew[e] = { status: true, style: { display: "none" } };
    else {
      themePickerNew[e] = { status: false, style: { display: "none" } };
      sponsorThemeDataNew[e] = "";
      await this.setState({ sponsorThemeData: sponsorThemeDataNew });
    }
    await this.setState({
      themePicker: themePickerNew
    });
  };

  handleAcceptColorPicker = async e => {
    let { themePicker, sponsorThemeData } = this.state,
      themePickerNew = themePicker;
    themePickerNew[e] = {
      status: sponsorThemeData[e] ? true : false,
      style: { display: 'none' }
    }

    await this.setState({ themePicker: themePickerNew })
  };

  addUpdateSponsorData = async () => {
    let { sponsorData } = this.state,
      {
        editSponsorStatus,
        updateSponsorDetails,
        createSponsor,
        sponsors,
        getSponsorList,
        paginationPage,
        searchedValue
      } = this.props;
    sponsorData["theme"] = JSON.stringify({
      Color: this.state.sponsorThemeData
    });
    if (editSponsorStatus) {
      let newid = { ...sponsors }
      if (!sponsorData.logoUrl || isUrl(sponsorData.logoUrl))
        delete sponsorData.logoUrl;
      delete sponsorData.id
      await updateSponsorDetails(newid.id, sponsorData); // update sponor
    } else await createSponsor(sponsorData); //create sponsor
    await getSponsorList(searchedValue, paginationPage, 10);
    this.handleClose(true);
  };

  hideOpenColorPicker = e => {
    if (!this.state.sponsorThemeData[e])
      this.setState(prevState => ({
        themePicker: {
          ...prevState.themePicker,
          [e]: { status: false, style: { display: 'none' } }
        }
      }));
  };

  getColorPickerClass = (data) => {
    return (data === undefined || (data && data.style.display === 'none')) ? false : true
  }

  handleClose = async (status) => {
    await this.setState(this.initialState)
    this.props.emptyState(status);
  };

  render() {
    let { sponsorData, nameClass, themePicker, sponsorThemeData, currentClass } = this.state;
    let { editSponsorStatus } = this.props;
    return (
      <>
        <div className="admin-right-detail-wrapper right-detail-with-footer">
          <div className="admin-right-detail-box add-detail-form-box add-detail-form-box-gap">
            <div className="add-detail-form-box-head">
              <h2 className="add-detail-heading"> {editSponsorStatus ? "Edit Sponsor" : "Add Sponsor"} </h2>
            </div>
            <div className="add-detail-form-box-inner add-sponsor-detail-box-inner">
              <div className="upload-media-body"> <div className="media">
                <div className="upload-media-box">
                  <img src={sponsorData && sponsorData.logoUrl ? sponsorData.logoUrl : signinBck}
                    className="align-self-center" alt="signin-back" />
                  <div className="small-custom-upload">
                    <input type="file" name="fileName" onChange={e => this.updateField(e, true, false)}
                      accept="image/x-png,image/jpeg" id="file-upload" />
                    <label for="file-upload" className="small-file-upload-label" >
                      <img src={editImg} alt="edit" />
                    </label>
                  </div>
                </div>
                <div className="media-body">
                  <p> Image size should be maximum <br /> 250 x 150 pixels at 72 dpi</p>
                  <p>File format: jpeg, png.</p>
                </div>
              </div>
              </div>
              <div className="row"> <div className="col-md-6">
                <div className="form-group">
                  <div className={`floating-input ${getClassName(nameClass.name, editSponsorStatus)}`} >
                    <label>Sponsor Name</label>
                    <input type="text" className="form-control" name="name"
                      value={sponsorData && sponsorData.name}
                      onFocus={e => this.handleOpenColorPicker(e)}
                      onChange={e => this.updateField(e)} />
                  </div>
                </div>
              </div>
                <div className="col-md-6"> <div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.BrandPrimary.status, true)}`} >
                    <label>Brand Primary</label>
                    <input type="text" className="form-control" name="BrandPrimary"
                      value={sponsorThemeData.BrandPrimary}
                      onChange={e => this.updateField(e, false, true)}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.BrandPrimary.style}
                      onClick={() => this.hideOpenColorPicker("BrandPrimary")}>
                      <ColorPicker name="BrandPrimary" color={sponsorThemeData.BrandPrimary}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6"> <div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.BrandSecondary.status, true)}`} >
                    <label>Brand Secondary</label>
                    <input type="text" className="form-control" name="BrandSecondary"
                      onChange={e => this.updateField(e, false, true)}
                      value={sponsorThemeData.BrandSecondary}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.BrandSecondary.style} onClick={() =>
                      this.hideOpenColorPicker("BrandSecondary")} >
                      <ColorPicker name="BrandSecondary" color={sponsorThemeData.BrandSecondary}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6"><div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.Neutral.status, true)}`} >
                    <label>Neutral</label>
                    <input type="text" className="form-control" name="Neutral"
                      onChange={e => this.updateField(e, false, true)}
                      value={sponsorThemeData.Neutral}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker"
                      style={themePicker.Neutral.style}
                      onClick={() => this.hideOpenColorPicker("Neutral")}>
                      <ColorPicker name="Neutral" color={sponsorThemeData.Neutral}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6"> <div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.NeutralLight.status, true)}`} >
                    <label>Neutral Light</label>
                    <input type="text" className="form-control" name="NeutralLight"
                      onChange={e => this.updateField(e, false, true)}
                      value={sponsorThemeData.NeutralLight}
                      onClick={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.NeutralLight.style} onClick={() => this.hideOpenColorPicker("NeutralLight")} >
                      <ColorPicker name="NeutralLight" color={sponsorThemeData.NeutralLight}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6"> <div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.NeutralDark.status, true)}`}>
                    <label>Neutral Dark</label>
                    <input type="text" className="form-control" name="NeutralDark"
                      onChange={e => this.updateField(e, false, true)}
                      value={sponsorThemeData.NeutralDark}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.NeutralDark.style} onClick={() => this.hideOpenColorPicker("NeutralDark")} >
                      <ColorPicker name="NeutralDark" color={sponsorThemeData.NeutralDark}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6"> <div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.NeutralExtraDark.status, true)}`} >
                    <label>Neutral Extra Dark</label>
                    <input type="text" className="form-control" name="NeutralExtraDark"
                      onChange={e => this.updateField(e, false, true)}
                      value={sponsorThemeData.NeutralExtraDark}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.NeutralExtraDark.style} onClick={() => this.hideOpenColorPicker("NeutralExtraDark")} >
                      <ColorPicker name="NeutralExtraDark" color={sponsorThemeData.NeutralExtraDark}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6"><div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.White.status, true)}`} >
                    <label>White</label>
                    <input type="text" className="form-control" name="White"
                      onChange={e => this.updateField(e, false, true)}
                      value={sponsorThemeData.White}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.White.style} onClick={() => this.hideOpenColorPicker("White")}>
                      <ColorPicker name="White" color={sponsorThemeData.White}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group lock-form-group">
                    <div className={`floating-input ${getClassName(themePicker.Success.status, true)}`}>
                      <label>Success</label>
                      <input type="text" className="form-control" name="Success"
                        value={sponsorThemeData.Success}
                        onChange={e => this.updateField(e, false, true)}
                        onFocus={e => this.handleOpenColorPicker(e)} />
                      <div className="custom-color-picker" style={themePicker.Success.style} onClick={() => this.hideOpenColorPicker("Success")} >
                        <ColorPicker name="Success" color={sponsorThemeData.Success}
                          updateColorField={this.updateColorField}
                          handleAcceptColorPicker={this.handleAcceptColorPicker}
                          handleCancelColorPicker={this.handleCancelColorPicker} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"> <div className="form-group lock-form-group">
                  <div className={`floating-input ${getClassName(themePicker.Error.status, true)}`} >
                    <label>Error</label>
                    <input type="text" className="form-control" name="Error"
                      value={sponsorThemeData.Error}
                      onChange={e => this.updateField(e, false, true)}
                      onFocus={e => this.handleOpenColorPicker(e)} />
                    <div className="custom-color-picker" style={themePicker.Error.style} onClick={() => this.hideOpenColorPicker("Error")} >
                      <ColorPicker name="Error" color={sponsorThemeData.Error}
                        updateColorField={this.updateColorField}
                        handleAcceptColorPicker={this.handleAcceptColorPicker}
                        handleCancelColorPicker={this.handleCancelColorPicker} />
                    </div> </div> </div></div> </div></div> </div>
          {this.getColorPickerClass(themePicker[currentClass]) ? (
            <div onClick={() => this.handleAcceptColorPicker(currentClass)} className="black-wrapper" ></div>)
            : (false)}
        </div>
        <Footer addUpdateData={this.addUpdateSponsorData}
          handleCancel={this.handleClose}
          editStatus={editSponsorStatus}
          enableButton={sponsorData && sponsorData.name && sponsorData.logoUrl ? false : true} />
      </>
    );
  }
}

export default AddEditSponsorModal;