import React, { Fragment } from "react";
import firebase from "../../../config/FirebaseConfig";
import Header from "../../rightPanel/Header";
import { TimeView } from "../assembly/timeView";
import { ComponentToPrint } from "./ComponentToPrint";
import { useRef } from "react";

const OnRack = (props) => {
  const {
    heading,
    tablist,
    data,
    listOrders,
    deliveryBoys,
    headerList,
  } = props;

  const assignOrderTo = async (deliveryBoy, orderKey, liveStatus) => {
    const { key } = deliveryBoy;
    let status = null;
    if (liveStatus === "PACKED" || liveStatus === "DISPATCHED") {
      status = "DISPATCHED";
    }
    if (status === null) {
      return;
    }
    console.log("deliveryBoyKey ", key);
    try {
      const response = await firebase
        .firestore()
        .collection("orders")
        .doc(orderKey)
        .update({
          liveStatus: status,
          deliveryBoyId: key,
        });
      console.log("[RESPOMSE ]", response);
    } catch (e) {
      console.log("ERROR IN SAVE", e);
    }
  };

  const printReceipt = () => {
    window.print();
  };

  return (
    <Fragment>
      <Header heading={heading} data={data} />
      <div className="row" style={{ marginTop: "20px" }}>
        <>
          <div className={`admin-table-wrapper`}>
            {/* <div>
                <button onClick={handle.enter}>Enter fullscreen</button>
                <ComponentToPrint />
              </div> */}
            <table className="table">
              <thead>
                <tr>
                  {["Order ID", "Total Item(s)", "Date", "Assign To"].map(
                    (item) => (
                      <th>
                        <strong>{item}</strong>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {listOrders.length > 0 ? (
                  listOrders.map((data, index) => {
                    const {
                      deliveryBoyId,
                      createdAt,
                      estimateDeliverTime,
                      items,
                      key,
                      liveStatus,
                      orderId,
                      totalItems,
                      makePaymentAt,
                      updatedAt,
                      method,
                      order,
                      totalPrice,
                      subTotal,
                      coupon,
                      discount,
                      phoneNumber,
                      userName = "",
                    } = data;
                    const fullAddress = data.address;
                    const {
                      address,
                      addressFill,
                      completeAddress,
                      tag,
                      postalCode,
                    } = fullAddress;
                    const selectedDeliveryBoy = deliveryBoys.filter(
                      (item) => item.key === deliveryBoyId
                    )[0];
                    return (
                      <>
                        <tr key={data.key}>
                          <th>
                            <strong>{orderId || "N/A"}</strong>
                          </th>
                          <th>
                            <strong>{totalItems || "N/A"}</strong>
                          </th>
                          <th>
                            <strong>
                              <TimeView
                                makePaymentAt={makePaymentAt}
                                fontColors={"black"}
                              ></TimeView>
                            </strong>
                          </th>
                          <th>
                            <div>
                              <div className="dropdown">
                                <button
                                  disabled={
                                    liveStatus !== "PACKED" &&
                                    liveStatus !== "DISPATCHED"
                                  }
                                  className={`btn ${
                                    selectedDeliveryBoy !== undefined
                                      ? "btn-primary"
                                      : liveStatus !== "PACKED"
                                      ? "btn btn-dark"
                                      : "btn-warning"
                                  } dropdown-toggle`}
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="false"
                                  aria-expanded="false"
                                >
                                  {selectedDeliveryBoy !== undefined
                                    ? selectedDeliveryBoy.displayName
                                    : "Assign Delivery To"}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  {deliveryBoys &&
                                    deliveryBoys.map((item, index) => {
                                      return (
                                        <a
                                          key={item.key}
                                          className="dropdown-item"
                                          onClick={() =>
                                            assignOrderTo(item, key, liveStatus)
                                          }
                                        >
                                          {item.displayName}{" "}
                                        </a>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <p style={{ fontSize: 18 }}>
                              <button
                                class="hide-on-print"
                                onClick={printReceipt}
                                style={{ height: 50, padding: 20 }}
                              >
                                Print
                              </button>
                            </p>
                            <p style={{ fontSize: 18 }}>
                              Address: {tag} : {completeAddress}, {address},{" "}
                              {postalCode}
                            </p>
                            <p style={{ fontSize: 18, fontWeight: "bold" }}>
                              Customer Name: {userName}
                            </p>
                            <p style={{ fontSize: 18, fontWeight: "bold" }}>
                              Phone Number: {phoneNumber}
                            </p>
                            <p style={{ fontSize: 18 }}>
                              Payment Mode: {method}
                            </p>
                            <p style={{ fontSize: 18 }}>
                              <b>Discount:</b> {discount && discount}
                            </p>
                            <p style={{ fontSize: 18 }}>
                              Sub Total: {subTotal}
                            </p>
                            <p style={{ fontSize: 18, fontWeight: "bold" }}>
                              Total Payment: {totalPrice}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <table width="100%">
                              <thead>
                                <tr>
                                  {[
                                    "Name",
                                    "Category",
                                    "Quantity",
                                    "Date",
                                    "Size",
                                  ].map((item) => (
                                    <th>
                                      <strong>{item}</strong>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((item) => {
                                  const {
                                    category,
                                    quantity,
                                    pizzaItem,
                                    size,
                                  } = item;
                                  const crustOuter = item.crust;
                                  // console.log("crustcrustcrustcrust", item);
                                  const isArray = Array.isArray(pizzaItem);
                                  const { name } = pizzaItem;
                                  let type;
                                  if (category === "1") {
                                    type = "Non-Veg";
                                  } else if (category === "2") {
                                    type = "Veg";
                                  } else if (category === "3") {
                                    type = "Drink";
                                  }
                                  return isArray ? (
                                    <tr>
                                      <td colspan="5">
                                        <table
                                          width={"100%"}
                                          style={{
                                            backgroundColor: "#F2F2F2",
                                          }}
                                        >
                                          {pizzaItem.map((data) => {
                                            const {
                                              category,
                                              quantity,
                                              size,
                                              name,
                                              crust,
                                            } = data;
                                            let type;
                                            if (category === "1") {
                                              type = "Non-Veg";
                                            } else if (category === "2") {
                                              type = "Veg";
                                            } else if (category === "3") {
                                              type = "Drink";
                                            }
                                            return (
                                              <tr>
                                                <td>
                                                  {name} (Combo) (
                                                  {crust && crust.name})
                                                </td>
                                                <td>{type}</td>
                                                <td>{quantity}</td>
                                                <td>
                                                  {/* {updatedAt && updatedAt} */}
                                                </td>
                                                <td>
                                                  {typeof size === "string" ||
                                                  size instanceof String
                                                    ? size
                                                    : size[0].size +
                                                      " " +
                                                      size[0].subTitle}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </table>
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>
                                        {name} ({crustOuter && crustOuter.name})
                                      </td>
                                      <td>{type}</td>
                                      <td>{quantity}</td>
                                      <td>
                                        {/* {new firestore.Timestamp(
                                          createdAt.seconds,
                                          createdAt.nanoseconds
                                        ).toDate()} */}
                                        Date
                                      </td>
                                      <td>
                                        {typeof size === "string" ||
                                        size instanceof String
                                          ? size
                                          : size[0].size +
                                            " " +
                                            size[0].subTitle}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td className="empty-text-wrapper">
                      <div className="empty-text">No Data Found ...</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* {listOrders.length > 0 && totalPages && totalPages > 1 && (
            <div className="pagination-wrapper">
              <p className="pagination-text">
                Showing{" "}
                <span>
                  {`${currentPage * 100 + 1}-${
                    (currentPage + 1) * 100 >= totalCount
                      ? totalCount
                      : (currentPage + 1) * 100
                  }`}
                </span>{" "}
                of <span>{totalCount || 0}</span>
              </p>
              <nav aria-label="..."></nav>
              <Pagination
                className="pagination"
                onChange={(page) =>
                  getPaginationDetails(
                    page,
                    bookingDetailsHeader && bookingDetailsHeader[1],
                    action
                  )
                }
                hideFirstAndLastPageLinks={true}
                hideEllipsis={true}
                currentPage={currentPage + 1 || 1}
                totalPages={totalPages || 1}
              />
            </div>
          )} */}
          </div>
          {/* {removeFooter ? (
        false
      ) : (
        <div className="admin-right-footer">
          <p className="admin-right-footer-text">
            © {date.getFullYear()} Blintz, All Rights Reserved.
          </p>
        </div>
      )} */}
        </>
      </div>
    </Fragment>
  );
};

export default OnRack;
