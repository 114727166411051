import React, { useEffect, useState } from "react";
import moment from "moment";
import { firestore } from "firebase";
import { TimeView } from "./timeView";

export const AssemblyContainer = (props) => {
  const {
    containerType,
    deliveryBoys,
    order: {
      pizzaItem,
      size,
      extraCheese,
      index,
      key,
      liveStatus,
      makePaymentAt,
      deliveryBoyId,
      crust,
    },
    handleClick,
    assignOrderTo,
    keyCell,
    isContain,
  } = props;
  //  moment(makePaymentAt.toDate()).format("LTS")
  // console.log("pizzaItem", containerType);

  //   var a = moment([2007, 0, 29]);
  // var b = moment([2007, 0, 28]);
  const getSelectedDeliveryBoy =
    deliveryBoys && deliveryBoys.find((item) => item.key === deliveryBoyId);
  // console.log("getSelectedDeliveryBoy", getSelectedDeliveryBoy, deliveryBoyId);

  const removeToppings =
    pizzaItem.toppings &&
    pizzaItem.toppings.filter((item) => {
      return item.isDeleted === true;
    });

  // console.log("removeToppings", pizzaItem);

  const addedToppings =
    pizzaItem.toppings &&
    pizzaItem.toppings.filter((item) => {
      return item.isSelected === true;
    });
  // console.log("addedToppings", addedToppings);

  let colors = "#fff";
  let fontColors = "#000";
  // if (liveStatus === "PREPARING") {
  //   colors = "#FFE10A";
  // } else if (liveStatus === "BAKING") {
  //   colors = "#0583F2";
  //   fontColors = "#fff";
  // }

  if (isContain) {
    colors = "#FFE10A";
  }

  return (
    <div
      key={keyCell}
      onClick={() => handleClick(key, liveStatus)}
      className="col-sm-3"
      style={{
        minHeight: "200px",
        background: colors,
        margin: "4px",
        boxShadow: "1px 1px 12px #666",
        borderRadius: 8,
      }}
    >
      <div className="row">
        <div
          className="col-sm-6"
          style={{
            fontSize: "20px",
            height: "30px",
            fontWeight: "bold",
            marginTop: 10,
            color: fontColors,
          }}
        >
          {index}
        </div>
        <TimeView
          makePaymentAt={makePaymentAt}
          fontColors={fontColors}
        ></TimeView>
      </div>
      <h1
        style={{
          fontSize: 18,
          fontWeight: "bold",
          marginTop: 10,
          color: "fontColors",
        }}
      >
        {pizzaItem.name}(
        {size &&
          (size instanceof String || typeof size === "string"
            ? size
            : size[0].size)}
        )
      </h1>
      <h1
        style={{
          fontSize: 18,
          marginTop: 10,
          color: "white",
          background: "green",
          padding: 4,
          borderRadius: 4,
        }}
      >
        {crust && crust.name}
      </h1>
      <div style={{ marginTop: 10 }}>
        {removeToppings &&
          removeToppings.map((top, index) => (
            <span
              key={top.name + index}
              className="badge badge-pill badge-dark"
              style={{
                fontSize: 18,
                fontWeight: "bold",
                background: "black",
                borderRadius: 4,
                color: "white",
                marginRight: 5,
                marginBottom: 4,
              }}
            >
              -{top.name}
            </span>
          ))}
        {addedToppings &&
          addedToppings.map((top, index) => (
            <span
              key={top.name + index}
              className="badge badge-pill badge-dark"
              style={{
                fontSize: 18,
                fontWeight: "bold",
                background: "green",
                borderRadius: 4,
                color: "white",
                marginRight: 5,
                marginBottom: 4,
              }}
            >
              +{top.name}
            </span>
          ))}
        {/* <p style={{ fontSize: 10 }}>{key}</p> */}
        {/* <p style={{ fontSize: 10 }}>
          {moment(addedDate).startOf("hour").fromNow()}
        </p> */}
        {extraCheese && (
          <p style={{ fontSize: 10 }}>Extra Cheese{extraCheese}</p>
        )}
        {containerType === "onRack" ? (
          <div className="dropdown">
            <button
              className="btn btn-warning dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded="false"
            >
              {getSelectedDeliveryBoy ? "UserName" : "Assign Delivery To"}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {deliveryBoys &&
                deliveryBoys.map((item, index) => {
                  return (
                    <a
                      key={item.key}
                      className="dropdown-item"
                      onClick={() => assignOrderTo(item, key, liveStatus)}
                    >
                      {item.displayName}
                    </a>
                  );
                })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
