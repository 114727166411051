import React, { Component } from "react";
import UploadIcon from "../../assets/images/svg/upload-icon.svg";
import Select from "react-select";
import Footer from "../rightPanel/Footer";
import {
  getClassName,
  TimeZones,
  ConfigList,
  jBoxClass,
  getHtmlClass,
} from "../../constants";
import AddEditConfig from "./AddEditConfig";
import moment from "moment-timezone";
import firebase from "../../config/FirebaseConfig";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import AddMenuImages from "./AddMenuImages";
import { firestore } from "firebase";
import AddEditPartnerModal from "./AddEditPartnerModal";

const animatedComponents = makeAnimated();

class AddEditJMenuModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jabbrBoxData: {
        toppings: [],
        size: [],
        category: {
          value: "2",
          label: "Veg",
          name: "Veg",
        },
      },
      amnetiesCheckedVal: [],
      classStatus: {
        name: { isFilled: false, status: false },
        category: { isFilled: false, status: false },
        size: { isFilled: false, status: false },
        price: { isFilled: false, status: false },
        toppings: { isFilled: false, status: false },
        description: { isFilled: false, status: false },
      },
      imageData: { image: [], file: [] },
      finalImage: [],
      hideShowConfig: false,
      configDetails: ConfigList,
      updatedConfigDetails: ConfigList,
      updatedRawConfigDetails: JSON.stringify(ConfigList, undefined, 2),
      hideShowErr: null,
      toppings: [],
      sizeOptions: [
        {
          value: "regular",
          label: "Regular",
          name: "Regular",
          subTitle: "4 Slices",
        },
        {
          value: "medium",
          label: "Medium",
          name: "Medium",
          subTitle: "6 Slices",
        },
        ,
        {
          value: "large",
          label: "Large",
          name: "Large",
          subTitle: "8 Slices",
        },
      ],
      showModal: false,
      showImagePicker: false,
    };
    this.initialState = this.state;
  }

  async componentDidMount() {
    const toppingsCollections = firebase.firestore().collection("toppings");
    toppingsCollections.get().then((snapshot) => {
      let toppings = [];
      snapshot.forEach((doc) => {
        console.log(doc.data());
        toppings.push({ id: doc.id, ...doc.data() });
      });
      console.log("toppings ", toppings);
      const filterToppings = toppings.map(({ name, id }) => {
        return { value: name, label: name, name: name, id: id };
      });
      this.setState({ toppings: filterToppings });
    });

    const { selectedMenuData } = this.props;
    if (selectedMenuData) {
      const {
        detail,
        name,
        toppings,
        size,
        price,
        images,
        category,
        id,
      } = selectedMenuData;
      let label = "Veg";
      if (category === "1") {
        label = "Non-veg";
      } else if (category === "3") {
        label = "Drinks";
      }
      const newPrice =
        price &&
        price.map((item) => {
          return {
            price: item.price,
            name: item.price,
            label: item.price,
            value: item.price,
          };
        });
      const newCategory = {
        value: category,
        label: label,
        name: label,
      };
      const newToppings =
        toppings &&
        toppings.map((item) => {
          return { ...item, label: item.name, value: item.name };
        });
      const newSize =
        size &&
        size.map((item) => {
          return {
            ...item,
            label: item.size,
            value: item.size,
            name: item.size,
          };
        });
      console.log("selectedMenuData >>> ", selectedMenuData);
      await this.setState({
        jabbrBoxData: {
          description: detail,
          name,
          toppings: newToppings,
          size: newSize,
          price: newPrice,
          images,
          category: newCategory,
          id,
        },
      });
      console.log("state >>> ", this.state);
    }
  }

  updateField = async (e, selectStaus, fileStatus, textValue, selectKey) => {
    let target = selectStaus ? e : e.target,
      name,
      value,
      checked,
      files;
    if (e) {
      name = target.name;
      value = target.value;
      checked = target.checked;
      files = target.files;
    }
    let { classStatus } = this.state;
    if (textValue) {
      this.setState((prevState) => ({
        amnetiesCheckedVal: {
          ...prevState.amnetiesCheckedVal,
          [textValue.id]: checked,
        },
      }));
    } else if (fileStatus) {
      this.fileUpload(files, value, name);
    } else {
      if (value === "") this.setState({ ...classStatus, [name]: false });
      if (selectKey && selectStaus) {
        let listName = e ? e[0].name : selectKey,
          listVal = e ? e.map((e) => e.value) : []; //updating multiselect value
        if (listName === "pricingPlanId") {
          let duration = {};
          if (e)
            e.forEach((e) => {
              duration[e.dataVal.duration] = true;
            });
          this.props.updatePricingPlanList(duration);
        }
        this.updateState("jabbrBoxData", { [listName]: listVal });
      } else {
        this.updateInputox(name, value); //updating single select value
      }
    }
  };

  updateInputox = async (name, value) => {
    if (name === "locationId") {
      await this.props.getLocationDetails(value);
      let {
          timezone,
          longitude,
          latitude,
          zipcode,
        } = this.props.locationDetail,
        data = {
          zipcode: zipcode,
          timezone: timezone,
          latitude: latitude,
          longitude: longitude,
          locationId: value,
        };
      this.updateState("jabbrBoxData", data);
      for (let key in data) {
        this.updateClassName(null, true, key, true);
      }
    }
    // else if (name === 'size') {
    //   if (value.charAt(value.length) !== '.' && /^\d*$/.test(value)) {
    //     jabbrBoxDataNew[name] = value
    //     this.setState({ jabbrBoxDataNew: jabbrBoxDataNew })
    //   }}
    else if (name === "latitude") {
      if (value >= -90 && value <= 90)
        this.updateState("jabbrBoxData", { [name]: value });
    } else if (name === "longitude") {
      if (value >= -180 && value <= 180)
        this.updateState("jabbrBoxData", { [name]: value });
    } else this.updateState("jabbrBoxData", { [name]: value });
  };

  fileUpload = (files, value, name) => {
    let { classStatus, imageData, jabbrBoxData } = this.state;
    const reader = new FileReader();
    const file = files[0];
    reader.onloadend = () => {
      // converting it to base_64
      const length = this.props.editJboxStatus
        ? imageData.file.length + jabbrBoxData.images.length
        : imageData.file.length;
      if (length < 5)
        this.updateState("imageData", {
          file: [...imageData.file, file],
          image: [...imageData.image, reader.result],
        });
    };
    reader.readAsDataURL(file);
    if (value === "") this.setState({ ...classStatus, [name]: false });
  };

  updateClassName = async (e, status, name, isAuto) => {
    let { jabbrBoxData, classStatus } = this.state;
    const newName = status ? name : e.target.name;
    let { setAttrName, attriName } = this.props;
    if (newName !== attriName) {
      setAttrName(newName);
      if (isAuto) {
        jabbrBoxData[newName] === null
          ? this.updateState("classStatus", {
              [newName]: { isFilled: false, status: false },
            })
          : this.updateState("classStatus", {
              [newName]: { isFilled: true, status: true },
            });
      } else {
        let data = getHtmlClass(jabbrBoxData, classStatus, attriName, newName);
        await this.setState((prevState) => ({
          classStatus: { ...prevState["classStatus"], data },
        }));
      }
    }
  };

  deleteImage = (index, status) => {
    const { jabbrBoxData } = this.state;
    let images = jabbrBoxData.images;
    images.splice(index, 1);
    this.setState({
      jabbrBoxData: {
        ...jabbrBoxData,
        images,
      },
    });
    // if (status) {
    //   const file = jabbrBoxData.images.filter((_, i) => i !== index);
    //   this.setState((prevState) => ({
    //     jabbrBoxData: {
    //       ...prevState.jabbrBoxData,
    //       images: file,
    //     },
    //   }));
    // } else {
    //   const file = imageData.file.filter((_, i) => i !== index),
    //     image = imageData.image.filter((_, i) => i !== index);
    //   this.updateState("imageData", { file, image });
    // }
  };

  updateState = async (state, value) => {
    await this.setState((prevState) => ({
      [state]: { ...prevState[state], ...value },
    }));
  };

  addUpdateJBoxData = async () => {
    // const { selectedImages } = this.props;
    const {
      name,
      size,
      price,
      description,
      category,
      toppings,
      id,
      images,
    } = this.state.jabbrBoxData;

    // const images = images.map((item) => {
    //   return item.url;
    // });
    // {this.state.jabbrBoxData.category.value === "3" ? (
    const sizeOptions = size.map((item) => {
      return {
        size: item.size.toLowerCase(),
        subTitle: category.value === "3" ? "ml" : item.subTitle,
      };
    });
    const priceOptions = price.map((item, index) => {
      return { price: item.price, size: sizeOptions[index].size.toLowerCase() };
    });
    const finalToppings = toppings.map((item, index) => {
      return { canRemove: item.canRemove, name: item.name, id: item.id };
    });
    const finalData = {
      price: priceOptions,
      images,
      name,
      detail: description,
      category: category.value,
      toppings: finalToppings,
      size: sizeOptions,
    };
    console.log("finalData", finalData);
    if (id) {
      console.log("finalData update", finalData);
      const respone = await firestore()
        .collection("menu")
        .doc(id)
        .update(finalData);
      console.log("respone", respone);
    } else {
      const respone = await firestore().collection("menu").add(finalData);
      console.log("respone", respone);
    }
    this.handleClose();
  };

  buttonFlow = (jabbrBoxData) => {
    const condition1 =
      jabbrBoxData &&
      jabbrBoxData.name &&
      jabbrBoxData.category &&
      jabbrBoxData.price &&
      jabbrBoxData.price.length > 0 &&
      jabbrBoxData.size &&
      jabbrBoxData.size.length > 0 &&
      jabbrBoxData.toppings &&
      jabbrBoxData.toppings.length > 0 &&
      jabbrBoxData.description;
    if (this.props.editJboxStatus) return condition1 ? false : true;
    else return condition1 ? false : true;
  };

  cleanObject = (obj) => {
    for (const propName in obj) {
      if (
        propName !== "sponsorId" &&
        (obj[propName] === null || obj[propName] === undefined)
      )
        delete obj[propName];
      if (propName === "timezone" && obj[propName]) {
        let timezone = obj[propName].split(" (UTC");
        obj[propName] = timezone[0];
      }
    }
    if (this.props.editJboxStatus) {
      const image = obj.images.map((e) => e.url);
      obj["images"] = image;
    }

    if (this.props.signedUrl.length !== 0) {
      if (!obj["images"]) obj["images"] = [];
      this.props.signedUrl.forEach((e) => obj["images"].push(e));
    }
    return obj;
  };

  uploadData = (imageData, images) => {
    return (
      <div className="upload-image-wrapper">
        <h3 className="upload-image-heading">
          Add Images (Image size should be maximum 1242 x 630 pixels at 72 dpi.
          File format: jpeg, png.)
        </h3>
        <div className="upload-image-row edit-upload-image-row">
          <div className="custom-upload">
            <input
              onClick={() => {
                this.setState({ showImagePicker: true });
              }}
              id="file-upload"
            />
            <label for="file-upload" className="file-upload-label">
              <img src={UploadIcon} alt="upload-icon" />
              <p className="file-upload-text">Upload Image</p>
            </label>
          </div>
          <div className="images-show-row">
            {this.state.jabbrBoxData.images &&
              this.state.jabbrBoxData.images.map((e, i) => (
                <div key={i} className="image-with-delete-box">
                  <img src={e} alt="signin-back" />
                  <i
                    onClick={() => this.deleteImage(i, false)}
                    className="icon-cross"
                  ></i>
                </div>
              ))}
            {/* {this.props.selectedImages &&
              this.props.selectedImages.map((e, i) => (
                <div key={i} className="image-with-delete-box">
                  <img src={e.url} alt="signin-back" />
                  <i
                    onClick={() => this.deleteImage(i, true)}
                    className="icon-cross"
                  ></i>
                </div>
              ))} */}
          </div>
        </div>
      </div>
    );
  };
  dropdownInfo = (classStatus, editJboxStatus) => {
    return (
      <>
        <div className="col-md-6">
          <div className="form-group">
            <div
              components={animatedComponents}
              onFocus={(e) => this.updateClassName(e, true, "price")}
              className={`custom-dropdown-with-values floating-input ${getClassName(
                classStatus.price.status,
                editJboxStatus,
                classStatus.price.isFilled
              )}`}
            >
              <label>Price</label>
              <CreatableSelect
                value={this.state.jabbrBoxData.price}
                isMulti
                defaultValue={this.state.jabbrBoxData.price}
                onChange={async (newValue, actionMeta) => {
                  console.log("eeee", newValue, actionMeta);
                  const { jabbrBoxData } = this.state;
                  const prices =
                    newValue &&
                    newValue.map((item) => {
                      return {
                        price: item.value,
                        label: item.value,
                        name: item.value,
                        value: item.value,
                      };
                    });
                  await this.setState({
                    jabbrBoxData: {
                      ...jabbrBoxData,
                      price: prices,
                    },
                  });
                  console.log("state", this.state);
                }}
                options={[]}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div
              onFocus={(e) => this.updateClassName(e, true, "size")}
              className={`custom-dropdown-with-values floating-input ${getClassName(
                classStatus.size.status,
                editJboxStatus,
                classStatus.size.isFilled
              )}`}
            >
              <label>Size</label>
              {this.state.jabbrBoxData.category.value === "3" ? (
                <CreatableSelect
                  value={this.state.jabbrBoxData.size}
                  isMulti
                  defaultValue={this.state.jabbrBoxData.size}
                  onChange={async (newValue, actionMeta) => {
                    console.log("eeee", newValue, actionMeta);
                    const { jabbrBoxData } = this.state;
                    const size =
                      newValue &&
                      newValue.map((item) => {
                        return {
                          size: item.value,
                          label: item.value,
                          name: item.value,
                          value: item.value,
                        };
                      });
                    await this.setState({
                      jabbrBoxData: {
                        ...jabbrBoxData,
                        size: size,
                      },
                    });
                    console.log("state", this.state);
                  }}
                  options={[]}
                />
              ) : (
                <Select
                  components={animatedComponents}
                  value={this.state.jabbrBoxData.size}
                  options={this.state.sizeOptions}
                  name="size"
                  onChange={async (e) => {
                    console.log("e", e);
                    if (e) {
                      const { jabbrBoxData } = this.state;
                      await this.setState({
                        jabbrBoxData: {
                          ...jabbrBoxData,
                          size: e.map((item) => {
                            return { ...item, size: item.name };
                          }),
                        },
                      });
                    }
                    console.log("state", this.state);
                  }}
                  isMulti
                  className="basic-multi-select"
                  placeholder="Size"
                />
              )}
            </div>
          </div>
        </div>
        {this.state.jabbrBoxData.category.value === "3" ? null : (
          <div className="col-md-6">
            <div className="form-group">
              <div
                onFocus={(e) => this.updateClassName(e, true, "size")}
                className={`custom-dropdown-with-values floating-input ${getClassName(
                  classStatus.size.status,
                  editJboxStatus,
                  classStatus.size.isFilled
                )}`}
              >
                <label>Toppings</label>
                <Select
                  value={this.state.jabbrBoxData.toppings}
                  defaultValue={this.state.jabbrBoxData.toppings}
                  options={this.state.toppings}
                  name="toppings"
                  onChange={async (e) => {
                    console.log("e", e);
                    const { jabbrBoxData } = this.state;
                    let canRemove = true;
                    await this.setState({
                      jabbrBoxData: {
                        ...jabbrBoxData,
                        toppings: e.map((item) => {
                          if (item.name === "Mozzarella") {
                            canRemove = false;
                          } else {
                            canRemove = true;
                          }
                          return { ...item, id: item.id, canRemove };
                        }),
                      },
                    });
                    console.log("state", this.state);
                  }}
                  isMulti
                  className="basic-multi-select"
                  placeholder="Topping"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  getOffset = (e) => {
    let date = new Date();
    return moment(date).tz(e).format("Z");
  };

  zipTime = (
    classStatus,
    editJboxStatus,
    jabbrBoxData,
    timezone,
    TimeZones
  ) => {
    return (
      <>
        <div className="col-md-6">
          <div className="form-group">
            <div
              onFocus={(e) => this.updateClassName(e, true, "toppings")}
              className={`custom-dropdown-with-values floating-input ${getClassName(
                classStatus.toppings.status,
                editJboxStatus,
                classStatus.toppings.isFilled
              )}`}
            >
              <label>Toppings</label>
              <Select
                // onMenuScrollToBottom={() => handleScrolledList(1, "plans")}
                defaultValue={{
                  value: "sponsor.id",
                  label: "sponsor.name",
                  name: "sponsorId",
                }}
                options={[
                  {
                    value: "Regular",
                    label: "Regular",
                    name: "Regular",
                    dataVal: "e",
                  },
                ]}
                name="Toppings"
                onChange={(e) =>
                  this.updateField(e, true, false, null, "toppings")
                }
                className="basic-multi-select"
                placeholder="Toppings"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  sponsorAddress = (
    classStatus,
    editJboxStatus,
    sponsorId,
    sponsor,
    sponsorScrollList,
    jabbrBoxData
  ) => {
    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <div
              className={`floating-input ${getClassName(
                classStatus.description.status,
                editJboxStatus,
                classStatus.description.isFilled
              )}`}
            >
              <label>Description</label>
              <textarea
                onFocus={(e) => this.updateClassName(e)}
                value={jabbrBoxData && jabbrBoxData.description}
                // onChange={(e) => this.updateField(e, false, false)}
                onChange={async (e) => {
                  console.log("e", e);
                  const { jabbrBoxData } = this.state;
                  await this.setState({
                    jabbrBoxData: {
                      ...jabbrBoxData,
                      description: e.target.value,
                    },
                  });
                  console.log("state", this.state);
                }}
                className="form-control"
                name="description"
              ></textarea>
            </div>
          </div>
        </div>
      </>
    );
  };
  IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  openSaveConfigModal = async (data, status, cancelStatus) => {
    let { updatedConfigDetails, hideShowConfig } = this.state;
    if (status) await this.setState({ configDetails: updatedConfigDetails });
    else if (!cancelStatus)
      await this.setState({
        updatedConfigDetails: data && data.updated_src,
        updatedRawConfigDetails: JSON.stringify(
          data && data.updated_src,
          undefined,
          2
        ),
      });
    if (cancelStatus) this.setState({ hideShowConfig: !hideShowConfig });
  };

  updateRawText = async (e) => {
    this.setState({ updatedRawConfigDetails: e.target.value });
    if (this.IsValidJSONString(e.target.value))
      this.setState({
        updatedConfigDetails: JSON.parse(e.target.value),
        hideShowErr: "green-text",
      });
    else this.setState({ hideShowErr: "red-text" });
  };

  getValue = (value) => {
    return value ? value : "";
  };

  handleClose = () => {
    this.emptyCurrentState();
    this.props.emptyState();
    // this.props.updatePricingPlan();
  };

  emptyCurrentState = async () => {
    await this.setState(this.initialState);
    await this.setState({ classStatus: { ...jBoxClass } });
  };

  handleChangeTab = async () => {
    if (!this.IsValidJSONString(this.state.updatedRawConfigDetails))
      await this.setState({
        updatedRawConfigDetails: JSON.stringify(
          this.state.updatedConfigDetails,
          undefined,
          2
        ),
      });
    this.setState({ hideShowErr: null });
  };

  onSelectImage = async (i) => {
    try {
      const image = this.props.downloadableUrls[i];
      const { images = [] } = this.state.jabbrBoxData;
      await this.setState({
        jabbrBoxData: {
          ...this.state.jabbrBoxData,
          images: [...images, image.url],
        },
      });
      console.log("image ", this.state.jabbrBoxData);
    } catch (e) {
      console.log("Error", e);
    }
  };

  render() {
    let {
        jabbrBoxData,
        classStatus,
        imageData,
        hideShowConfig,
        updatedRawConfigDetails,
        hideShowErr,
        showImagePicker,
      } = this.state,
      {
        jabbrrboxDetail,
        editJboxStatus,
        handleScrolledList,
        locScrollList,
        sponsorScrollList,
        planScrollList,
      } = this.props,
      { jabbrrboxPricingPlans, sponsorId, sponsor, location, address, images } =
        jabbrBoxData || {};
    return (
      <>
        <AddEditConfig
          isdetailPage={false}
          hideShowConfig={hideShowConfig}
          updatedConfigDetails={this.state.updatedConfigDetails}
          editJboxStatus={editJboxStatus}
          openSaveConfigModal={this.openSaveConfigModal}
          hideShowErr={hideShowErr}
          updateRawText={this.updateRawText}
          handleChangeTab={this.handleChangeTab}
          updatedRawConfigDetails={updatedRawConfigDetails}
        />
        <div className="admin-right-detail-wrapper right-detail-with-footer">
          <div className="admin-right-detail-box add-detail-form-box add-detail-form-box-gap">
            <div className="add-detail-form-box-head">
              <h2 className="add-detail-heading">
                {editJboxStatus ? "Edit Menu" : "Add Menu"}
              </h2>
            </div>
            <div className="add-detail-form-box-inner">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div
                      className={`floating-input ${getClassName(
                        classStatus.name.status,
                        editJboxStatus,
                        classStatus.name.isFilled
                      )}`}
                    >
                      <label>Name</label>
                      <input
                        type="text"
                        value={jabbrBoxData && jabbrBoxData.name}
                        onChange={async (e) => {
                          // this.updateField(e, false, false)
                          const { jabbrBoxData } = this.state;
                          await this.setState({
                            jabbrBoxData: {
                              ...jabbrBoxData,
                              name: e.target.value,
                            },
                          });
                          console.log("state", this.state);
                        }}
                        onFocus={(e) => this.updateClassName(e)}
                        className="form-control"
                        name="name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div
                      onFocus={(e) => this.updateClassName(e, true, "category")}
                      className={`custom-dropdown-with-values floating-input ${getClassName(
                        classStatus.category.status,
                        editJboxStatus,
                        classStatus.category.isFilled
                      )}`}
                    >
                      <label>Category</label>
                      <Select
                        value={this.state.jabbrBoxData.category}
                        defaultValue={this.state.jabbrBoxData.category}
                        options={[
                          {
                            value: "2",
                            label: "Veg",
                            name: "Veg",
                          },
                          {
                            value: "1",
                            label: "Non-Veg",
                            name: "Non-Veg",
                          },
                          {
                            value: "3",
                            label: "Drinks",
                            name: "Drinks",
                          },
                        ]}
                        name="category"
                        onChange={async (e) => {
                          console.log("eeee", e);
                          const { jabbrBoxData } = this.state;
                          await this.setState({
                            jabbrBoxData: {
                              ...jabbrBoxData,
                              category: e,
                            },
                          });
                          console.log("state", this.state);
                        }}
                        className="basic-multi-select"
                        placeholder="Category"
                      />
                    </div>
                  </div>
                </div>
                {this.dropdownInfo(
                  classStatus,
                  editJboxStatus,
                  handleScrolledList,
                  jabbrrboxPricingPlans,
                  planScrollList,
                  address,
                  location,
                  locScrollList
                )}
                {this.sponsorAddress(
                  classStatus,
                  editJboxStatus,
                  sponsorId,
                  sponsor,
                  sponsorScrollList,
                  jabbrBoxData
                )}
              </div>
              {this.uploadData(imageData, images)}
            </div>
          </div>
        </div>
        <Footer
          openSaveConfigModal={this.openSaveConfigModal}
          addUpdateData={this.addUpdateJBoxData}
          details={jabbrrboxDetail}
          footerStatus={true}
          handleCancel={this.handleClose}
          editStatus={editJboxStatus}
          enableButton={this.buttonFlow(jabbrBoxData)}
        />
        <AddMenuImages
          handleClose={() => {
            this.setState({ showImagePicker: false });
          }}
          addPartnerHocStatus={showImagePicker}
          showModal={this.state.showImagePicker}
          downloadableUrls={this.props.downloadableUrls}
          onSelectImage={this.onSelectImage}
          emptyState={() => this.setState({ showImagePicker: false })}
        />
      </>
    );
  }
}

export default AddEditJMenuModal;
