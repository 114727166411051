/** Email */
export const emailRejex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/** Password */
export const passwordRejex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?+&])[A-Za-z\d@$!%*?&]{8,}$/;

/** Promo Code */
export const promocodeReqex = /^[a-zA-Z0-9]*$/;

/** Discount */
export const discountRegex = /^\d*\.?\d{0,2}$/;

/** MaxUser */
export const maxLimitRegex = /^[0-9]*$/;
