import store from '../store/configureStore';
import history from '../store/history';

/**
 * Function for set value
 * @param {string} field
 * @param {string} value
 */
export const setItem = (field, value) => localStorage.setItem(field, value);

/**
 * Function for get value
 * @param {string} field
 * @param {string} value
 */
export const getItem = field => localStorage.getItem(field);

export const getPropertyName = (id, key) => {
  let list = [];
  if (key === 'Location') {
    const locations = store.getState().locationList;
    list = locations.list || [];
  }
  const selectedObject = list.find(data => data.id === id) || {};
  return selectedObject.name || null;
};

export const handleActiveTab = route => {
  const selectedTab = history.location.pathname;
  return selectedTab === route ? 'active' : '';
};
