import React from 'react';
import { Modal } from 'react-bootstrap';

function DeleteModal({
  heading,
  addRemoveDeleteModal,
  openDeleteModal,
  deleteDataDetails,
  openCancelModal,
  isInside,
  bookingDetailsHeader,
  action
}) {
  return (
    <Modal show={addRemoveDeleteModal} className='delete-modal' centered>
      <Modal.Body>
        <h3 className='delete-modal-heading'>
          {heading
            ? `Delete ${heading}`
            : openCancelModal
              ? 'Cancel Booking'
              : 'Change Status'}
        </h3>
        {heading ? (
          <p className='delete-modal-text'>
            Are you sure you want to delete <br />
            {`this ${heading} from the list?`}
          </p>
        ) : openCancelModal ? (
          <p className='delete-modal-text'>
            Are you sure you want to cancel this <br />
            booking?
          </p>
        ) : (
              <p className='delete-modal-text'>
                Are you sure you want to change the status?
          </p>
            )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn secondary-btn primary-main'
          data-dismiss='modal'
          onClick={() => openDeleteModal(true, deleteDataDetails, isInside,
            bookingDetailsHeader && bookingDetailsHeader)}
        >
          Yes
        </button>
        <button
          className='btn primary-btn primary-main btn-right-gap'
          onClick={() => openDeleteModal(false, deleteDataDetails)}
        >
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
