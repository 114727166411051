import React from "react";
import { CSVLink } from "react-csv";
import {
  headersUser,
  headersBooking,
  headersBookingPast,
  headersBookingCancel,
  headerblintzboxStatics,
  headerRevenue,
  headerLocation,
} from "../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CsvButton = (props) => {
  const {
    csvData,
    heading,
    bookingTabVal,
    showDate,
    handleDateChange,
    bookingDetailsHeader,
    dateType,
    action,
    isApply,
  } = props;
  const headerValue = (heading, bookingTabVal) => {
    let headValue;
    switch (heading) {
      case "BOOKING":
        headValue = headersBooking;
        break;
      case "REVENUE":
        headValue = headerRevenue;
        break;
      case "DASHBOARD":
        headValue = headerblintzboxStatics;
        break;
      case "LOCATIONS":
        headValue = headerLocation;
        break;
      default:
        headValue = headersUser;
    }
    return headValue;
  };
  const filenameValue = (heading) => {
    let nameValue;
    switch (heading) {
      case "DASHBOARD":
        nameValue = "blintzbox_Statistics.csv";
        break;
      case "BOOKING":
        nameValue = "booking.csv";
        break;
      case "REVENUE":
        nameValue = "Revenue.csv";
        break;
      case "LOCATIONS":
        nameValue = "Location.csv";
        break;
      default:
        nameValue = "usermanagement.csv";
    }
    return nameValue;
  };
  return (
    <>
      <div className="admin-right-header-bottom-right booking-right-header-with-date">
        {showDate && (
          <>
            <div className="form-group small-calendar-form-group">
              <DatePicker
                placeholderText="Start Date"
                selected={dateType.startDate}
                className="form-control custom-date-picker"
                onChange={(e) =>
                  handleDateChange(
                    e,
                    "startDate",
                    bookingDetailsHeader && bookingDetailsHeader[1],
                    action
                  )
                }
              />
            </div>
            <div className="form-group small-calendar-form-group">
              <DatePicker
                placeholderText="End Date"
                selected={dateType.endDate}
                minDate={dateType.startDate}
                disabled={
                  dateType.startDate === "" || dateType.startDate === null
                    ? true
                    : false
                }
                className="form-control custom-date-picker"
                onChange={(e) =>
                  handleDateChange(
                    e,
                    "endDate",
                    bookingDetailsHeader && bookingDetailsHeader[1],
                    action
                  )
                }
              />
            </div>
          </>
        )}
        {isApply && (
          <button
            className="btn primary-btn btn-block add-partner-api-btn"
            onClick={() => handleDateChange(null, null, null, null, true)}
          >
            Apply
          </button>
        )}
        <CSVLink
          data={csvData || []}
          filename={filenameValue(heading)}
          headers={headerValue(heading, bookingTabVal)}
          disabled={true}
          target="_blank"
          className="btn primary-btn btn-block primary-btn-with-a add-partner-api-btn"
        >
          Export to CSV
        </CSVLink>
      </div>
    </>
  );
};

export default CsvButton;
