import React, { Component } from "react";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { USER_ID, AUTH_TOKEN, BLINTZBOX_ROUTE } from "../constants";
import * as actions from "../actions";
import history from "../store/history";
import { getItem } from "../utils";
import Routes from "../routes";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

history.listen(() => window.scrollTo(0, 0));

class App extends Component {
  componentDidMount() {
    const { getUserDetails, userDetail } = this.props;
    const accessToken = getItem(AUTH_TOKEN);
    const userId = getItem(USER_ID);
    const location = history.location.pathname;
    if (accessToken) {
      if (isEmpty(userDetail)) {
        getUserDetails(userId);
      }
      location === "/" ? history.push(BLINTZBOX_ROUTE) : history.push(location);
    }
  }

  render() {
    return (
      <Router history={history}>
        <Routes />
        <ToastContainer transition={Slide} />
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.user,
});
export default connect(mapStateToProps, actions)(App);
