import {   cyan500, cyan700,
  grey100, grey300, grey400, grey500,
  white, darkBlack, fullBlack } from 'material-ui/styles/colors'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

const Color = {
  BrandPrimary: '#FFD938',
  Neutral: '#78909C',
  NeutralLight: '#ECEFF1',
  NeutralDark: '#455A64',
  White: '#FFFFFF',
  Error: '#EE6161',
  Success: '#8FCD4C',
  InternalLight: '#9FB3C2',
}
export const muiTheme = getMuiTheme({
  palette: {
    primary1Color: Color.NeutralDark,
    primary2Color: cyan700,
    primary3Color: grey400,
    accent1Color: Color.BrandPrimary,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: darkBlack,
    alternateTextColor: Color.BrandPrimary,
    selectedTextColor: Color.NeutralDark,
    canvasColor: white,
    borderColor: grey300,
    pickerHeaderColor: cyan500,
    shadowColor: fullBlack,
  },
})
