import axios from "axios";

import * as constant from "./constants";
import { config } from "./config";
import { getItem } from "./utils";
import { errorToast } from "./components/blintzbox/ToastNotification";
import history from "./store/history";

/**
 * Function to handle interceptor
 */
export const setupInterceptors = () => {
  axios.interceptors.request.use((reqConfig) => {
    const storageToken = getItem(constant.AUTH_TOKEN);
    const access_token = storageToken ? storageToken : "";
    if (access_token) {
      reqConfig.headers.Authorization = `Bearer ${access_token}`;
      reqConfig.headers["x-partner-key"] = constant.PARTNER_API_KEY;
    }
    if (reqConfig.url.includes("https")) {
      delete reqConfig.headers;
    }
    reqConfig.baseURL = config.api_url;
    return reqConfig;
  });
  axios.interceptors.response.use(
    (response) => {
      if (response && response.data.data) {
        return response.data.data;
      } else if (response && response.data.token) {
        return response.data;
      } else {
        return response.data.message;
      }
    },
    function (error) {
      if (error.response && error.response.status === 500)
        errorToast(1, "Could-not connect to Server Please try again later.");
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        errorToast(1, error.response.data.message);
        history.push("/");
      }
      return Promise.reject(error);
    }
  );
};
