import axios from "axios";
import moment from "moment";
import { BOOKING_URL } from "../constants";

const date = new Date();

/**
 * Function to get Booking list
 */
export const fetchBookingList = (
  filter,
  page,
  offset,
  sort,
  type,
  userId,
  startDate,
  endDate,
  search,
  businessPartnerId
) =>
  axios.get(
    `/admin/bookings?page=${page || 0}&limit=${offset || 10}&${
      sort || "sortByStartDate"
    }=${type || "DESC"}${filter ? `&filter=${filter}` : ""}${
      userId ? `&userId=${userId}` : ""
    }${
      startDate ? `&startDate=${moment(startDate).format("YYYY-MM-DD")}` : ""
    }${
      startDate && !endDate
        ? `&endDate=${moment(date.setDate(startDate.getDate() + 1)).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }${endDate ? `&endDate=${moment(endDate).format("YYYY-MM-DD")}` : ""}${
      search ? `&search=${search}` : ""
    }${businessPartnerId ? `&businessPartnerId=${businessPartnerId}` : ""}`
  );
/**
 * Function to get Booking details
 * @param {Object} payload
 */
export const getBookingInfo = (id) => axios.get(BOOKING_URL + "/" + id);

/**
 * Function to add Booking
 * @param {Object} payload
 */
export const addBooking = (payload) => axios.post(BOOKING_URL, payload);

/**
 * Function to update Booking details
 * @param {Object} payload
 */
export const updateBookingInfo = (id, payload) =>
  axios.put(BOOKING_URL + "/" + id, payload);

/**
 * Function to delete Booking details
 * @param {Object} payload
 */
export const deleteBookingInfo = (id) =>
  axios.delete(`admin${BOOKING_URL}/${id}`);

/**
 * Function to delete Booking details
 * @param {Object} payload
 */
export const getBookingPriceDetailsApi = (id) =>
  axios.get(`${BOOKING_URL}/${id}/payment-receipt`);

export const refundBookingAmount = (id, payload) =>
  axios.put(`admin${BOOKING_URL}/${id}/refund`, payload);
/**
 * Function to delete Booking details
 * @param {Object} payload
 */
export const lockUpcomingBookingApi = (id) =>
  axios.get(`admin${BOOKING_URL}/${id}/unlock`);

export const sendImagesApi = (id, email) =>
  axios.get(`admin${BOOKING_URL}/${id}/send-image?email=${email}`);

export const getRefundDetailsApi = (id) =>
  axios.get(`admin${BOOKING_URL}/${id}/refund`);

export const sendPaymentReciptApi = (id) =>
  axios.post(`${BOOKING_URL}/${id}/payment-receipt/send-mail`);

export const getBookingImagesApi = (id) =>
  axios.get(`admin/photo?blintzboxReservationId=${id}`);
