import axios from "axios";
import { LOGIN, LOGOUT, USER_URL } from "../constants";
import firebase from "../config/FirebaseConfig";
/**
 * Function for sign in
 * @param  {Object} payload
 */
export const logInUser = (payload) => {
  console.log("payload:", payload);
  const { password, email } = payload;
  console.log("EMAIL", email);
  console.log("PASSWORD", password);
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

/**
 * Function for sign out
 */
export const logOutApi = () => axios.put(LOGOUT);

/**
 * Function for sign out
 */
export const updatePasswordApi = (payload) =>
  axios.put(USER_URL + "/changePassword", payload);
