export const LOGOUT = "LOGOUT";

export const LOGGED_IN = "LOGGED_IN";

export const END_LOADER_CALL = "END_LOADER_CALL";
export const BEGIN_LOADER_CALL = "BEGIN_LOADER_CALL";

export const ERROR_TOAST = "ERROR_TOAST";
export const SUCCESS_TOAST = "SUCCESS_TOAST";

export const USER_LIST = "USER_LIST";
export const USER_DETAIL = "USER_DETAIL";
export const USER_DETAIL_BY_ID = "USER_DETAIL_BY_ID";
export const USER_UPDATED = "USER_UPDATED";
export const USER_ASSIGN_CREDIT = "USER_ASSIGN_CREDIT";
export const USER_ASSIGN_PROMOCODE = "USER_ASSIGN_PROMOCODE";
export const GET_CREDIT = "CREDIT";
export const EDIT_CREDIT = "EDIT_CREDIT";
export const GET_PROMO_CODE = "GET_PROMO_CODE";
export const EDIT_PROMO_CODE = "EDIT_PROMO_CODE";

export const MENU_IMAGES = "MENU_IMAGES";

export const BLINTZBOX_LIST = "BLINTZBOX_LIST";
export const BLINTZBOX_DETAIL = "BLINTZBOX_DETAIL";
export const BLINTZBOX_CREATED = "BLINTZBOX_CREATED";
export const BLINTZBOX_UPDATED = "BLINTZBOX_UPDATED";
export const BLINTZBOX_REMOVED = "BLINTZBOX_REMOVED";
export const PAGINATION_PAGE = "PAGINATION_PAGE";
export const SIGNED_URL_DATA = "SIGNED_URL_DATA";
export const ATTRIBUTE_NAME = "ATTRIBUTE_NAME";

export const LOCATION_LIST = "LOCATION_LIST";
export const LOCATION_DETAILS = "LOCATION_DETAILS";
export const LOCATION_UPDATED = "LOCATION_UPDATED";
export const LOCATION_DELETED = "LOCATION_DELETED";
export const LOCATION_CREATED = "LOCATION_CREATED";
export const LOCATION_COUNTRIES = "LOCATION_COUNTRIES";
export const LOCATION_CITIES = "LOCATION_CITIES";
export const LOCATION_STATES = "LOCATION_STATES";
export const LAT_LONG = "LAT_LONG";
export const LOCATION_CSV = "LOCATION_CSV";

export const SPONSOR_LIST = "SPONSOR_LIST";
export const SPONSOR_DETAILS = "SPONSOR_DETAILS";
export const SPONSOR_UPDATED = "SPONSOR_UPDATED";
export const SPONSOR_DELETED = "SPONSOR_DELETED";
export const SPONSOR_CREATED = "SPONSOR_CREATED";

export const PARTNERS_LIST = "PARTNERS_LIST";
export const PARTNERS_DETAILS = "PARTNERS_DETAILS";
export const PARTNERS_UPDATED = "PARTNERS_UPDATED";
export const PARTNERS_DELETED = "PARTNERS_DELETED";
export const PARTNERS_CREATED = "PARTNERS_CREATED";

export const AMENITIES_LIST = "AMENITIES_LIST";
export const AMENITIES_DETAILS = "AMENITIES_DETAILS";
export const AMENITIES_UPDATED = "AMENITIES_UPDATED";
export const AMENITIES_DELETED = "AMENITIES_DELETED";
export const AMENITIES_CREATED = "AMENITIES_CREATED";

export const PRICING_PLANES_LIST = "PRICING_PLANES_LIST";
export const PRICING_PLAN_DETAIL = "PRICING_PLAN_DETAIL";
export const PRICING_PLAN_CREATED = "PRICING_PLAN_CREATED";
export const PRICING_PLAN_UPDATED = "PRICING_PLAN_UPDATED";
export const PRICING_PLAN_REMOVED = "PRICING_PLAN_REMOVED";

export const BOOKING_CURRENT = "BOOKING_CURRENT";
export const BOOKING_UPCOMMING = "BOOKING_UPCOMMING";
export const BOOKING_PAST = "BOOKING_PAST";
export const BOOKING_CANCELED = "BOOKING_CANCELED";
export const BOOKING_DETAIL = "BOOKING_DETAIL";
export const BOOKING_CREATED = "BOOKING_CREATED";
export const BOOKING_UPDATED = "BOOKING_UPDATED";
export const BOOKING_REMOVED = "BOOKING_REMOVED";
export const BOOKING_PRICE = "BOOKING_PRICE";
export const BOOKING_REFUND = "BOOKING_REFUND";
export const BOOKING_IMAGES = "BOOKING_IMAGES";

export const AUTHORIZATION_TOKEN = "AUTHORIZATION_TOKEN";

export const DASHBOARD_PANEL_REPORT = "DASHBOARD_PANEL_REPORT";
export const BLINTZBOX_STATICS = "BLINTZBOX_STATICS";
export const REVENUE_STATICS = "REVENUE_STATICS";
export const USER_DATA_CSV = "USER_DATA_CSV";
export const BOOKING_CSV = "BOOKING_CSV";

export const PARTNER_API_LIST = "PARTNER_API_LIST";
export const PARTNERS_API_CREATED = "PARTNERS_API_CREATED";
export const PARTNERS_API_DELETED = "PARTNERS_API_DELETED";
export const PARTNERS_API_UPDATED = "PARTNERS_API_UPDATED";

export const DASHBOARD_ORDER_COUNT = "DASHBOARD_ORDER_COUNT";
export const DASHBOARD_CUSTOMER_COUNT = "DASHBOARD_CUSTOMER_COUNT";
