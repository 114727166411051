import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import AddEditMenuModal from "../components/blintzbox/AddEditMenuModal";
import Header from "../components/rightPanel/Header";
import { BLINTZBOX_HEADERS, BLINTZBOX_ROUTE, MENU_HEADERS } from "../constants";
import ListWrapper from "../components/listWrapper";
import Filters from "../components/rightPanel/filters";
import * as actions from "../actions";
import Loader from "../components/loader/Loader";
import firebase from "../config/FirebaseConfig";

class MenuboxHoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boxDetailId: "",
      addblintzboxStatus: false,
      editJboxStatus: "",
      selectedMenuData: null,
      searchedValue: "",
      deleteJboxStatus: false,
      addRemoveDeleteModal: false,
      planScrollList: [],
      locScrollList: [],
      partnerScrollList: [],
      sponsorScrollList: [],
      sortKey: null,
      sortType: null,
      locationSearch: 0,
      locationName: "",
      addRemoveStatusModal: false,
      changeStatusData: "",
      menuList: [],
      showImagePicker: false,
      downloadableUrls: [],
      images: [],
    };
    this.props.setPaginationPage(0);
    this.initialState = this.state;
  }

  async componentDidMount() {
    const menuCollection = firebase.firestore().collection("menu");
    menuCollection.get().then((snapshot) => {
      let menu = [];
      snapshot.forEach((doc) => {
        console.log(doc.data());
        menu.push({ id: doc.id, ...doc.data() });
      });
      console.log("menu ", menu);
      this.setState({ menuList: menu });
    });

    this.getImages();
  }

  addblintzboxPage = async (status, data) => {
    const { addblintzboxStatus } = this.state;
    console.log("Data", data);
    if (status === "Edit") {
      await this.setState({
        selectedMenuData: data,
        boxDetailId: "",
        editJboxStatus: true,
      });
    }
    await this.setState({ addblintzboxStatus: !addblintzboxStatus });
  };

  sortblintzboxList = (header, type) => {
    const { searchedValue, locationSearch } = this.state;
    const { getblintzboxList } = this.props;
    const headerKey = "sortByName";
    this.setState({ sortKey: headerKey, sortType: type });
    getblintzboxList(searchedValue, 0, 10, locationSearch, headerKey, type);
  };

  getJboxDetails = async (page) => {
    const { sortKey, sortType, locationSearch } = this.state;
    await this.props.setPaginationPage(page - 1);
    this.props.getblintzboxList(
      this.state.searchedValue,
      page - 1,
      10,
      locationSearch,
      sortKey,
      sortType
    );
  };

  // open delete sponsor modal
  deleteJboxPage = async (status, data) => {};

  updatePricingPlan = async () => {
    await this.props.getPricingList("", 0, 10000000, null, null, false);
    this.setState({
      planScrollList: this.props.plans.list,
      initialPlanList: this.props.plans.list,
    });
  };

  searchblintzbox = (value) => {
    // search sponsor list
    const { sortKey, sortType, locationSearch } = this.state;
    this.setState({ searchedValue: value });
    setTimeout(
      () =>
        this.props.getblintzboxList(
          value,
          0,
          10,
          locationSearch,
          sortKey,
          sortType,
          false
        ),
      500
    );
  };

  getFilteredList(e) {
    const { sortKey, sortType } = this.state;
    this.setState({
      locationSearch: e.value,
      locationName: e.label,
    });
    this.props.getblintzboxList(
      this.state.searchedValue,
      0,
      10,
      e.value,
      sortKey,
      sortType
    );
  }

  async showDetails(data) {
    this.setState({
      selectedMenuData: data,
      boxDetailId: data.id,
    });
  }

  getCurrentScroll = async (
    key,
    scrollList,
    list,
    totalCount,
    currentPage,
    totalPages
  ) => {
    if (totalCount > 10000000 && currentPage + 1 < totalPages) {
      await this.props.getPricingList("", currentPage + 1, 10000000);
      this.setState({ [key]: [...scrollList, ...list] });
    }
  };

  handleScrolledList = (status, name) => {
    let { totalPages, currentPage, totalCount } = this.props[name],
      {
        planScrollList,
        locScrollList,
        partnerScrollList,
        sponsorScrollList,
      } = this.state;
    const list = this.props[name].list;
    switch (status) {
      case 1:
        this.getCurrentScroll(
          "planScrollList",
          planScrollList,
          list,
          totalCount,
          currentPage,
          totalPages
        );
        break;
      case 2:
        this.getCurrentScroll(
          "locScrollList",
          locScrollList,
          list,
          totalCount,
          currentPage,
          totalPages
        );
        break;
      case 3:
        this.getCurrentScroll(
          "partnerScrollList",
          partnerScrollList,
          list,
          totalCount,
          currentPage,
          totalPages
        );
        break;
      case 4:
        this.getCurrentScroll(
          "sponsorScrollList",
          sponsorScrollList,
          list,
          totalCount,
          currentPage,
          totalPages
        );
        break;
      default:
        break;
    }
  };

  updatePricingPlanList = async (duration) => {
    let planScrollList = [...this.state.initialPlanList],
      updateplanScrollList = [];
    updateplanScrollList = planScrollList.filter((element) => {
      if (!duration[element.duration]) return element;
      return false;
    });
    await this.setState({ planScrollList: updateplanScrollList });
  };

  changeStatus = async (status, data) => {
    let {
        searchedValue,
        addRemoveStatusModal,
        sortKey,
        sortType,
        locationSearch,
      } = this.state,
      { updateblintzboxStatus, getblintzboxList } = this.props;
    if (status) {
      await updateblintzboxStatus(data.id, { isActive: !data.isActive });
      await getblintzboxList(
        searchedValue,
        0,
        10,
        locationSearch,
        sortKey,
        sortType
      );
    }
    await this.setState({ changeStatusData: data });
    await this.setState({ addRemoveStatusModal: !addRemoveStatusModal });
  };

  emptyState = async (status) => {
    if (status) await this.setState({ searchedValue: "" });
    await this.setState({
      boxDetailId: "",
      addblintzboxStatus: false,
      editJboxStatus: "",
      selectedMenuData: null,
      deleteJboxStatus: false,
      addRemoveDeleteModal: false,
      changeStatusData: null,
    });
  };

  getPizzaImage = async () => {
    const response = await this.props.fetchPizzaImages();
    return response;
  };

  getImages = async () => {
    await this.getPizzaImage();
    console.log("RESPONSE", this.props.menuImagesReducer);
    console.log("RESPONSE", Object.keys(this.props.menuImagesReducer));
    try {
      const imgs = Object.keys(this.props.menuImagesReducer);
      let downloadableUrls = [];
      await imgs.forEach(async (item) => {
        console.log("item", item);
        console.log("item", this.props.menuImagesReducer[item]);
        const name = this.props.menuImagesReducer[item].metadata.name;
        const url = await firebase.storage().ref(name).getDownloadURL();
        console.log("RESPONSE url", url);
        if (!name.includes("bankIcons")) {
          downloadableUrls.push({ url, name });
        }
      });
      await this.setState({
        downloadableUrls,
      });
      console.log("RESPONSE", this.state);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  render() {
    const {
        boxDetailId,
        addblintzboxStatus,
        editJboxStatus,
        partnerScrollList,
        selectedMenuData,
        addRemoveDeleteModal,
        planScrollList,
        locScrollList,
        searchedValue,
        sponsorScrollList,
        locationSearch,
        locationName,
        addRemoveStatusModal,
        changeStatusData,
        menuList,
        showImagePicker,
      } = this.state,
      {
        blintzboxList,
        plans,
        amenities,
        getSignedUrl,
        createBlintzbox,
        updateblintzboxDetails,
        paginationPage,
        attriName,
        getblintzboxList,
        setAttrName,
        blintzboxDetail,
        signedUrl,
        locations,
        emptySignedUrl,
        getLocationDetails,
        locationDetail,
        loading,
      } = this.props;
    return (
      <Fragment>
        {!boxDetailId && !addblintzboxStatus && (
          <>
            <Header
              heading="MENU"
              routeLink={BLINTZBOX_ROUTE}
              isblintzbox={true}
            />
            <Filters
              addButton="Menu"
              numberType="text"
              isStatusFilter={false}
              getFilteredList={this.getFilteredList.bind(this)}
              searchedValue={searchedValue} // searched value
              isLocationFilter={true}
              locations={locations}
              displayPage={this.addblintzboxPage}
              searchListDetails={this.searchblintzbox}
              filterOptions={[]}
              locationSearch={locationSearch}
              locationName={locationName}
            />
            <ListWrapper
              addButton="Menu"
              openStatusModal={true}
              headerList={MENU_HEADERS}
              getPaginationDetails={this.getJboxDetails}
              changeStatus={this.changeStatus}
              showDetails={this.showDetails.bind(this)}
              data={{
                list: menuList,
                totalPages: menuList.length,
                totalCount: menuList.length,
                currentPage: 0,
              }}
              changeStatusData={changeStatusData}
              displayPage={this.addblintzboxPage}
              deleteDataDetails={selectedMenuData}
              addRemoveDeleteModal={addRemoveDeleteModal}
              openDeleteModal={this.deleteJboxPage} // open delete sponsor modalin sponsor list
              paginationPage={paginationPage}
              sortList={this.sortblintzboxList}
              showStatus={true}
              addRemoveStatusModal={addRemoveStatusModal}
              action={(param) => {
                console.log("param", param);
              }}
            />
          </>
        )}

        {addblintzboxStatus ? (
          <>
            <Header
              heading="MENU"
              isblintzbox={true}
              routeLink={BLINTZBOX_ROUTE}
              subHeading={editJboxStatus ? "EDIT MENU" : "Add MENU"}
              searchedValue={searchedValue}
              locationSearch={locationSearch}
              locationName={locationName}
              emptyState={this.emptyState}
            />
            <AddEditMenuModal
              createBlintzbox={createBlintzbox}
              updateblintzboxDetails={updateblintzboxDetails}
              editJboxStatus={editJboxStatus}
              getLocationDetails={getLocationDetails}
              selectedMenuData={selectedMenuData || {}}
              locationSearch={"locationSearch"}
              locationName={"locationName"}
              blintzboxDetail={blintzboxDetail}
              locationDetail={locationDetail}
              sponsorScrollList={sponsorScrollList}
              emptyState={this.emptyState}
              addblintzboxPage={this.addblintzboxPage}
              getblintzboxList={getblintzboxList}
              planScrollList={planScrollList}
              locScrollList={locScrollList}
              handleScrolledList={this.handleScrolledList}
              partnerScrollList={partnerScrollList}
              setAttrName={setAttrName}
              attriName={"attriName"}
              updatePricingPlanList={this.updatePricingPlanList}
              getPizzaImage={this.getPizzaImage}
              selectedImages={this.state.images}
              downloadableUrls={this.state.downloadableUrls}
            />
          </>
        ) : (
          false
        )}
        {loading && <Loader />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  blintzboxList: state.blintzboxList,
  locations: state.locationList || {},
  plans: state.pricingList || {},
  amenities: state.amenityList || {},
  signedUrl: state.signedUrl,
  blintzboxDetail: state.blintzboxDetails,
  paginationPage: state.paginationPage,
  partners: state.partnerList || {},
  sponsors: state.sponsorList,
  attriName: state.attriName.attriName,
  locationDetail: state.locationDetail,
  loading: state.loaderStatus.loading,
  menuImagesReducer: state.menuImagesReducer,
});

export default connect(mapStateToProps, actions)(MenuboxHoc);
