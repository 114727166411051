import React, { Component } from "react";
import LOGO from "../../assets/images/logo.png";
import SERVER_ERROR from "../../assets/images/server-error.png";
import { Link } from "react-router-dom";
import * as constants from "../../constants";
import { BLINTZ_LOGO } from "../../constants";

class NoPageFound extends Component {
  render() {
    return (
      <>
        <header>
          <div className="container-fluid">
            <Link to={constants.DASHBOARD_ROUTE} className="navbar-brand">
              <img
                alt="Logo"
                className="img-responsive"
                src={BLINTZ_LOGO}
                width="150"
              />
            </Link>
          </div>
        </header>
        <div className="main-container server-error">
          <div className="page-not-found-container">
            <h3>OOPS! Page not found</h3>
            <div className="server-error">
              <img src={SERVER_ERROR} alt="server-error" />
            </div>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <Link to={constants.DASHBOARD_ROUTE} className="btn primary-btn">
              <button type="button" className="btn primary-btn">
                Back
              </button>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default NoPageFound;
