import { beginLoaderCall, endLoaderCall } from ".";
import {
  getUserInfo,
  getUsers,
  updateUserInfo,
  assignCreditInfo,
  assignPromoInfo,
} from "../services";
import {
  USER_DETAIL,
  USER_LIST,
  USER_DETAIL_BY_ID,
  USER_UPDATED,
  USER_ASSIGN_CREDIT,
  USER_ASSIGN_PROMOCODE,
  USER_DATA_CSV,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";
import firebase from "../config/FirebaseConfig";

/**
 * Action for get user details
 */
export const getUserDetails = (id, status) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    getUserInfo(id)
      .then((doc) => {
        if (doc.exists) {
          console.log("RESPONSE USER INFO ID", id);
          console.log("RESPONSE USER INFO", doc.data());
          const { currentUser } = firebase.auth();
          dispatch({
            type: status ? USER_DETAIL_BY_ID : USER_DETAIL,
            payload: { ...currentUser, ...doc.data() },
          });
          resolve(true);
        } else {
          reject(false);
        }
        dispatch(endLoaderCall());
      })
      .catch(() => {
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

export const getUserList = (
  searchKey = "createdAt",
  searchValue = "DESC",
  search = null,
  page = 0,
  offset = 10,
  isCsv = null,
  businessPartnerId = null,
  status = true,
  startDate = "",
  endDate = ""
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return getUsers(
    searchKey,
    searchValue,
    search,
    page,
    offset,
    businessPartnerId,
    startDate,
    endDate
  )
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: isCsv ? USER_DATA_CSV : USER_LIST,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

export const updateUserDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updateUserInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: USER_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.USER_UPDATE);
        resolve(true);
      })
      .catch(() => {
        errorToast(2, MESSAGE.USER_UPDATE_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

export const assignCredit = (payload) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    assignCreditInfo(payload)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: USER_ASSIGN_CREDIT,
          payload: response,
        });
        successToast(1, MESSAGE.CREDIT_UPDATE);
        resolve(true);
      })
      .catch(() => {
        errorToast(2, MESSAGE.CREDIT_UPDATE_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

export const assignPromoCode = (payload) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    assignPromoInfo(payload)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: USER_ASSIGN_PROMOCODE,
          payload: response,
        });
        successToast(1, MESSAGE.PROMO_UPDATE);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(2, response.response.data.message);
        } else {
          errorToast(2, MESSAGE.PROMO_UPDATE_ERROR);
        }
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};
