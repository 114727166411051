import { beginLoaderCall, endLoaderCall } from ".";
import {
  fetchPartnerList,
  getPartnerInfo,
  addPartner,
  updatePartnerInfo,
  deletePartnerInfo,
} from "../services";
import {
  PARTNERS_LIST,
  PARTNERS_DETAILS,
  PARTNERS_CREATED,
  PARTNERS_UPDATED,
  PARTNERS_DELETED,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

const handleError = (message, dispatch, reject) => {
  errorToast(1, message);
  reject(false);
};

export const getPartnerList = (
  search,
  page,
  offset,
  sortKey,
  sortType,
  status = true
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return fetchPartnerList(search, page, offset, sortKey, sortType)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: PARTNERS_LIST,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for get Partner details
 */
export const getPartnerDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getPartnerInfo(id)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: PARTNERS_DETAILS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for create Partner
 */
export const createPartner = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addPartner(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PARTNERS_CREATED,
          payload: response,
        });
        successToast(1, MESSAGE.PARTNER_CREATED);
        resolve(true);
      })
      .catch((error) => {
        dispatch(endLoaderCall());
        handleError(error.response.data.message, dispatch, reject);
      });
  });
};

/**
 * Action for update Partner details
 */
export const updatePartner = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updatePartnerInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PARTNERS_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.PARTNER_UPDATED);
        resolve(true);
      })
      .catch((error) => {
        dispatch(endLoaderCall());
        handleError(error.response.data.message, dispatch, reject);
      });
  });
};

/**
 * Action for delete Partner details
 */
export const deletePartner = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    deletePartnerInfo(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: PARTNERS_DELETED,
          payload: id,
        });
        successToast(1, MESSAGE.PARTNER_DELETED);
        resolve(true);
      })
      .catch((error) => {
        dispatch(endLoaderCall());
        handleError(error.response.data.message, dispatch, reject);
      });
  });
};
