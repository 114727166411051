import React, { Component } from "react";
import { connect } from "react-redux";

import { USER_HEADERS, USER_ROUTE, getPageNumber } from "../constants";
import * as actions from "../actions";
import UserDetail from "../components/blintzbox/UserDetail";
import Header from "../components/rightPanel/Header";
import isEmpty from "lodash/isEmpty";
import Loader from "../components/loader/Loader";
import moment from "moment";

const UserHoc = (WrapperComponent) => {
  class UserParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        addPriceHocStatus: false,
        changeStatusModal: false,
        editPricingStatus: false,
        deleteLocStatus: false,
        userData: null,
        searchedValue: "",
        userDetailId: "",
        csvData: null,
        sortKey: "createdAt",
        sortType: "DESC",
        businessPartnerId: null,
        dateType: { startDate: "", endDate: "" },
      };
      this.initialState = this.state;
      this.props.setPaginationPage(0);
    }

    componentDidMount = async () => {
      // const { history, getPartnerApiList } = this.props;
      // if (history.location.state && history.location.state.detail) {
      //   this.setState({
      //     userDetailId: history.location.state.detail,
      //     userData: history.location.state.data,
      //   });
      // } else {
      //   const { getUserList } = this.props;
      //   const { sortKey, sortType } = this.state;
      //   if (this.props.location.state) {
      //     await this.setState({
      //       searchedValue: this.props.location.state.search,
      //     });
      //   }
      //   getUserList(sortKey, sortType, this.state.searchedValue);
      //   await getUserList(
      //     sortKey,
      //     sortType,
      //     this.state.searchedValue,
      //     0,
      //     100000,
      //     "csv"
      //   );
      //   this.userNewData();
      // }
      // getPartnerApiList("", 0, 10000000000, "", "", false);
      await this.props.getTotalCustomerCount();
    };

    userNewData = () => {
      const { usersCsv } = this.props;
      const userArray = [];
      usersCsv.list &&
        usersCsv.list.length !== 0 &&
        usersCsv.list.forEach((ele) => {
          userArray.push({
            business_partner: ele.business_partner
              ? ele.business_partner.name
              : "N/A",
            date:
              (ele.createdAt &&
                moment(ele.createdAt).format("MM/DD/YYYY HH:mm")) ||
              "N/A",
            firstname: ele.firstName || "N/A",
            lastname: ele.lastName || "N/A",
            email: ele.email,
            phone: ele.phone
              ? ((ele.countryCode || "") + " " + (ele.phone || "")).replace(
                  /[()-]/gi,
                  ""
                )
              : "N/A",
            address: isEmpty(ele.userAddress) ? "N/A" : ele.userAddress.address,
            state: isEmpty(ele.userAddress) ? "N/A" : ele.userAddress.state,
            city: isEmpty(ele.userAddress) ? "N/A" : ele.userAddress.city,
            country: isEmpty(ele.userAddress) ? "N/A" : ele.userAddress.country,
            zipcode: isEmpty(ele.userAddress) ? "N/A" : ele.userAddress.zipcode,
            wallet: ele.wallet,
            count: isEmpty(ele.count) ? "0" : ele.count,
            total: ele.total || "0",
            status: ele.isActive ? "Active" : "Inactive",
          });
        });
      this.setState({ csvData: userArray });
    };
    changeStatus = async (status, data) => {
      let { searchedValue, changeStatusModal, sortKey, sortType } = this.state,
        { users, paginationPage, updateUserDetails, getUserList } = this.props;
      if (status) {
        await updateUserDetails(data.id, { isActive: !data.isActive });
        await this.props.getUserList(
          sortKey,
          sortType,
          searchedValue,
          getPageNumber(users.totalCount, paginationPage),
          10
        );
        await getUserList(sortKey, sortType, searchedValue, "", 100000, "csv");
        this.userNewData();
        this.emptyState();
      } else await this.setState({ userData: data });
      await this.setState({ changeStatusModal: !changeStatusModal });
    };

    getUserDetails = async (page) => {
      const { sortKey, sortType } = this.state;
      await this.props.setPaginationPage(page - 1);
      this.props.getUserList(
        sortKey,
        sortType,
        this.state.searchedValue,
        page - 1,
        10
      );
    };

    // search User list
    searchUserList = async (value) => {
      const { sortKey, sortType, businessPartnerId, dateType } = this.state;
      this.setState({ searchedValue: value });
      setTimeout(
        () =>
          this.props.getUserList(
            sortKey,
            sortType,
            value,
            0,
            10,
            null,
            businessPartnerId,
            false,
            dateType.startDate,
            dateType.endDate
          ),
        500
      );
      await this.props.getUserList(
        sortKey,
        sortType,
        value,
        0,
        100000,
        "csv",
        businessPartnerId,
        false,
        dateType.startDate,
        dateType.endDate
      );
      this.userNewData();
    };

    handleDateChange = async (
      date,
      dateTypeVal,
      val2 = null,
      val1 = null,
      status = false
    ) => {
      if (status) {
        const {
          sortKey,
          sortType,
          businessPartnerId,
          dateType,
          searchedValue,
        } = this.state;
        this.props.getUserList(
          sortKey,
          sortType,
          searchedValue,
          0,
          10,
          null,
          businessPartnerId,
          null,
          dateType.startDate,
          dateType.endDate
        );
        await this.props.getUserList(
          sortKey,
          sortType,
          searchedValue,
          0,
          100000,
          "csv",
          businessPartnerId,
          false,
          dateType.startDate,
          dateType.endDate
        );
        this.userNewData();
      } else {
        await this.setState((prevState) => ({
          dateType: { ...prevState.dateType, [dateTypeVal]: date },
        }));
      }
    };

    getFilteredList = async (e) => {
      const { sortKey, sortType, searchedValue, dateType } = this.state;
      this.setState({ businessPartnerId: e.value });
      this.props.getUserList(
        sortKey,
        sortType,
        searchedValue,
        0,
        10,
        null,
        e.value,
        true,
        dateType.startDate,
        dateType.endDate
      );
      await this.props.getUserList(
        sortKey,
        sortType,
        this.state.searchedValue,
        0,
        10000,
        "csv",
        e.value,
        false,
        dateType.startDate,
        dateType.endDate
      );
      this.userNewData();
    };

    sortUserList = async (header, type) => {
      let headerKey;
      switch (header) {
        case "Name":
          headerKey = "sortByName";
          break;
        case "Email":
          headerKey = "sortByEmail";
          break;
        default:
          headerKey = "sortByStatus";
      }
      this.setState({ sortKey: headerKey, sortType: type });
      this.props.getUserList(headerKey, type, this.state.searchedValue, 0, 10);
      await this.props.getUserList(
        headerKey,
        type,
        this.state.searchedValue,
        0,
        10000,
        "csv"
      );
      this.userNewData();
    };

    emptyState = async () => {
      await this.setState({
        addPriceHocStatus: false,
        changeStatusModal: false,
        editPricingStatus: false,
        deleteLocStatus: false,
        userData: null,
        userDetailId: "",
        csvData: null,
        sortKey: "createdAt",
        sortType: "ASC",
        businessPartnerId: null,
        dateType: { startDate: "", endDate: "" },
      });
    };

    showDetails = (data) => {
      this.setState({ userDetailId: data.id, userData: data });
    };

    render() {
      const { users, loading, partnersAPi, totalCustomers } = this.props;
      const {
        userDetailId,
        changeStatusModal,
        userData,
        searchedValue,
        csvData,
        dateType,
      } = this.state;
      console.log("totalCustomers", totalCustomers);
      return (
        <>
          {!userDetailId && (
            <WrapperComponent
              isApply={true}
              dateType={dateType}
              heading="USER MANAGEMENT"
              showDate={true}
              headerList={USER_HEADERS}
              displayPage={this.addRemoveEditPricingPage} // open add location modal and edit modal in Pricing Plan list
              searchListDetails={this.searchUserList} // search Pricing Plan list
              getPaginationDetails={this.getUserDetails}
              isStatusFilter={true}
              handleDateChange={this.handleDateChange.bind(this)}
              isCsvExport={true}
              data={{
                totalPages: 0,
                totalCount: totalCustomers.totalUser,
                list: totalCustomers.users,
                currentPage: 0,
              }}
              showDetails={this.showDetails.bind(this)}
              csvData={csvData || []}
              openDeleteModal={this.changeStatus}
              addRemoveDeleteModal={changeStatusModal}
              deleteDataDetails={userData}
              numberType="text"
              searchedValue={searchedValue}
              sortList={this.sortUserList}
              isPartnerApiFilter={true}
              partnersAPi={partnersAPi}
              getFilteredList={this.getFilteredList}
            />
          )}
          {userDetailId && (
            <>
              <Header
                heading="USER MANAGEMENT"
                routeLink={USER_ROUTE}
                subHeading={userData.firstName || "User"}
                searchedValue={searchedValue}
              />
              <UserDetail
                partnersAPi={partnersAPi}
                userDetailId={userDetailId}
                emptyState={this.emptyState}
              />
            </>
          )}
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    users: state.userList || {},
    paginationPage: state.paginationPage.paginationPage,
    usersCsv: state.userCSVList || {},
    loading: state.loaderStatus.loading,
    partnersAPi: state.partnerApiList || {},
    totalCustomers: state.totalCustomers || [],
  });
  return connect(mapStateToProps, actions)(UserParent);
};

export default UserHoc;
