import React, { useState } from 'react';
import Footer from '../rightPanel/Footer';
import DeleteModal from './DeleteModal';

const SponsorDetail = props => {
  const { details } = props;
  const colorDetail = details.theme && JSON.parse(details.theme);
  const [deleteActive, setDeleteActive] = useState(false);
  const deleteModal = () => {
    setDeleteActive(true);
  };
  const openDeleteModal = async (status, data) => {
    if (status) {
      await props.deleteSponsorDetails(data.id);
      props.emptyState()
      props.deleteDetail();
    }
    setDeleteActive(false);
  };

  const getValue = value => {
    return colorDetail && colorDetail.Color && colorDetail.Color[value]
      ? colorDetail.Color[value]
      : 'N/A';
  };

  return (
    <div className='admin-right-detail-wrapper right-detail-with-footer'>
      <div className='admin-right-detail-box admin-sponsor-detail-box'>
        <div className='media-show-box'>
          <div className='media'>
            <img src={details.logoUrl} alt='sponsor-image' />
          </div>
        </div>
        <div className='admin-detail-small-boxex-row'>
          <div className='admin-detail-small-box'>
            <h3>Brand Primary</h3>
            <p>{getValue('BrandPrimary')}</p>
          </div>
          <div className='admin-detail-small-box'>
            <h3>Brand Secondary</h3>
            <p>{getValue('BrandSecondary')}</p>
          </div>
          <div className='admin-detail-small-box'>
            <h3>Neutral</h3>
            <p>{getValue('Neutral')}</p>
          </div>
        </div>
        <div className='admin-detail-small-boxex-row'>
          <div className='admin-detail-small-box'>
            <h3>Neutral Light</h3>
            <p>{getValue('NeutralLight')}</p>
          </div>
          <div className='admin-detail-small-box'>
            <h3>Neutral Dark</h3>
            <p>{getValue('NeutralDark')}</p>
          </div>
          <div className='admin-detail-small-box'>
            <h3>Neutral Extra Dark</h3>
            <p>{getValue('NeutralExtraDark')}</p>
          </div>
        </div>
        <div className='admin-detail-small-boxex-row small-boxes-row-gap'>
          <div className='admin-detail-small-box'>
            <h3>White</h3>
            <p>{getValue('White')}</p>
          </div>
          <div className='admin-detail-small-box'>
            <h3>Success</h3>
            <p>{getValue('Success')}</p>
          </div>
          <div className='admin-detail-small-box'>
            <h3>Error</h3>
            <p>{getValue('Error')}</p>
          </div>
        </div>
      </div>
      <Footer
        detailPageStatus={true}
        hideConfig={true}
        edit={props.addSponsorboxPage}
        details={details}
        deleteData={props.deleteSponsorPage}
        deleteModal={deleteModal}
      />
      {deleteActive && (
        <DeleteModal
          isInside={true}
          addRemoveDeleteModal={deleteActive}
          openDeleteModal={openDeleteModal}
          heading='Sponsor'
          deleteDataDetails={details}
        />
      )}
    </div>
  );
};

export default SponsorDetail;
