import React from "react";
import { getPropertyName } from "../utils";
import ArrowDown from "../assets/images/svg/arrow-down.svg";
import { Dropdown } from "react-bootstrap";
import { BOOKING_STATUS } from "../../src/constants";
import moment from "moment-timezone";

export const statusDropdown = (
  header,
  changedValue,
  openDeleteModal,
  data,
  openStatusModal,
  changeStatus
) => {
  return (
    <td key={header.id}>
      <div className="dropdown custom-dropdown custom-small-dropdown">
        <Dropdown>
          <Dropdown.Toggle
            className={changedValue ? "active-toggle" : "inactive-toggle"}
          >
            {changedValue ? "Active" : "Inactive"}
            <img src={ArrowDown} alt="arrow-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {openStatusModal ? (
              <Dropdown.Item href="#" onClick={() => changeStatus(false, data)}>
                <i className="dropdown-item">
                  {!changedValue ? "Active" : "Inactive"}
                </i>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                href="#"
                onClick={() => openDeleteModal(false, data)}
              >
                <i className="dropdown-item">
                  {!changedValue ? "Active" : "Inactive"}
                </i>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </td>
  );
};

export const extension = (changedValue, header) => {
  return header.extension === "$"
    ? "$" + changedValue
    : changedValue + header.extension;
};

export const themeView = (header, themeData) => {
  return (
    <td key={header.id}>
      <span>{(themeData && themeData.Color[header.name]) || "N/A"}</span>
    </td>
  );
};

const lineValue = (header, changedValue, BOOKING_STATUS) => {
  try {
    if (header.key === "price") {
      // console.log("changedValue header", header);
      console.log("changedValue", changedValue);
      // const keys = Object.keys(changedValue);
      let value = "";
      changedValue.forEach((element) => {
        value +=
          element.size.charAt(0).toUpperCase() + ": " + element.price + " ";
      });
      return value;
    } else if (header.key === "size") {
      // console.log("changedValue header", header);
      console.log("changedValue", changedValue);
      // const keys = Object.keys(changedValue);
      let value = "";
      changedValue.forEach((element) => {
        value += element.size.toUpperCase() + ": ";
      });
      return value;
    } else {
      return header.key === "blintzbox"
        ? (changedValue && changedValue.name) || ""
        : header.key === "status"
        ? BOOKING_STATUS[changedValue]
        : changedValue || "N/A";
    }
  } catch (e) {
    console.log("e", e);
  }
};

const getDate = (value) => {
  if (value) {
    let date = moment(value),
      dayN = date.format("D"),
      day = date.year(value, '"YYYY-MM-DD HH:mm:ss"').format("dddd"),
      month = moment(value)
        .subtract(0, "month")
        .startOf("month")
        .format("MMMM"),
      year = date.format("YYYY"),
      time = date.format("HH:mm A");
    return `${day.substring(0, 3)}, ${month.substring(
      0,
      3
    )} ${dayN} ,${year} ,${time}`;
  } else return "N/A";
};

const handleHeaderKeys = (
  header,
  data,
  changedValue,
  showDetails,
  openDeleteModal,
  bookingDetailsHeader,
  openStatusModal,
  changeStatus
) => {
  if (header.bold)
    return (
      <th
        key={header.id}
        onClick={() => showDetails(data, bookingDetailsHeader)}
      >
        <strong>{changedValue || "N/A"}</strong>
      </th>
    );
  else if (header.key === "theme") {
    const themeData = data.theme && JSON.parse(changedValue);
    return themeView(header, themeData);
  } else if (header.key === "active" || header.key === "status") {
    return statusDropdown(
      header,
      changedValue,
      openDeleteModal,
      data,
      openStatusModal,
      changeStatus
    );
  } else if (header.key === "userAddress" && header.label === "Address") {
    return (
      <td key={header.id}>{(changedValue && changedValue.address) || "N/A"}</td>
    );
  } else if (header.key === "userAddress") {
    return (
      <td key={header.id}>{(changedValue && changedValue.country) || "N/A"}</td>
    );
  } else if (header.isDate) {
    return <td key={header.id}>{getDate(changedValue) || "N/A"}</td>;
  } else if (header.key === "blintzbox" && header.label === "Location") {
    return (
      <td key={header.id}>
        {(changedValue &&
          changedValue.location &&
          changedValue.location.name) ||
          "N/A"}
      </td>
    );
  } else if (header.key === "business_partner") {
    return (
      <td key={header.id}>{(changedValue && changedValue.name) || "N/A"}</td>
    );
  } else if (header.key === "amount") {
    return (
      <td key={header.id}>
        {(changedValue && "$" + changedValue.toFixed(2)) || "0"}
      </td>
    );
  } else if (header.key === "address" && header.id === 2) {
    return (
      <td key={header.id} className="address-line">
        {changedValue || "N/A"}
      </td>
    );
  } else if (header.key === "phone") {
    return (
      <td key={header.id}>
        {data.phone
          ? ((data.countryCode || "") + " " + (data.phone || "")).replace(
              /[()-]/gi,
              ""
            )
          : "N/A"}
      </td>
    );
  } else {
    return (
      <td key={header.id}>{lineValue(header, changedValue, BOOKING_STATUS)}</td>
    );
  }
};

export const handleListValue = (
  headers,
  data,
  showDetails,
  openDeleteModal,
  bookingDetailsHeader,
  isAmneties,
  openStatusModal,
  changeStatus
) => {
  return headers.map((header) => {
    let value = data[header.key];
    if (header.key === "name" && !value) {
      value = data["pizzaName"];
    }
    if (header.isImage) {
      //handle image ui when a column contains an image
      return (
        <td key={header.id}>
          <img
            src={value}
            className={
              isAmneties ? "table-amenity-main-logo" : "table-amenity-logo"
            }
            alt={header.key}
          />
        </td>
      );
    } else {
      let changedValue = header.hasId
        ? getPropertyName(value, header.label)
        : value;
      //handle specific extension added into the value
      if (header.extension) {
        changedValue = extension(changedValue, header);
      }
      return handleHeaderKeys(
        header,
        data,
        changedValue,
        showDetails,
        openDeleteModal,
        bookingDetailsHeader,
        openStatusModal,
        changeStatus
      );
    }
  });
};
