import { GET_CREDIT , GET_PROMO_CODE}  from '../actions';

export const seasonalcredit = (state = {}, action) => {
  const { type, payload } = action;
  if (type === GET_CREDIT) {
    return {
        ...state,
        creditdata : payload || []
      }
  } else {
    return state;
  }
};

export const seasonalpromo = (state = {}, action) => {
  const { type, payload } = action;
  if (type === GET_PROMO_CODE) {
    return {
        ...state,
        promodetail : payload || []
      }
  } else {
    return state;
  }
};