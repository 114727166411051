import { combineReducers } from "redux";
import {
  blintzboxList,
  blintzboxDetails,
  paginationPage,
  attriName,
  signedUrl,
} from "./blintzbox.reducer";
import { seasonalcredit, seasonalpromo } from "./seasonalpromocode.reducer";
import { pricingList, pricingDetails } from "./pricingPlan.reducer";
import { amenityList, amenityDetails } from "./amenities.reducer";
import { partnerList, partnerDetails } from "./partners.reducer";
import { sponsorList, sponsorDetails } from "./sponsor.reducer";
import { menuImagesReducer } from "./menu.reducer";
import {
  locationList,
  locationDetail,
  locCountries,
  locCities,
  locStates,
  locationCsv,
} from "./location.reducer";
import { user, userList, userById, userCSVList } from "./user.reducer";
import {
  bookingList,
  bookingDetails,
  bookingPriceDetails,
  refundDetails,
  bookingImages,
} from "./booking.reducer";
import {
  panelReportList,
  totalOrderCount,
  totalCustomers,
  blintzbox_statics,
  revenue_statics,
} from "./dashboard.reducer";
import { LOGOUT } from "../actions";
import { loaderStatus } from "./loader.reducer";
import { partnerApiList } from "./partnersApi.reducer";

const appReducers = combineReducers({
  user,
  userList,
  userById,
  amenityList,
  sponsorList,
  pricingList,
  amenityDetails,
  partnerList,
  pricingDetails,
  partnerDetails,
  sponsorDetails,
  locationList,
  blintzboxList,
  blintzboxDetails,
  locationDetail,
  paginationPage,
  attriName,
  bookingList,
  bookingDetails,
  bookingPriceDetails,
  panelReportList,
  userCSVList,
  loaderStatus,
  signedUrl,
  partnerApiList,
  locCountries,
  blintzbox_statics,
  revenue_statics,
  locCities,
  locStates,
  refundDetails,
  locationCsv,
  bookingImages,
  seasonalcredit,
  seasonalpromo,
  menuImagesReducer,
  totalOrderCount,
  totalCustomers,
});

const rootReducer = (state, action) => {
  const { type } = action;
  if (type === LOGOUT) {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
