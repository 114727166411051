import React from "react";
import deleteIcon from "../../assets/images/svg/delete-red.svg";
import editIcon from "../../assets/images/svg/edit.svg";

const Footer = ({
  addUpdateData,
  footerStatus,
  enableButton,
  editStatus,
  handleCancel,
  detailPageStatus,
  hideConfig,
  edit,
  details,
  deleteModal,
  openSaveConfigModal,
}) => {
  return footerStatus ? (
    <div className="admin-bottom-wrapper">
      <div className="admin-bottom-inner">
        <button
          className="btn primary-btn primary-main"
          onClick={() => openSaveConfigModal(null, null, true)}
        >
          TODO
        </button>
        <div className="admin-bottom-right">
          <button
            className="btn secondary-btn primary-main"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
          <button
            disabled={enableButton}
            className="btn primary-btn primary-main btn-right-gap"
            onClick={() => addUpdateData()}
          >
            {editStatus ? "Update " : "Add "}Menu
          </button>
        </div>
      </div>
    </div>
  ) : detailPageStatus ? (
    <div className="admin-bottom-wrapper">
      <div className="admin-bottom-inner">
        <button
          type="button"
          className="btn btn-primary white-small-btn white-delete-btn"
          onClick={() => deleteModal(false, details)}
        >
          <img src={deleteIcon} alt="delete-red" /> Delete
        </button>
        <div className="admin-bottom-right">
          {!hideConfig && (
            <button
              type="button"
              onClick={() => openSaveConfigModal(null, null, true)}
              className="btn btn-primary white-small-btn white-big-btn"
            >
              View Config Details
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary white-small-btn edit-btn"
            onClick={() => edit("Edit", details)}
          >
            <img src={editIcon} alt="edit" /> Edit
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="admin-bottom-wrapper">
      <div className="admin-bottom-inner admin-bottom-align-right">
        <button
          className="btn secondary-btn primary-main"
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
        <button
          className="btn primary-btn primary-main btn-right-gap"
          disabled={enableButton}
          onClick={() => addUpdateData()}
        >
          {editStatus ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default Footer;
