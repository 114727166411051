import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import * as constants from '../../constants';
import * as actions from '../../actions';
import { connect } from 'react-redux';

class RefundModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonValue: '',
      bookingData: null,
      refundData: null,
      updatebookingData: null
    };
    this.initialState = this.state
  }

  static getDerivedStateFromProps(props, state) {
    let refundValue = props.refundDetails && (props.refundDetails.refundtotal || 0)
    if (props.bookingData && Object.keys(props.refundDetails).length !== 0 && ((state.updatebookingData &&
      (props.bookingData.id !== state.updatebookingData.id)) ||
      state.updatebookingData === null)) {
      let tax = props.refundDetails && (props.refundDetails.tax || 0)
      let totalAmount = props.refundDetails && (parseFloat(props.refundDetails.subtotal) + parseFloat(props.refundDetails.wallet))
      let finalValue = (parseFloat(totalAmount) - (parseFloat(refundValue) + parseFloat(tax)))
      return {
        bookingData: `$${finalValue}`,
        refundData: finalValue,
        updatebookingData: props.bookingData
      };
    } else return null;
  }

  handleChange = e => {
    this.setState({
      buttonValue: e.target.value
    })
  }
  handleAmount = (e) => {
    const { refundData } = this.state;
    const value = e.target.value.replace('$', '');
    if (constants.maxLimitRegex.test(value) && value <= (refundData)) {
      this.setState({
        bookingData: (value ? '$' : '') + value
      })
    }
  };

  handleRefund = async () => {
    this.props.openRefundModal(
      true,
      this.props.bookingData,
      this.state.buttonValue,
      this.state.bookingData
    )
    await this.setState(this.initialState)
    this.props.emptyState()
  }

  emptyCurrentState = async () => {
    await this.setState(this.initialState)
  }

  disableData = () => {
    const { refundData } = this.state;
    let disable = true;
    disable = this.state.buttonValue === '' || !this.state.bookingData ||
      (refundData <= parseFloat(0).toFixed(2))
    return disable;
  }
  handleClose = () => {
    this.emptyCurrentState()
    this.props.emptyState()
  }

  render() {
    const { refundModalStatus, refundDetails } = this.props;
    return (
      <Modal
        show={refundModalStatus}
        className='form-modal refund-modal'
        onHide={() => this.handleClose()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className='modal-title'>Refund Amount</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='radio-button-row'>
            {refundDetails && parseFloat(refundDetails.subtotal) !== 0 && <div className='custom-radio custom-radio-gap'>
              <label>
                <input
                  type='radio'
                  onChange={e => this.handleChange(e)}
                  value='card'
                  checked={this.state.buttonValue === 'card' ? true : false}
                />
                <span>Card</span>
              </label>
            </div>}
            <div className='custom-radio'>
              <label>
                <input
                  type='radio'
                  onChange={e => this.handleChange(e)}
                  value='wallet'
                  checked={this.state.buttonValue === 'wallet' ? true : false}
                />
                <span>Wallet</span>
              </label>
            </div>
          </div>
          <div className='form-group'>
            <div className='floating-input edit-floating-input'>
              <label>Amount</label>
              <input
                type='text'
                className='form-control'
                name='Amount'
                value={this.state.bookingData}
                onChange={this.handleAmount}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn secondary-btn primary-main'
            onClick={() => this.handleClose()}
            data-dismiss='modal'
          >
            Cancel
          </button>
          <button
            className='btn primary-btn primary-main btn-right-gap'
            disabled={
              this.disableData()
                ? true
                : false
            }
            onClick={() => this.handleRefund()}
          >
            Refund
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  refundDetails: state.refundDetails || {}
});

export default connect(mapStateToProps, actions)(RefundModal);
