import { applyMiddleware, createStore, compose } from "redux";
import { DEVELOPMENT } from "../constants";
import reducers from "../reducers";
import thunk from "redux-thunk";

const composeByEnv = (initialState) => {
  const middlewares = [thunk];

  const middleware = applyMiddleware(...middlewares);
  let enhancer = compose(middleware);
  if (
    process.env.NODE_ENV === DEVELOPMENT &&
    window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined
  ) {
    //Redux DevTools not installed
    enhancer = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
  }
  return createStore(reducers, initialState, enhancer);
};

const store = composeByEnv({});

export default store;
