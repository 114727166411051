import axios from 'axios';

import { PARTNER_URL } from '../constants';

/**
 * Function to get partner list
 */
export const fetchPartnerList = (search, page, offset, sortKey, sorttype) =>
 axios.get(`${PARTNER_URL}?search=${search || ''}&page=${page || 0}&limit=${offset || 10}${sortKey ? `&${sortKey}=${sorttype}` : ''}`);//?search=${search}&page=${page}&offset=${offset}

/**
 * Function to get partner details
 * @param  {Object} payload
 */
export const getPartnerInfo = id => axios.get(PARTNER_URL + '/' + id);

/**
 * Function to add partner
 * @param  {Object} payload
 */
export const addPartner = payload => axios.post(PARTNER_URL, payload);

/**
 * Function to update partner details
 * @param  {Object} payload
 */
export const updatePartnerInfo = (id, payload) =>
  axios.put(PARTNER_URL + '/' + id, payload);

/**
 * Function to delete partner details
 * @param  {Object} payload
 */
export const deletePartnerInfo = id => axios.delete(PARTNER_URL + '/' + id);
