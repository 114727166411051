import React, { useState } from 'react';

export const TextBox = props => {
  const {
    type,
    name,
    value,
    onChange,
    activeClass,
    readOnly,
    label,
    error,
    autoFocus,
    isDisabled
  } = props;
  const [focus, setFocus] = useState({});
  /**
   * This function will remove the focus on blur event
   */
  const onBlur = event => {
    let { name, onBlur } = props;
    setFocus({ [name]: false });
    onBlur && onBlur(event);
  };

  /**
   * This function will handle the focus on focus event
   */
  const onFocus = event => {
    const { name } = event.target;
    setFocus({ [name]: true });
  };

  /**
   * This function will handle enter keyboard event
   */
  const onEnterButton = event => {
    const { value } = props;
    if (event.which === 32 && !value.length) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      const element = document.getElementById('submitCall');
      element && element.click();
    }
  };

  return (
    <div
      className={`floating-input move ${activeClass} ${(focus[name] ||
        error ||
        value) &&
        'active label-heightlight'}  ${isDisabled && 'disable-input'}`}
    >
      {label && <label>{label}</label>}
      {type === 'textarea' ? (
        <textarea
          {...props}
          type='text'
          onChange={onChange}
          autoFocus={autoFocus}
          onKeyPress={event => onEnterButton(event)}
          readOnly={readOnly || false}
          onBlur={event => onBlur(event)}
          onFocus={event => onFocus(event)}
        />
      ) : (
        <input
          {...props}
          type={type || 'text'}
          onChange={onChange}
          autoFocus={autoFocus}
          onKeyPress={event => onEnterButton(event)}
          onBlur={event => onBlur(event)}
          onFocus={event => onFocus(event)}
        />
      )}
    </div>
  );
};
