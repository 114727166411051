import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import * as constants from "../../constants";

class SendImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      clickValue: false
    };
  }

  handleChange = e => {
    if (constants.emailRejex.test(e.target.value)) {
      this.setState({
        email: e.target.value
      });
    }
  };
  changeValue = () => {
      this.setState({
          clickValue: true
      })
  }
  render() {
    const { imageModalStatus, openImageModal } = this.props;
    return (
      <Modal
        show={imageModalStatus}
        className="form-modal send-image-modal"
        onHide={() => openImageModal()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="modal-title">Send Images</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='form-group'>
          <div className={`floating-input ${this.state.clickValue ? "move active label-heightlight" : ''}`}>
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              onChange={e => this.handleChange(e)}
              onClick={this.changeValue}
            />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn secondary-btn primary-main"
            onClick={() => openImageModal()}
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            className="btn primary-btn primary-main btn-right-gap"
            disabled={!this.state.email ? true : false}
            onClick={() =>
              openImageModal(
                true,
                this.state.email
              )
            }
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SendImageModal;
