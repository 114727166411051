import * as actions from "../actions";

export const DEVELOPMENT = "development";

export const AUTH_TOKEN = "auth_token";
export const USER_ID = "userId";
export const NUMBER = 2;

export const getClassName = (status, editStatus, dropdown) => {
  if (editStatus) return "edit-floating-input";
  else if (dropdown) return status ? "edit-floating-input" : "";
  else return status ? "move active label-heightlight" : "";
};

export const getPageNumber = (totalCount, page) => {
  return totalCount > 10 && totalCount % 10 === 1 ? Math.round(21 / 10) : page;
};

export const isUrl = (url) => {
  const regexp = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return regexp.test(url);
};

export const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const getActionValue = (value, bookingTabVal) => {
  return value === "upcoming"
    ? actions.BOOKING_UPCOMMING
    : bookingTabVal === "past"
    ? actions.BOOKING_PAST
    : bookingTabVal === "current"
    ? actions.BOOKING_CURRENT
    : actions.BOOKING_CANCELED;
};

export const getHtmlClass = (data, classStatus, attriName, newName) => {
  const classStatusNew = classStatus;
  if (data === null || (data && data[attriName] === undefined)) {
    classStatusNew[newName] = {
      isFilled: false,
      status: true,
    };
    classStatusNew[attriName] = {
      isFilled: false,
      status: false,
    };
  } else if (data && data[attriName] !== undefined) {
    classStatusNew[newName] = {
      isFilled: false,
      status: true,
    };
    classStatusNew[attriName] = {
      isFilled: true,
      status: true,
    };
  }
  return classStatusNew;
};

export const partnerClass = {
  promo: { isFilled: false, status: false },
  pricePlanIds: { isFilled: false, status: false },
  name: { isFilled: false, status: false },
  discount: { isFilled: false, status: false },
};

export const passwordClass = {
  oldPassword: { isFilled: false, status: false },
  newPassword: { isFilled: false, status: false },
  confirmNewPassword: { isFilled: false, status: false },
};

export const locationClass = {
  name: { isFilled: false, status: false },
  address: { isFilled: false, status: false },
  city: { isFilled: false, status: false },
  state: { isFilled: false, status: false },
  zipcode: { isFilled: false, status: false },
  country: { isFilled: false, status: false },
  timezone: { isFilled: false, status: false },
  latitude: { isFilled: false, status: false },
  longitude: { isFilled: false, status: false },
};

export const jBoxClass = {
  name: { isFilled: false, status: false },
  timezone: { isFilled: false, status: false },
  size: { isFilled: false, status: false },
  pricingPlanId: { isFilled: false, status: false },
  zipcode: { isFilled: false, status: false },
  sponsorId: { isFilled: false, status: false },
  tax: { isFilled: false, status: false },
  address: { isFilled: false, status: false },
  description: { isFilled: false, status: false },
  locationId: { isFilled: false, status: false },
  longitude: { isFilled: false, status: false },
  latitude: { isFilled: false, status: false },
  partnerId: { isFilled: false, status: false },
};

export const promoCodeClass = {
  clickName: { isFilled: true, status: true },
  clickAmount: { isFilled: false, status: false },
  clickDate: { isFilled: false, status: false },
  clickEndDate: { isFilled: false, status: false },
  clickLimit: { isFilled: false, status: false },
};

export const PARTNER_API_KEY = "JB.nKRrKxKDZSNeDPz_4bWtEnJWXxWmyWK1IC2gm4XmO";

export const MESSAGE = {
  JBOX_CREATE: "blintzbox successfully created.",
  JBOX_UPDATE: "blintzbox successfully updated.",
  JBOX_DELETE: "blintzbox successfully deleted.",
  JBOX_UPDATE_ERROR: "Unable to update blintzbox.",
  JBOX_CREATE_ERROR: "Unable to create blintzbox.",
  JBOX_DELETE_ERROR: "Unable to delete blintzbox.",
  JBOX_STATUS_UPDATE: "Status successfully updated.",
  JBOX_STATUS_UPDATE_ERROR: "Unable to update status",

  PRICE_CREATE: "Pricing plan successfully created.",
  PRICE_UPDATE: "Pricing plan successfully updated.",
  PRICE_DELETE: "Pricing plan successfully deleted.",
  PRICE_INAVLID_NAME: "Invalid duration or cost value.",
  PRICE_UPDATE_ERROR: "Unable to update pricing plan.",
  PRICE_CREATE_ERROR: "Unable to create pricing plan.",

  LOC_CREATE: "Location successfully created.",
  LOC_UPDATE: "Location successfully updated.",
  LOC_DELETE: "Location successfully deleted.",
  LOC_INAVLID_NAME: "Invalid location name.",
  LOC_UPDATE_ERROR: "Unable to update location.",
  LOC_CREATE_ERROR: "Unable to create location.",

  AMENTY_CREATE: "Amenity successfully created.",
  AMENTY_UPDATE: "Amenity successfully updated.",
  AMENTY_DELETE: "Amenity successfully deleted.",
  AMENTY_UPDATE_ERROR: "Unable to update amenity.",
  AMENTY_CREATE_ERROR: "Unable to create amenity.",
  AMENITY_DELETE_ERROR: "Unable to delete amenity",

  USER_UPDATE_ERROR: "Unable to update user.",
  USER_UPDATE: "User successfully updated.",

  CREDIT_UPDATE_ERROR: "Unable to update credit.",
  CREDIT_UPDATE: "Credit successfully updated.",

  PROMO_UPDATE_ERROR: "Unable to assign promo code to user.",
  PROMO_UPDATE: "Promo Code successfully assigned.",

  BOOKING_CREATED: "Booking successfully created.",
  BOOKING_UPDATED: "Booking successfully updated.",
  BOOKING_DELETED: "Booking canceled successfully",
  BOOKING_UPDATED_ERROR: "Unable to update booking.",
  BOOKING_CREATED_ERROR: "Unable to created booking.",
  BOOKING_DELETED_ERROR: "Unable to delete booking",

  INVALID_EMAIL: "Invalid email or password",

  PARTNER_CREATED: "Partner successfully created.",
  PARTNER_UPDATED: "Partner successfully updated.",
  PARTNER_DELETED: "Partner successfully deleted.",

  PARTNER_API_CREATED: "Partner api successfully created.",
  PARTNER_API_UPDATED: "Partner api successfully updated.",
  PARTNER_API_DELETED: "Partner api successfully deleted.",
  PARTNER_API_UPDATED_ERROR: "Unable to update partner api.",
  PARTNER_API_CREATED_ERROR: "Unable to created partner api.",
  PARTNER_API_DELETED_ERROR: "Unable to delete partner api",

  SPONSOR_CREATED: "Sponsor successfully created.",
  SPONSOR_UPDATED: "Sponsor successfully updated.",
  SPONSOR_DELETED: "Sponsor successfully deleted.",
  SPONSOR_DELETED_ERROR: "Unable to delete sponsor.",
  SPONSOR_UPDATED_ERROR: "Unable to update sponsor.",
  SPONSOR_CREATED_ERROR: "Unable to created sponsor.",

  INVALID_JSON: "*Invalid JSON: Changes will not be saved.",
  VALID_JSON: "*Valid JSON: Changes can be saved.",

  DATE_ERROR: "Please fill the blank date.",

  REFUND_SUCCESS: "Refund is done successfully",
  REFUND_FAIL: "Unable to refund",

  PRICE_FAILED: "Please select size before adding price",
  MENU_ADDED: "Menu has been added successfully",
  MENU_UPDATED: "Menu has been updated successfully",

  SEND_IMAGES_SUCCESS: "Images has been send successfully",
  SEND_IMAGES_ERROR: "Unable to send images",

  EMPTY_FIELDS: "Fields cannot be empty.",
  INVALID_PASS: "New password and confirm password does not match",
  CORECT_PASS:
    "At least 8 characters long with 1 number and 1 upper case letter.",
};

export const BOOKING_STATUS = {
  1: "Pending",
  2: "Confirmed",
  3: "Cancelled",
  4: "Rescheduled",
  5: "Extended",
};

export const headersUser = [
  { label: "Partner Api Code", key: "business_partner" },
  { label: "Sign-Up Date", key: "date" },
  { label: "First Name", key: "firstname" },
  { label: "Last Name", key: "lastname" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Address", key: "address" },
  { label: "State", key: "state" },
  { label: "City", key: "city" },
  { label: "Country", key: "country" },
  { label: "Zipcode", key: "zipcode" },
  { label: "Wallet Amount Remaining", key: "wallet" },
  { label: "Number of Bookings", key: "count" },
  { label: "Total Booking Dollars (Business)($)", key: "total" },
  { label: "Status", key: "status" },
];

export const headersBooking = [
  { label: "Partner API Code", key: "partner" },
  { label: "Booking ID", key: "id" },
  { label: "Transaction ID", key: "transactionId" },
  { label: "Result", key: "status" },
  { label: "Total $", key: "amount" },
  { label: "Credit Amount Used $", key: "credit" },
  { label: "Location", key: "location" },
  { label: "blintzbox Name", key: "name" },
  { label: "Start Date/Time", key: "datetimeStart" },
  { label: "End Date/Time", key: "datetimeEnd" },
  { label: "Time Zone", key: "time_zone" },
  { label: "Duration (minutes)", key: "duration" },
  { label: "Reservation Date/Time", key: "reservation" },
  { label: "Name", key: "user" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
];

export const headerblintzboxStatics = [
  { label: "Month", key: "month" },
  { label: "No. of Bookings", key: "booking" },
  { label: "No. of Customers", key: "customer" },
];

export const headerRevenue = [
  { label: "Month", key: "month" },
  { label: "Revenue($)", key: "revenue" },
];

export const headerLocation = [
  { label: "Name", key: "name" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Country", key: "country" },
  { label: "Zip Code", key: "zipcode" },
  { label: "Address", key: "address" },
  { label: "Time Zone", key: "timezone" },
  { label: "Latitude", key: "latitude" },
  { label: "Longitude", key: "longitude" },
];

export const ConfigList = {
  lights: {
    restServiceBaseURL: "http://000.000.0.000/api",
    restServiceAPIKey: "UNjvZ972BYUh5XKj2kerluyNAkFO52FPWzPZHkKC",
    internal: {
      id: 5,
      reservationStart: {
        mode: "SINGLE_PASS",
        colors: [
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 1,
          },
        ],
        interval: 1000,
        transitionTime: 0,
      },
      reservationEnd: {
        mode: "LOOP",
        colors: [
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 0.5,
          },
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 1,
          },
        ],
        interval: 3000,
        transitionTime: 1000,
      },
    },
    photoBooth: {
      id: 10,
      reservationStart: {
        mode: "SINGLE_PASS",
        colors: [
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 0.5,
          },
        ],
        interval: 1000,
        transitionTime: 0,
      },
      reservationEnd: {
        mode: "LOOP",
        colors: [
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 0.5,
          },
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 1,
          },
        ],
        interval: 3000,
        transitionTime: 1000,
      },
      takePhotoColor: {
        r: 255,
        g: 255,
        b: 255,
        brightness: 1,
      },
    },
    external: {
      id: 1,
      reservationStart: {
        mode: "SINGLE_PASS",
        colors: [
          {
            r: 255,
            g: 0,
            b: 0,
            brightness: 1,
          },
        ],
        interval: 1000,
        transitionTime: 0,
      },
      reservationEnd: {
        mode: "SINGLE_PASS",
        colors: [
          {
            r: 0,
            g: 255,
            b: 0,
            brightness: 1,
          },
        ],
        interval: 1000,
        transitionTime: 0,
      },
    },
    photoBooth1: {
      id: 12,
      reservationStart: {
        mode: "SINGLE_PASS",
        colors: [
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 0.5,
          },
        ],
        interval: 1000,
        transitionTime: 0,
      },
      reservationEnd: {
        mode: "LOOP",
        colors: [
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 0.5,
          },
          {
            r: 255,
            g: 255,
            b: 255,
            brightness: 1,
          },
        ],
        interval: 3000,
        transitionTime: 1000,
      },
      takePhotoColor: {
        r: 255,
        g: 255,
        b: 255,
        brightness: 1,
      },
    },
  },
  payment: {
    serviceURL: "http://127",
    Transaction: {
      HostOrIP: "127.0.0.1",
      IpPort: "9000",
      MerchantID: "srfr=BOX",
      OperatorID: "JBX",
      UserTrace: "blintzbox",
      TranCode: "TTTEMVSale",
      SecureDevice: "EMV_IUC455_MERCURY",
      ComPort: 9,
      InvoiceNo: "1",
      RefNo: "1",
      SequenceNo: "0D######010010010",
      RecordNo: "RecordNumberRequested",
      Frequency: "OneTime",
    },
  },
  blintzbox: {
    doorFacesLeft: true,
  },
  googleMapUrl: "AIzaSyBdJdxHhfTIzS6ZhgTt5twRCoo1jsWWSlg",
  googleLicenseAgreement:
    "https://www.google.com/intl/en-US_US/help/terms_maps.html",
  port: "COM3",
  testing: false,
  cancelReservation: false,
  globalPollInterval: 60000,
  shortPollInterval: 3000,
  signUpPageTimeout: 120000,
  tellMeMoreTimeout: 300000,
  timeout: 30000,
  thankyouTimeout: 120000,
  currentMaintenanceScreen: "",
  APP_URL: "https://blintzbox.us",
  flightStats: {
    appId: "cffacebe",
    appKey: "f0c2d9e8fa27dd1fe56af53979b33714",
    flightStatus: {
      restServiceBaseURL:
        "https://api.flightstats.com/flex/flightstatus/rest/v2/jsonp/flight/status",
    },
    weather: {
      zoneForecast: {
        restServiceBaseURL:
          "https://api.flightstats.com/flex/weather/rest/v1/jsonp/zf",
      },
      all: {
        restServiceBaseURL:
          "https://api.flightstats.com/flex/weather/rest/v1/jsonp/all",
      },
    },
  },
};

export const TimeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];
