import React, { Component } from "react";
import moment from "moment-timezone";
import UserDetail from "../blintzbox/UserDetail";
import Header from "../../components/rightPanel/Header";
import { USER_ROUTE } from "../../constants";
import history from "../../store/history";
import * as actions from "../../actions";
import { connect } from "react-redux";
import SendImageModal from "./SendImageModal";
import DefaultImage from "../../assets/images/svg/default-user.svg";
import { BOOKING_STATUS } from "../../constants";
import ShowPaymentModal from "./ShowPaymentModal";

class BookingDetail extends Component {
  state = {
    userDetailId: "",
    imageModalStatus: false,
    openModal: false,
  };

  handleRedirect() {
    this.setState({ userDetailId: this.props.userDetail.id });
    history.push({
      pathname: USER_ROUTE,
      state: { detail: this.props.userDetail.id, data: this.props.userDetail },
    });
  }

  handleLockUnlock() {
    this.props.lockUpcomingBooking(this.props.bookingDetails.id);
  }

  openImageModal = (status, email) => {
    const { sendImagesAction } = this.props;
    const { imageModalStatus } = this.state;
    if (status) {
      const id = this.props.bookingDetails.id;
      sendImagesAction(id, email);
    }
    this.setState({ imageModalStatus: !imageModalStatus });
  };

  getDate = (value) => {
    if (value) {
      let date = moment(value),
        dayN = date.format("D"),
        day = date.year(value, '"YYYY-MM-DD HH:mm:ss"').format("dddd"),
        month = moment(value)
          .subtract(0, "month")
          .startOf("month")
          .format("MMMM"),
        year = date.format("YYYY"),
        time = date.format("HH:mm A");
      return `${day.substring(0, 3)}, ${month.substring(
        0,
        3
      )} ${dayN} ,${year} ,${time}`;
    } else return "N/A";
  };

  customerDetail = (
    blintzbox,
    id,
    transactionId,
    datetimeStart,
    amount,
    createdAt,
    accessCode,
    status,
    blintzboxDetail,
    bookingTabVal,
    bookingImages
  ) => {
    return (
      <div className="admin-right-detail-box btlinzbox-right-detail-show-box right-detail-bottom-gap">
        <div className="admin-detail-head-section admin-detail-head-with-toggle">
          <div className="admin-detail-head">
            <h3 className="admin-detail-heading">
              {blintzbox && blintzbox.name}
            </h3>
            <p className="admin-detail-desc">
              {blintzbox && blintzbox.address}
              <br />
              {blintzbox && blintzbox.zipcode}
            </p>
          </div>
          {
            <div className="admin-detail-head-right">
              <button
                className="btn primary-btn primary-main btn-right-gap"
                data-dismiss="modal"
                onClick={() => this.handleLockUnlock()}
              >
                Unlock
              </button>
            </div>
          }
        </div>
        <div className="admin-detail-small-boxex-row">
          <div className="admin-detail-small-box">
            <h3>Booking ID</h3>
            <p>#{id}</p>
          </div>
          <div className="admin-detail-small-box">
            <h3>Transaction ID</h3>
            <p>#{transactionId}</p>
          </div>
          <div className="admin-detail-small-box">
            <h3>Booking Start Date</h3>
            <p>{this.getDate(datetimeStart)}</p>
          </div>
        </div>
        <div className="admin-detail-small-boxex-row">
          <div className="admin-detail-small-box">
            <h3>blintzbox Name</h3>
            <p>{blintzbox && blintzbox.name}</p>
          </div>
          <div className="admin-detail-small-box">
            <h3>Payment Value</h3>
            <p>${parseFloat(amount).toFixed(2)}</p>
          </div>
          <div className="admin-detail-small-box">
            <h3>Date of Reservation</h3>
            <p>{this.getDate(createdAt)}</p>
          </div>
        </div>
        <div className="admin-detail-small-boxex-row">
          <div className="admin-detail-small-box">
            <h3>Size</h3>
            <p>{blintzbox && blintzbox.size} Squre Feet</p>
          </div>
          <div className="admin-detail-small-box">
            <h3>Time Zone</h3>
            <p>{blintzbox && blintzbox.timezone}</p>
          </div>
          <div className="admin-detail-small-box">
            <h3>Passcode</h3>
            <p>{accessCode}</p>
          </div>
        </div>
        <div className="admin-detail-small-boxex-row">
          <div className="admin-detail-small-box">
            <h3 className="inactive">Status</h3>
            <p>{BOOKING_STATUS[status]}</p>
          </div>
        </div>
        <div className="admin-detail-small-box">
          <h3>Description</h3>
          <p>{blintzbox && blintzbox.description}</p>
        </div>
        <div className="admin-detail-small-box">
          <h3>Amenities</h3>
          <p>
            {blintzboxDetail &&
              blintzboxDetail.blintzbox_amenities &&
              blintzboxDetail.blintzbox_amenities.map(
                (e) => e.amenity.name + ", "
              )}{" "}
          </p>
        </div>
        <div className="admin-detail-small-box admin-detail-image-box">
          <h3>Images</h3>
          <div className="images-show-row">
            {bookingImages &&
              bookingImages.map((e) => <img src={e.url} alt="signin-back" />)}
            {bookingTabVal === "past" && (
              <div className="text-with-images" data-target="#send-image-modal">
                <span onClick={() => this.openImageModal(false)}>
                  Send Images
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  handleSendRecipt = () => {
    this.props.sendPaymentRecipt(this.props.bookingDetails.id);
  };

  openPaymentModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  render() {
    const {
        bookingDetails,
        userDetail,
        bookingPriceDetails,
        bookingTabVal,
        emptyState,
        blintzboxDetail,
        bookingImages,
      } = this.props,
      {
        id,
        datetimeStart,
        transactionId,
        status,
        blintzbox,
        amount,
        createdAt,
        accessCode,
      } = bookingDetails,
      {
        username,
        email,
        phone,
        profilePic,
        address,
        totalBooking,
        wallet,
        countryCode,
      } = userDetail,
      subtotal =
        bookingPriceDetails.length > 0 &&
        bookingPriceDetails.reduce((t, e) => t + Number(e.subtotal), 0),
      tax =
        bookingPriceDetails.length > 0 &&
        bookingPriceDetails.reduce((t, e) => t + Number(e.tax), 0),
      duration =
        bookingPriceDetails &&
        bookingPriceDetails.reduce((t, e) => t + Number(e.duration), 0),
      walletRecipt =
        bookingPriceDetails &&
        bookingPriceDetails.reduce((t, e) => t + Number(e.wallet), 0),
      discount =
        bookingPriceDetails &&
        bookingPriceDetails.reduce((t, e) => t + Number(e.discount), 0);
    return (
      <>
        <ShowPaymentModal
          openModal={this.state.openModal}
          openPaymentModal={this.openPaymentModal}
          bookingPriceDetails={bookingPriceDetails}
          subtotal={subtotal}
          duration={duration}
          bookingId={id}
          transactionId={transactionId}
        />
        {this.state.userDetailId ? (
          <>
            <Header
              heading="USER MANAGEMENT"
              routeLink={USER_ROUTE}
              subHeading={username}
            />
            <UserDetail
              userDetailId={this.state.userDetailId}
              emptyState={emptyState}
            />
          </>
        ) : (
          <>
            <div className="admin-right-detail-wrapper">
              {this.customerDetail(
                blintzbox,
                id,
                transactionId,
                datetimeStart,
                amount,
                createdAt,
                accessCode,
                status,
                blintzboxDetail,
                bookingTabVal,
                bookingImages
              )}
              <div className="admin-right-detail-box user-management-detail-detail-box user-detail-box-with-header right-detail-bottom-gap">
                <div className="user-detail-box-header">
                  <h3>Customer Details</h3>
                  <div className="user-detail-box-header-right">
                    <button
                      className="btn primary-btn btn-block"
                      onClick={() => this.handleRedirect()}
                    >
                      View Profile
                    </button>
                  </div>
                </div>
                <div className="user-detail-box">
                  <div className="user-detail-box-left">
                    <div className="user-detail-image-box">
                      <img src={profilePic || DefaultImage} alt="user" />
                    </div>
                  </div>
                  <div className="user-detail-box-right">
                    <div className="user-info-top-wrapper">
                      <div className="user-info-head-section">
                        <h3 className="user-info-heading">{username}</h3>
                        <p className="user-info-text">{email}</p>
                      </div>
                    </div>
                    <div className="admin-detail-small-boxex-row">
                      <div className="admin-detail-small-box">
                        <h3>Address</h3>
                        <p>{address && address.address}</p>
                      </div>
                      <div className="admin-detail-small-box">
                        <h3>City</h3>
                        <p>{address && address.city}</p>
                      </div>
                      <div className="admin-detail-small-box">
                        <h3>State</h3>
                        <p>{address && address.state}</p>
                      </div>
                    </div>
                    <div className="admin-detail-small-boxex-row">
                      <div className="admin-detail-small-box">
                        <h3>Country</h3>
                        <p>{address && address.country}</p>
                      </div>
                      <div className="admin-detail-small-box">
                        <h3>Zip Code</h3>
                        <p>{address && address.zipcode}</p>
                      </div>
                      <div className="admin-detail-small-box">
                        <h3>Phone Number</h3>
                        <p>
                          {phone
                            ? (
                                (countryCode || "") +
                                " " +
                                (phone || "")
                              ).replace(/[()-]/gi, "")
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="admin-detail-small-boxex-row">
                      <div className="admin-detail-small-box">
                        <h3>Number of bookings made</h3>
                        <p>{totalBooking}</p>
                      </div>
                      <div className="admin-detail-small-box">
                        <h3>Credit Amount</h3>
                        <p>${parseInt(wallet).toFixed(2) || "0.0"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-right-detail-box user-detail-box-with-header admin-right-payment-box">
                <div className="user-detail-box-header">
                  <h3>Payment Summary</h3>
                  <button
                    className="btn primary-btn"
                    onClick={() => this.handleSendRecipt()}
                  >
                    Send Recipt
                  </button>
                </div>
                <div className="user-detail-box">
                  <div className="payment-info-row payment-info-row-bottom-gap">
                    <p>
                      <span>blintzbox Subtotal</span>blintzbox @ $
                      {(subtotal + discount + walletRecipt).toFixed(2) || 0} /{" "}
                      {duration || 0} min
                    </p>
                    <strong>
                      ${(subtotal + walletRecipt + discount - tax).toFixed(2)}
                    </strong>
                  </div>
                  <div
                    className={`payment-info-row ${
                      discount === 0 && walletRecipt === 0
                        ? ""
                        : "payment-info-row-bottom-gap"
                    }`}
                  >
                    <p>
                      <span>Taxes & Fees</span>State Sales Tax
                    </p>
                    <strong>${Number(tax).toFixed(2)}</strong>
                  </div>
                  {discount !== 0 ? (
                    <div className="payment-info-row payment-info-row-bottom-gap">
                      <p>
                        <span>Promo Code</span>Amount Saved
                      </p>
                      <strong>-${discount || "0"}</strong>
                    </div>
                  ) : (
                    false
                  )}
                  {walletRecipt !== 0 ? (
                    <div className="payment-info-row">
                      <p>
                        <span>Wallet Amount</span>blintzbox Credits
                      </p>
                      <strong>
                        -${(walletRecipt && walletRecipt.toFixed(2)) || "0"}
                      </strong>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div className="user-detail-box-footer user-detail-with-payment-btn">
                  <div className="payment-info-row">
                    <p>Total</p>
                    <strong>
                      ${(subtotal && Number(subtotal).toFixed(2)) || "0"}
                    </strong>
                  </div>
                  <button
                    className="btn primary-btn"
                    onClick={() => this.openPaymentModal()}
                  >
                    Payment Info
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.imageModalStatus && (
          <SendImageModal
            imageModalStatus={this.state.imageModalStatus}
            openImageModal={this.openImageModal}
          />
        )}
      </>
    );
  }
}

export default connect(null, actions)(BookingDetail);
