import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import signinBck from "../../assets/images/signin-back.png";
import editImg from "../../assets/images/svg/edit.svg";
import {
  getClassName,
  isUrl,
  partnerClass,
  getHtmlClass,
} from "../../constants";

class AddMenuImages extends Component {
  handleClose = () => {
    // this.emptyCurrentState();
    this.props.emptyState();
  };

  render() {
    let { addPartnerHocStatus, editPartnerStatus } = this.props;
    return (
      <Modal
        show={addPartnerHocStatus}
        className="add-partner-modal form-modal"
        onHide={() => this.handleClose()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload-media-body">
            <div className="media">
              <div className="images-show-row">
                {this.props.downloadableUrls &&
                  this.props.downloadableUrls.map((e, i) => (
                    <div
                      key={i}
                      className="image-with-delete-box"
                      onClick={() => {
                        this.handleClose();
                        this.props.onSelectImage(i);
                      }}
                    >
                      <img
                        src={e.url}
                        alt="signin-back"
                        style={{ "border-color": "coral !important" }}
                      />
                      <p style={{ fontSize: 10 }}>{e.name}</p>
                      {/* <i
                        onClick={() => this.deleteImage(i, false)}
                        className="icon-cross"
                      ></i> */}
                    </div>
                  ))}
              </div>
              {/* <div className="upload-media-box">
                {this.props.downloadableUrls &&
                  this.props.downloadableUrls.map((e, i) => (
                    <div key={i}>
                      <img
                        src={e}
                        className="align-self-center"
                        alt="signin-back"
                      />
                    </div>
                  ))}
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button
            className="btn secondary-btn primary-main"
            data-dismiss="modal"
            onClick={() => this.handleClose()}
          >
            Cancel
          </button>
          <button
            className="btn primary-btn primary-main btn-right-gap"
            onClick={() => this.addEditLocation()}
          >
            {editPartnerStatus ? "Update" : "Add"}
          </button>{" "}
        </div>
      </Modal>
    );
  }
}

export default AddMenuImages;
