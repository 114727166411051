export const FORGOT_PASSWORD_URL = "/auth/forgotPassword";
export const PRICING_URL = "/pricingPlans";
export const BLINTZ_URL = "/blintzPizza";
export const AMENITIES_URL = "/amenities";
export const LOCATION_URL = "/locations";
export const COUNTRIES = "/countries";
export const STATES = "/states";
export const CITIES = "/cities";
export const USER_DETAIL_URL = "/users/";
export const SPONSORS_URL = "/admin/sponsors";
export const PARTNER_URL = "/partner";
export const BOOKING_URL = "/bookings";
export const LOGOUT = "auth/logout";
export const LOGIN = "auth/login";
export const USER_URL = "/users";
export const SIGNED_URL = "/images/signed-url";
export const PROMO_URL = "/promocode/assign-promocode/";
export const PANEL_REPORT = "/admin/dashboard/panel-report";
export const STATICS_REPORT = "/admin/dashboard/statistics";
export const PARTNER_API_URL = "/admin/business-partner";
export const REVENUE_REPORT = "/admin/dashboard/revenue";
export const GET_CREDIT = "/admin/credit";
export const GET_PROMO_CODE = "/promocode/seasonal";
export const EDIT_PROMO_CODE = "/promocode/";
