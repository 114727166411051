import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  const {
    heading,
    subHeading,
    routeLink,
    bookingStatus,
    searchedValue,
    locationSearch,
    locationName,
    isblintzbox,
    emptyState,
    bookingTabVal,
  } = props;
  const getHeader = () => {
    return bookingStatus ? (
      <>
        <li className="breadcrumb-item">
          <Link
            to={{
              pathname: routeLink,
              state: {
                bookingTabVal: bookingTabVal,
              },
            }}
          >
            {subHeading[0]}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {subHeading[1]}
        </li>
      </>
    ) : (
      <li className="breadcrumb-item active" aria-current="page">
        {subHeading}
      </li>
    );
  };

  const handleRoute = (e) => {
    if (isblintzbox) emptyState(true);
  };
  return (
    <div className="admin-right-header">
      {subHeading ? (
        <nav className="custom-breadcrumb" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              {isblintzbox ? (
                <Link onClick={(e) => handleRoute(e)}>
                  <span>{heading}</span>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: routeLink,
                    state: {
                      search: searchedValue,
                      location: locationSearch,
                      locationName,
                    },
                  }}
                >
                  {heading}
                </Link>
              )}
            </li>
            {getHeader()}
          </ol>
        </nav>
      ) : (
        <h2 className="admin-heading">{heading}</h2>
      )}
    </div>
  );
};

export default Header;
