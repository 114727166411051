import { beginLoaderCall, endLoaderCall } from ".";
import { logOutApi, updatePasswordApi } from "../services";
import { LOGOUT } from "./actionType";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";

/**
 * Action for sign out
 */
export const logOut = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return logOutApi()
    .then(() => {
      dispatch(endLoaderCall());
      localStorage.clear();
      dispatch({ type: LOGOUT });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for sign out
 */
export const updatePassword = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return updatePasswordApi()
    .then(() => {
      dispatch(endLoaderCall());
      successToast(8, "Password successfully updated");
    })
    .catch((error) => {
      errorToast(5, error.response.data.message);
      dispatch(endLoaderCall());
    });
};
