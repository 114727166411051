import { LOCATION_LIST, LOCATION_DETAILS ,LOCATION_COUNTRIES,LOCATION_STATES,
  LOCATION_CITIES, LOCATION_CSV} from '../actions';

export const locationList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === LOCATION_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const locationCsv = (state = {}, action) => {
  const { type, payload } = action;
  if (type === LOCATION_CSV) {
    return payload;
  } else {
    return state;
  }
};

export const locationDetail = (state = {}, action) => {
  const { type, payload } = action;
  if (type === LOCATION_DETAILS) {
    return payload;
  } else {
    return state;
  }
};

export const locCountries = (state = {}, action) => {
  const { type, payload } = action;
  if (type === LOCATION_COUNTRIES) {
    return payload;
  } else {
    return state;
  }
};

export const locStates = (state = {}, action) => {
  const { type, payload } = action;
  if (type === LOCATION_STATES) {
    return payload;
  } else {
    return state;
  }
};

export const locCities = (state = {}, action) => {
  const { type, payload } = action;
  if (type === LOCATION_CITIES) {
    return payload;
  } else {
    return state;
  }
};

