import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { Login } from "../components/Login";
import * as constants from "../constants";
import { EmailValidate } from "../utils";
import * as actions from "../actions";
import Loader from "../components/loader/Loader";

class LoginHoc extends Component {
  constructor(props) {
    super(props);
    this.state = { fields: {}, errors: {} };
  }

  /**
   * Function for handle change event
   */
  onChange = (event) => {
    const { fields, errors } = this.state;
    const { name, value } = event.target;
    if (errors[name]) {
      delete errors[name];
    }
    fields[name] = value;
    this.setState({ fields, errors });
  };

  /**
   * Function for Blur event
   */
  onBlur = (event) => {
    const { fields, errors } = this.state;

    const { name } = event.target;
    switch (name) {
      case "email":
        if (fields.email && !EmailValidate(fields.email)) {
          errors.email = constants.INVALID_EMAIL;
        } else if (!fields.email) {
          errors.email = constants.REQUIRE_EMAIL;
        } else {
          fields.email = fields.email.trim();
        }
        break;
      case "password":
        if (
          (fields.password && fields.password.length < 8) ||
          !fields.password
        ) {
          errors.password = constants.INVALID_PASSWORD;
        }
        break;
      default:
    }
    return this.setState({ errors });
  };
  /**
   * Function for handle validation
   */
  validateForm = () => {
    const { fields, errors } = this.state;
    if (!fields.email) {
      errors.email = constants.REQUIRE_EMAIL;
    }
    if (!fields.password) {
      errors.password = constants.INVALID_PASSWORD;
    }
    const elementName = Object.keys(errors)[0];
    elementName && document.getElementsByName(elementName)[0].focus();
    this.setState({ fields, errors });
    return isEmpty(errors);
  };

  /**
   * Function to handle form submit
   */
  onSubmit = async () => {
    const { fields } = this.state;
    const { login, history } = this.props;
    if (this.validateForm()) {
      await login(fields, history);
    }
  };

  passwordToggling = (event) => {
    const { name } = event.target;
    const inputType = document.getElementById(name).type;
    if (inputType === "password") {
      document.getElementById(name).type = "text";
      this.setState({ showPassword: true });
    } else {
      document.getElementById(name).type = "password";
      this.setState({ showPassword: false });
    }
  };

  render() {
    const { fields, errors, showPassword } = this.state;
    return (
      <>
        <Login
          fields={fields}
          errors={errors}
          showPassword={showPassword}
          handleOnBlur={this.onBlur}
          handleOnChange={this.onChange}
          handleSubmit={this.onSubmit}
          passwordToggling={this.passwordToggling}
        />
        {this.props.loading && <Loader />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loaderStatus.loading,
});

export default connect(mapStateToProps, actions)(LoginHoc);
