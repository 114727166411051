import axios from "axios";
import moment from "moment";
import firebase from "../config/FirebaseConfig";

import { USER_DETAIL_URL, USER_URL, PROMO_URL } from "../constants";
const date = new Date();
/**
 * Function to get logged in user details
 * @param  {Object} payload
 */
export const getUserInfo = (id) => {
  console.log("RESPONSE USER INFO ID 2", id);
  return firebase.firestore().collection("users").doc(id).get();
  // axios.get(USER_DETAIL_URL + id)
};

/**
 * Function to get user list
 */
export const getUsers = (
  searchKey,
  searchValue,
  search,
  page,
  offset,
  businessPartnerId,
  startDate,
  endDate
) =>
  axios.get(
    `${USER_URL}?${
      search ? `search=${search}` : ""
    }&page=${page}&limit=${offset}&${searchKey}=${searchValue}${
      businessPartnerId ? `&businessPartnerId=${businessPartnerId}` : ""
    }${
      startDate ? `&startDate=${moment(startDate).format("YYYY-MM-DD")}` : ""
    }${endDate ? `&endDate=${moment(endDate).format("YYYY-MM-DD")}` : ""}${
      startDate && !endDate
        ? `&endDate=${moment(date.setDate(startDate.getDate() + 1)).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }`
  );

/**
 * Function to update status
 * @param  {Object} payload
 */
export const updateUserInfo = (id, payload) =>
  axios.put(USER_URL + "/" + id, payload);

/**
 * Function to assign credit
 * @param  {Object} payload
 */
export const assignCreditInfo = (payload) =>
  axios.put(USER_URL + "/credit-wallet", payload);

export const assignPromoInfo = (payload) => axios.post(PROMO_URL, payload);
