import { SPONSOR_LIST, SPONSOR_DETAILS } from '../actions';

export const sponsorList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === SPONSOR_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const sponsorDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === SPONSOR_DETAILS) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
