import { beginLoaderCall, endLoaderCall } from ".";
import { PAGINATION_PAGE, ATTRIBUTE_NAME, SIGNED_URL_DATA } from ".";
import { successToast } from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

/**
 * Action for update blintzbox details
 */
export const updateblintzboxDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    dispatch(endLoaderCall());
    dispatch({
      type: "FF",
      payload: null,
    });
    successToast(1, MESSAGE.JBOX_UPDATE);
    resolve(true);
  });
};

/**
 * Action for delete blintzbox details
 */
export const deleteblintzboxDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve) => {
    dispatch(endLoaderCall());
    dispatch({
      type: "GB",
      payload: id,
    });
    successToast(2, "hello");
    resolve(true);
  });
};

export const setPaginationPage = (page) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: PAGINATION_PAGE,
      payload: page,
    });
    resolve(true);
  });
};

export const getSignedUrl = (filename, contentType, file) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    dispatch(endLoaderCall());
    dispatch({
      type: "DATA",
      payload: null,
    });
    resolve(true);
  });
};

export const updateblintzboxStatus = (id, payload) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    dispatch(endLoaderCall());
    successToast(1, "ESS");
    resolve(true);
  });
};

export const emptySignedUrl = (_) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: SIGNED_URL_DATA,
      payload: null,
    });
    resolve(true);
  });
};

export const setAttrName = (name) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: ATTRIBUTE_NAME,
      payload: name,
    });
    resolve(true);
  });
};
