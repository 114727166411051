import { AMENITIES_LIST, AMENITIES_DETAILS } from '../actions';

export const amenityList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === AMENITIES_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const amenityDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === AMENITIES_DETAILS) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
