import React, { Component } from "react";
const LocationHoc = (WrapperComponent) => {
  class LocationParent extends Component {
    render() {
      return <></>;
    }
  }
  return LocationParent;
};

export default LocationHoc;
