import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import * as constants from '../../constants';

class AssignCreditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      enableButton: true,
      clickManage: false
    };
  }
  handleSubmit = () => {
    const { amount } = this.state;
    const newAmount = amount.replace('$ ', '');
    const { handleAssignCredit } = this.props;
    handleAssignCredit(parseFloat(newAmount).toFixed(2));
  };
  handleChange = e => {
    const value = e.target.value.replace('$ ', '');
    if (parseInt(value) >= 0 || value === '') {
      if (constants.discountRegex.test(value)) {
        this.setState({
          amount: (value.length > 0 ? '$ ' : '') + value,
          enableButton: value.length > 0 && value !== '.' ? false : true
        });
      }
    }
  };
  handleClick = () => {
    this.setState({
      clickManage: true
    });
  };
  render() {
    const { openModal, openCreditModal } = this.props;
    return (
      <Modal
        show={openModal}
        className='edit-amenities-modal form-modal'
        onHide={openCreditModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <div
              className={`floating-input ${
                this.state.clickManage
                  ? 'move active label-heightlight'
                  : ''
                }`}
            >
              <label>Amount</label>
              <input
                type='text'
                className='form-control'
                name='Amount'
                value={this.state.amount}
                onChange={e => this.handleChange(e)}
                onClick={() => this.handleClick()}
              />
            </div>
          </div>
        </Modal.Body>
        <div className='modal-footer'>
          <button
            className='btn secondary-btn primary-main'
            data-dismiss='modal'
            onClick={() => {
              openCreditModal();
            }}
          >
            Cancel
          </button>
          <button
            className='btn primary-btn primary-main btn-right-gap'
            disabled={this.state.enableButton}
            onClick={() => this.handleSubmit()}
          >
            Assign
          </button>
        </div>
      </Modal>
    );
  }
}

export default AssignCreditModal;
