import React from "react";
import { Link } from "react-router-dom";

import { handleActiveTab } from "../../utils";
import * as constants from "../../constants";
import promocodeicon from "../../assets/images/promocode.svg";

const NavigationBar = () => {
  return (
    <div className="admin-left-wrapper">
      <ul>
        <li>
          <Link
            to={constants.BOOKING_ROUTE}
            className={handleActiveTab(constants.BOOKING_ROUTE)}
          >
            <i className="icon-booking-icn"></i> Track Orders
          </Link>
        </li>
        <li>
          <Link
            to={constants.ORDER_ROUTE}
            className={handleActiveTab(constants.ORDER_ROUTE)}
          >
            <i className="icon-booking-icn"></i> Past Orders
          </Link>
        </li>
        <li>
          <Link
            to={constants.DASHBOARD_ROUTE}
            className={handleActiveTab(constants.DASHBOARD_ROUTE)}
          >
            <i className="icon-dashboard-icon"></i> Dashboard
          </Link>
        </li>
        <li>
          <Link
            to={constants.MENU_ROUTE}
            className={handleActiveTab(constants.MENU_ROUTE)}
          >
            <i className="icon-dashboard-icon"></i> Menu
          </Link>
        </li>
        <li>
          <Link
            to={constants.USER_ROUTE}
            className={handleActiveTab(constants.USER_ROUTE)}
          >
            <i className="icon-customer-icn"></i> User Management
          </Link>
        </li>
        <li>
          <Link
            to={constants.BLINTZBOX_ROUTE}
            className={handleActiveTab(constants.BLINTZBOX_ROUTE)}
          >
            <i className="icon-btlinzbox-icn"></i> Bltinz Management
          </Link>
        </li>
        <li>
          <Link
            to={constants.SPONSORS_ROUTE}
            className={handleActiveTab(constants.SPONSORS_ROUTE)}
          >
            <i className="icon-sponsor-icn"></i> Sponsors
          </Link>
        </li>
        <li>
          <Link
            to={constants.PRICING_ROUTE}
            className={handleActiveTab(constants.PRICING_ROUTE)}
          >
            <i className="icon-pricing-icn"></i> Pricing Plans
          </Link>
        </li>
        <li>
          <Link
            to={constants.PARTNER_ROUTE}
            className={handleActiveTab(constants.PARTNER_ROUTE)}
          >
            <i className="icon-partners-icn"></i> Partners
          </Link>
        </li>
        <li>
          <Link
            to={constants.QR_ROUTE}
            className={handleActiveTab(constants.QR_ROUTE)}
          >
            <i className="icon-qr-code-icn"></i> QR Code
          </Link>
        </li>
        <li>
          <Link
            to={constants.AMENITIES_ROUTE}
            className={handleActiveTab(constants.AMENITIES_ROUTE)}
          >
            <i className="icon-dashboard-icn"></i> Amenities
          </Link>
        </li>
        <li>
          <Link
            to={constants.LOCATION_ROUTE}
            className={handleActiveTab(constants.LOCATION_ROUTE)}
          >
            <i className="icon-places-icn"></i> Location
          </Link>
        </li>
        <li>
          <Link
            to={constants.SEASONAL_PROMO_CODE_ROUTE}
            className={handleActiveTab(constants.SEASONAL_PROMO_CODE_ROUTE)}
          >
            <img src={promocodeicon} /> &nbsp; Promo Code
          </Link>
        </li>
        <li>
          <Link
            to={constants.PARTNERS_API_ROUTE}
            className={handleActiveTab(constants.PARTNERS_API_ROUTE)}
          >
            <i className="icon-partners-api-icon"></i> Partners Api
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
