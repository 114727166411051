import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { getClassName } from '../../constants';

function AddEditPartnerApiModal(props) {
    const initialState = { code: false, name: false };
    const [partnerData, setPartnerState] = useState(props),
        [classStatus, setPartnerClass] = useState(initialState);

    useEffect(() => {
        setPartnerState(props.partnerApiData);
    }, [props]);

    const updateField = (e) => {
        setPartnerState({ ...partnerData, [e.target.name]: e.target.value });
    };

    const addEditLocation = async () => {
        if (props.editPartnerApiStatus) {
            await props.updatePartnerApi(partnerData.id, { name: partnerData.name });
        } else await props.createPartnerApi(partnerData);
        await props.getPartnerApiList();
        props.emptyState();
        setPartnerClass({ ...initialState });
    };

    const updateClassName = (e, status) => {
        const newName = status ? 'price' : e.target.name;
        setPartnerClass({ ...classStatus, [newName]: true });
    };

    const handleClose = () => {
        setPartnerClass({ ...initialState });
        props.emptyState();

    };
    return (
        <Modal
            show={props.addPartnerApiHocStatus}
            className='add-partner-modal form-modal'
            onHide={handleClose}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.editPartnerApiStatus ? 'Edit Partner Api' : 'Add Partner Api'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='form-group'>
                    <div
                        className={`floating-input ${getClassName(
                            classStatus.name,
                            props.editPartnerApiStatus
                        )}`}>
                        <label>Partner Name</label>
                        <input
                            type='text'
                            value={partnerData && partnerData.name}
                            onFocus={e => updateClassName(e)}
                            onChange={e => updateField(e, false, false)}
                            className='form-control'
                            name='name' />
                    </div>
                </div>
                <div className='form-group'>
                    <div
                        className={`floating-input ${getClassName(
                            classStatus.code,
                            props.editPartnerApiStatus
                        )}`}>
                        <label>Partner Code</label>
                        <input
                            disabled={props.editPartnerApiStatus ? true : false}
                            type='text'
                            value={partnerData && partnerData.code}
                            onChange={e => updateField(e, false, false)}
                            onFocus={e => updateClassName(e)}
                            className='form-control'
                            name='code' />
                    </div>
                </div>
            </Modal.Body>
            <div className='modal-footer'>
                <button
                    className='btn secondary-btn primary-main'
                    data-dismiss='modal'
                    onClick={() => handleClose()}>
                    Cancel
                </button>
                <button
                    className='btn primary-btn primary-main btn-right-gap'
                    disabled={partnerData && partnerData.name ? false : true}
                    onClick={() => addEditLocation()}>
                    {props.editPartnerApiStatus ? 'Update' : 'Add'}
                </button>{' '}
            </div>
        </Modal>
    );
}

export default AddEditPartnerApiModal;
