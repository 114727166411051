import { beginLoaderCall, endLoaderCall } from ".";
import {
  addBooking,
  fetchBookingList,
  getBookingInfo,
  updateBookingInfo,
  deleteBookingInfo,
  getBookingPriceDetailsApi,
  refundBookingAmount,
  lockUpcomingBookingApi,
  sendImagesApi,
  getRefundDetailsApi,
  sendPaymentReciptApi,
  getBookingImagesApi,
} from "../services";
import {
  BOOKING_DETAIL,
  BOOKING_CREATED,
  BOOKING_UPDATED,
  BOOKING_REMOVED,
  BOOKING_PRICE,
  BOOKING_REFUND,
  BOOKING_IMAGES,
} from "../actions";
import {
  errorToast,
  successToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

export const getBookingList = (
  type,
  filter,
  page,
  offset,
  sortKey,
  sortType,
  userId = null,
  startDate = "",
  endDate = "",
  search = "",
  businessPartnerId,
  status = true
) => (dispatch) => {
  let finalValue = filter && filter.filter((x) => x);
  if (status) dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    fetchBookingList(
      finalValue && finalValue.length ? filter : ["current"],
      page,
      offset,
      sortKey,
      sortType,
      userId,
      startDate,
      endDate,
      search,
      businessPartnerId
    )
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for get Booking details
 */
export const getBookingDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getBookingInfo(id)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: BOOKING_DETAIL,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for create Booking
 */
export const createBooking = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addBooking(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: BOOKING_CREATED,
          payload: response,
        });
        successToast(1, MESSAGE.BOOKING_CREATED);
        resolve(true);
      })
      .catch(() => {
        errorToast(1, MESSAGE.BOOKING_CREATED_ERROR);
        reject(false);
        dispatch(endLoaderCall());
      });
  });
};

/**
 * Action for update Booking details
 */
export const updateBookingDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updateBookingInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: BOOKING_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.BOOKING_UPDATED);
        resolve(true);
      })
      .catch(() => {
        errorToast(2, MESSAGE.BOOKING_UPDATED_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for delete booking details
 */
export const deleteBookingDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve) => {
    deleteBookingInfo(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: BOOKING_REMOVED,
          payload: id,
        });
        successToast(2, MESSAGE.BOOKING_DELETED);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(1, response.response.data.message);
        } else {
          errorToast(1, MESSAGE.BOOKING_DELETED_ERROR);
        }
        dispatch(endLoaderCall());
      });
  });
};

/**
 * Action for delete booking details
 */
export const getBookingPriceDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve) => {
    getBookingPriceDetailsApi(id)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: BOOKING_PRICE,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
      });
  });
};

export const lockUpcomingBooking = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve) => {
    lockUpcomingBookingApi(id)
      .then((res) => {
        dispatch(endLoaderCall());
        successToast(2, res);
        resolve(true);
      })
      .catch((response) => {
        errorToast(1, response.response.data.message);
        dispatch(endLoaderCall());
      });
  });
};

export const refundBooking = (id, payload) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    refundBookingAmount(id, payload)
      .then((response) => {
        dispatch(endLoaderCall());
        successToast(2, MESSAGE.REFUND_SUCCESS);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(1, response.response.data.message);
        } else {
          errorToast(1, MESSAGE.REFUND_FAIL);
        }
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

export const sendImagesAction = (id, email) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    sendImagesApi(id, email)
      .then((response) => {
        dispatch(endLoaderCall());
        successToast(2, MESSAGE.SEND_IMAGES_SUCCESS);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(1, response.response.data.message);
        } else {
          errorToast(1, MESSAGE.SEND_IMAGES_ERROR);
        }
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

export const sendPaymentRecipt = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve) => {
    sendPaymentReciptApi(id)
      .then((res) => {
        dispatch(endLoaderCall());
        successToast(2, res);
        resolve(true);
      })
      .catch((response) => {
        errorToast(1, response.response.data.message);
        dispatch(endLoaderCall());
      });
  });
};

export const getBookingImages = (id) => (dispatch) => {
  return new Promise((resolve) => {
    getBookingImagesApi(id)
      .then((response) => {
        dispatch({
          type: BOOKING_IMAGES,
          payload: response,
        });
        resolve(true);
      })
      .catch((response) => {});
  });
};

export const getRefundDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve) => {
    getRefundDetailsApi(id)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: BOOKING_REFUND,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
      });
  });
};
