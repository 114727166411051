import axios from 'axios';

import { PRICING_URL } from '../constants';

/**
 * Function to get pricing list
 */
export const fetchPricingList = (search, page, offset, sortKey, sorttype) =>
  axios.get(`${PRICING_URL}?search=${search || ''}&page=${page || 0}&limit=${offset || 10}${sortKey ? `&${sortKey}=${sorttype}` : ''}`);

/**
 * Function to get pricing plan
 * @param  {Object} payload
 */
export const getPricingInfo = id => axios.get(PRICING_URL + '/' + id);

/**
 * Function to add pricing plan
 * @param  {Object} payload
 */
export const addPricingPlan = payload => axios.post(PRICING_URL, payload);

/**
 * Function to update pricing plan
 * @param  {Object} payload
 */
export const updatePricingInfo = (id, payload) =>
  axios.put(PRICING_URL + '/' + id, payload);

/**
 * Function to delete pricing plan
 * @param  {Object} payload
 */
export const deletePricingInfo = id => axios.delete(PRICING_URL + '/' + id);
