import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/rightPanel/Header";
import Loader from "../components/loader/Loader";
import HeaderStatics from "../components/dashboard/HeaderStatics";
import BookingTable from "../components/dashboard/BookingTable";
import Revenue from "../components/dashboard/Revenue";
import * as actions from "../actions";
import UserDetail from "../components/blintzbox/UserDetail";
import { BOOKING_ROUTE, USER_ROUTE, DASHBOARD_ROUTE } from "../constants";

const DashboardHoc = (WrapperComponent) => {
  class DashboardParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userDetailId: null,
        searchedValue: "",
        userData: null,
        bookingData: null,
        bookingDetailId: null,
      };
    }
    componentDidMount = async () => {
      await this.props.getTotalOrdersCount();
      await this.props.getTotalCustomerCount();
    };

    handleCustomer = async (data, status) => {
      if (status)
        await this.setState({ userDetailId: data.id, userData: data });
      else {
        let {
          getUserDetails,
          getBookingDetails,
          getBookingPriceDetails,
        } = this.props;

        await getBookingDetails(data.id);
        getUserDetails(this.props.bookingDetails.userId, true);
        getBookingPriceDetails(data.id);
        await this.setState({
          bookingData: this.props.bookingDetails,
          bookingDetailId: this.props.bookingDetails.id,
        });
      }
    };
    emptyState = async () => {
      await this.setState(this.initialState);
    };

    render() {
      let { loading, partnersAPi } = this.props;
      let {
        userDetailId,
        searchedValue,
        userData,
        bookingDetailId,
        bookingData,
      } = this.state;
      let date = new Date();
      return (
        <>
          {userDetailId && (
            <>
              <Header
                heading="USER MANAGEMENT"
                routeLink={USER_ROUTE}
                subHeading={userData.username}
                searchedValue={searchedValue}
              />
              <UserDetail
                userDetailId={userDetailId}
                emptyState={this.emptyState}
                partnersAPi={partnersAPi}
              />
            </>
          )}
          {bookingDetailId && (
            <>
              <Header
                heading="BOOKINGS"
                subHeading={["UPCOMING BOOKING", bookingData.id]}
                routeLink={BOOKING_ROUTE}
                bookingStatus={true}
              />
            </>
          )}
          {userDetailId || bookingDetailId ? (
            false
          ) : (
            <>
              <Header heading="Dashboard" routeLink={DASHBOARD_ROUTE} />
              <div className="admin-right-data-wrapper admin-tab-view-data-wrapper admin-right-dashboard-wrapper">
                <HeaderStatics />
                <Revenue />
                <BookingTable handleCustomer={this.handleCustomer} />
              </div>
              <div className="admin-right-footer">
                <p className="admin-right-footer-text">
                  © {date.getFullYear()} Blintz, All Rights Reserved.
                </p>
              </div>
            </>
          )}
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    commingBooking: state.bookingList.commingBooking || {},
    bookingPriceDetails: state.bookingPriceDetails.bookingPriceDetails || [],
    userDetail: state.userById,
    bookingDetails: state.bookingDetails || {},
    loading: state.loaderStatus.loading,
    partnersAPi: state.partnerApiList || {},
  });
  return connect(mapStateToProps, actions)(DashboardParent);
};

export default DashboardHoc;
