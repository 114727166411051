import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

function ShowPaymentModal({
  openModal,
  openPaymentModal,
  bookingPriceDetails,
  subtotal,
  duration,
  bookingId,
  tax,
  transactionId,
}) {
  const getDate = (value) => {
    let date = moment(value),
      dayN = date.format("D"),
      day = date.year(value, '"YYYY-MM-DD HH:mm:ss"').format("dddd"),
      month = moment(value)
        .subtract(0, "month")
        .startOf("month")
        .format("MMMM"),
      year = date.format("YYYY"),
      time = date.format("HH:mm A");
    return `${day.substring(0, 3)}, ${month.substring(0, 3)} ${dayN} ,${year}`;
  };
  return (
    <Modal
      show={openModal}
      className="payment-summary-modal form-modal"
      onHide={() => openPaymentModal()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">Payment Summary</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="payment-summary-main-detail">
          <div className="payment-summary-main-detail-row">
            <strong>Booking Id</strong>
            <p>{bookingId}</p>
          </div>
          <div className="payment-summary-main-detail-row">
            <strong>Total</strong>
            <p>${(subtotal && Number(subtotal).toFixed(2)) || "0"} </p>
          </div>
        </div>
        {bookingPriceDetails.map((data) => {
          let subtotal = (
            Number(data.subtotal) +
            Number(data.wallet) +
            Number(data.discount) -
            Number(data.tax)
          ).toFixed(2);
          let total = Number(data.subtotal);
          let value = (
            Number(data.subtotal) +
            Number(data.wallet) +
            Number(data.discount)
          ).toFixed(2);
          return (
            <div className="payment-summary-inner-box">
              <div className="payment-summary-header">
                <div className="payment-summary-header-left">
                  <h4>Transaction ID:</h4>
                  <strong>#{transactionId}</strong>
                </div>
                <p className="payment-summary-date">
                  {getDate(data.createdAt)}
                </p>
              </div>
              <div className="payment-summary-mid-box">
                <div className="payment-summary-detail-box">
                  <div className="payment-summary-detail-box-left">
                    <h4>blintzbox Subtotal</h4>
                    <strong>
                      blintzbox @ ${value || "0"} - {data.duration || 0} min
                    </strong>
                  </div>
                  <p>${subtotal}</p>
                </div>
                <div className="payment-summary-detail-box">
                  <div className="payment-summary-detail-box-left">
                    <h4>Taxes & Fees</h4>
                    <strong>State sales Tax</strong>
                  </div>
                  <p>${(data.tax && Number(data.tax).toFixed(2)) || "0"}</p>
                </div>
                {data.discount !== 0 && (
                  <div className="payment-summary-detail-box">
                    <div className="payment-summary-detail-box-left">
                      <h4>Promo Code</h4>
                      <strong>Amount Saved</strong>
                    </div>
                    <p>-${Number(data.discount).toFixed(2) || "0"}</p>
                  </div>
                )}
                {data.wallet !== 0 && (
                  <div className="payment-summary-detail-box">
                    <div className="payment-summary-detail-box-left">
                      <h4>Credit Amount</h4>
                      <strong>blintzbox Credits</strong>
                    </div>
                    <p>-${Number(data.wallet).toFixed(2) || "0"}</p>
                  </div>
                )}
              </div>
              <div className="payment-summary-footer">
                <h4>Total</h4>
                <p>${(total && Number(total).toFixed(2)) || "0"}</p>
              </div>
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
}

export default ShowPaymentModal;
