import { beginLoaderCall, endLoaderCall } from "./loader.action";
import { MENU_IMAGES } from "./actionType";
import { getAllPizzaImages } from "../services/menu.api";

export const fetchPizzaImages = () => (dispatch) => {
  dispatch(beginLoaderCall());
  return getAllPizzaImages()
    .then((response) => {
      dispatch(endLoaderCall());
      console.log("ACTION", response);
      dispatch({
        type: MENU_IMAGES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};
