import React from "react";
import ReactToPrint from "react-to-print";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <button class="hide-on-print" style={{ height: 50, padding: 20 }}>
                Print
              </button>
            );
          }}
          content={() => this.componentRef}
        />
        <table
          style={{}}
          class="print-receipt"
          ref={(ref) => (this.componentRef = ref)}
        >
          <tr>
            <th>Company</th>
            <th>Contact</th>
            <th>Country</th>
          </tr>
          <tr>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
          </tr>
          <tr>
            <td>Centro comercial Moctezuma</td>
            <td>Francisco Chang</td>
            <td>Mexico</td>
          </tr>
          <tr>
            <td>Ernst Handel</td>
            <td>Roland Mendel</td>
            <td>Austria</td>
          </tr>
        </table>
        <button class="hide-on-print" onClick={this.printReceipt}>
          Print
        </button>
      </>
    );
  }
}
