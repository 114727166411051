import React from 'react';
import successImg from '../../assets/images/success.png';
import { toast } from 'react-toastify';

// Toast Notification Success Component
const Success = ({ message }) => (
  <div className='alert alert-success'>
    <img src={successImg} alt='tick mark' className='alert-img' />
    <span className='alert-text'>{message}</span>
    <div className='close'>
      <i className='icon-cross'></i>
    </div>
  </div>
);

export const successToast = (id, message) => {
  toast(<Success message={message} />, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: true,
    toastId: id
  });
};

const Error = ({ message }) => (
  <div className='alert alert-danger'>
    <span className='alert-text'>{message}</span>
    <div className='close'>
      <i className='icon-cross'></i>
    </div>
  </div>
);

// Toast Notification Error Component
export const errorToast = (id, message) => {
  toast.error(<Error message={message} />, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    toastId: id
  });
};
