import React, { Component } from 'react';
import Select from 'react-select';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { addFocus: false, defaultValue: { value: '', label: '' } };
  }

  render() {
    const { name, value, onChange, isSearchable } = this.props;
    const { defaultValue } = this.state;
    return (
      <div className='custom-react-dropdown'>
        <Select
          isSearchable={isSearchable || false}
          {...this.props}
          value={value ? value : defaultValue}
          onChange={object => onChange({ name, value: object.value })}
          className='select-custom'
        />
      </div>
    );
  }
}
