import { beginLoaderCall, endLoaderCall } from ".";
import {
  getPartnersApi,
  addPartnerApi,
  updatePartnerInfoApi,
  deletePartnerInfoApi,
} from "../services";
import {
  PARTNER_API_LIST,
  PARTNERS_API_CREATED,
  PARTNERS_API_DELETED,
  PARTNERS_API_UPDATED,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

/**
 * Action for get user details
 */
export const getPartnerApiList = (
  search,
  page,
  offset,
  sortKey,
  sorttype,
  status = true
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return getPartnersApi(search, page, offset, sortKey, sorttype)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: PARTNER_API_LIST,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for create Partner
 */
export const createPartnerApi = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addPartnerApi(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PARTNERS_API_CREATED,
          payload: response,
        });
        successToast(1, MESSAGE.PARTNER_API_CREATED);
        resolve(true);
      })
      .catch((response) => {
        if (response.response) {
          errorToast(1, response.response.data.message);
        } else {
          errorToast(4, MESSAGE.PARTNER_API_CREATED_ERROR);
        }
        dispatch(endLoaderCall());
      });
  });
};

/**
 * Action for update Partner details
 */
export const updatePartnerApi = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updatePartnerInfoApi(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PARTNERS_API_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.PARTNER_API_UPDATED);
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
        errorToast(4, MESSAGE.PARTNER_API_UPDATED_ERROR);
      });
  });
};

/**
 * Action for delete Partner details
 */
export const deletePartnerApi = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    deletePartnerInfoApi(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: PARTNERS_API_DELETED,
          payload: id,
        });
        successToast(1, MESSAGE.PARTNER_API_DELETED);
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
        errorToast(4, MESSAGE.PARTNER_API_DELETED_ERROR);
      });
  });
};
