import React, { Fragment } from "react";

import ListWrapper from "../listWrapper";
import Filters from "./filters";
import Header from "./Header";

const RightPanel = (props) => {
  const { heading, tablist, data } = props;
  return (
    <Fragment>
      {tablist ? false : <Header heading={heading} data={data} />}
      <Filters {...props} />
      <ListWrapper {...props} />
    </Fragment>
  );
};

export default RightPanel;
