import React, { Component } from "react";
import { connect } from "react-redux";

import { PRICING_PLAN_HEADERS, getPageNumber } from "../constants";
import AddEditPriceModal from "../components/blintzbox/AddEditPriceModal";
import * as actions from "../actions";
import Loader from "../components/loader/Loader";

const PricingPlanHoc = (WrapperComponent) => {
  class PricingPlanParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        addPriceHocStatus: false,
        addRemoveDeleteModal: false,
        editPricingStatus: false,
        deleteLocStatus: false,
        pricingData: null,
        sortKey: null,
        sortType: null,
      };
      this.initialState = this.state;
      this.props.setPaginationPage(0);
    }

    componentDidMount = async () => {
      this.props.setPaginationPage(0);
      const { getPricingList } = this.props;
      if (this.props.location.state) {
        await this.setState({
          searchedValue: this.props.location.state.search,
        });
      }
      getPricingList(this.state.searchedValue);
    };

    sortPriceList = (header, type) => {
      const { getPricingList } = this.props;
      let headerKey;
      if (header === "Plan Duration(Minutes)") {
        headerKey = "sortByDuration";
      } else {
        headerKey = "sortByCost";
      }
      this.setState({
        sortKey: headerKey,
        sortType: type,
      });
      getPricingList(this.state.searchedValue, 0, 10, headerKey, type);
    };

    addRemoveEditPricingPage = async (status, data) => {
      if (status === "Edit") {
        await this.setState({
          pricingData: data,
          editPricingStatus: true,
        });
      }
      await this.setState({ addPriceHocStatus: !this.state.addPriceHocStatus });
    };

    getPricingDetails = async (page) => {
      const { sortKey, sortType } = this.state;
      await this.props.setPaginationPage(page - 1);
      this.props.getPricingList(
        this.state.searchedValue,
        page - 1,
        10,
        sortKey,
        sortType
      );
    };

    // open delete Pricing Plan modal
    deletePricingPage = async (status, data) => {
      let {
          searchedValue,
          addRemoveDeleteModal,
          sortKey,
          sortType,
        } = this.state,
        { plans, paginationPage } = this.props;
      if (status) {
        await this.props.deletePricingDetails(data.id);
        await this.props.getPricingList(
          searchedValue,
          getPageNumber(plans.totalCount, paginationPage),
          10,
          sortKey,
          sortType
        );
      } else await this.setState({ pricingData: data });
      await this.setState({ addRemoveDeleteModal: !addRemoveDeleteModal });
    };

    // search Pricing Plan list
    searchPricingPlan = (value) => {
      const { sortKey, sortType } = this.state;
      this.setState({ searchedValue: value });
      setTimeout(
        () => this.props.getPricingList(value, 0, 10, sortKey, sortType, false),
        500
      );
    };

    emptyState = async (status) => {
      if (status) await this.setState({ searchedValue: "" });
      await this.setState(this.initialState);
    };

    render() {
      const { plans } = this.props;
      const {
          updatePricingDetails,
          createPricingDetails,
          getPricingList,
          paginationPage,
          setAttrName,
          attriName,
          loading,
        } = this.props,
        {
          addPriceHocStatus,
          pricingData,
          editPricingStatus,
          addRemoveDeleteModal,
          searchedValue,
        } = this.state;
      return (
        <>
          <WrapperComponent
            data={plans}
            addButton="Plan"
            heading="PRICING PLANS"
            headerList={PRICING_PLAN_HEADERS}
            displayPage={this.addRemoveEditPricingPage} // open add location modal and edit modal in Pricing Plan list
            openDeleteModal={this.deletePricingPage} // open delete location modalin Pricing Plan list
            searchListDetails={this.searchPricingPlan} // search Pricing Plan list
            addRemoveDeleteModal={addRemoveDeleteModal}
            deleteDataDetails={pricingData}
            getPaginationDetails={this.getPricingDetails}
            numberType="number"
            sortList={this.sortPriceList}
            searchedValue={searchedValue}
            showStatus={true}
          />
          <AddEditPriceModal
            addPriceHocStatus={addPriceHocStatus}
            createPricingDetails={createPricingDetails}
            updatePricingDetails={updatePricingDetails}
            pricingData={pricingData}
            editPricingStatus={editPricingStatus}
            emptyState={this.emptyState}
            paginationPage={paginationPage}
            getPricingList={getPricingList}
            addRemoveEditPricingPage={this.addRemoveEditPricingPage} // open add location modal and edit modal in Pricing Plan list
            searchedValue={searchedValue}
            setAttrName={setAttrName}
            attriName={attriName}
          />
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    plans: state.pricingList || {},
    paginationPage: state.paginationPage.paginationPage,
    attriName: state.attriName.attriName,
    loading: state.loaderStatus.loading,
  });

  return connect(mapStateToProps, actions)(PricingPlanParent);
};

export default PricingPlanHoc;
