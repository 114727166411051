import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import * as actions from "../../actions";
import { getItem } from "../../../src/utils";
import ChangePasswordModal from "../../components/blintzbox/ChangePasswordModal";
import firebase from "../../config/FirebaseConfig";

import {
  BLACK_LOGO,
  DOWN_ARROW,
  BLINTZBOX_ROUTE,
  BLINTZ_LOGO,
} from "../../constants";

class Header extends React.Component {
  state = {
    hideShowModal: false,
  };

  handleLogout(e) {
    e.preventDefault();
    this.props.logOut();
  }
  async componentDidMount() {
    await this.props.getUserDetails(getItem("userId"));
  }

  openChangePassword = () => {
    this.setState({ hideShowModal: !this.state.hideShowModal });
  };

  render() {
    const { userInfo } = this.props;
    console.log("[userInfo ]", userInfo);
    console.log("[firebase user]", firebase.auth().currentUser);
    return (
      <header>
        <div className="container-fluid">
          <Link to={BLINTZBOX_ROUTE} className="logo-wrapper">
            <img src={BLINTZ_LOGO} alt="logo-black" style={{ height: 30 }} />
          </Link>
          <div className="dropdown custom-dropdown custom-dropdown-with-arrow">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {userInfo && userInfo.email}
                <img src={DOWN_ARROW} alt="arrow-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  onClick={() => this.openChangePassword()}
                >
                  <i className="icon-change-password"></i> Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  className="dropdown-item-with-color"
                  onClick={(e) => this.handleLogout(e)}
                >
                  <i className="icon-logout-icon"></i> Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <ChangePasswordModal
            openChangePassword={this.openChangePassword}
            hideShowModal={this.state.hideShowModal}
            setAttrName={this.props.setAttrName}
          />
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user || {},
});

export default connect(mapStateToProps, actions)(Header);
