import React from 'react';
import { PhotoshopPicker } from 'react-color';

const ColorPicker = ({
  name,
  updateColorField,
  color,
  handleCancelColorPicker,
  handleAcceptColorPicker
}) => {
  return (
    <PhotoshopPicker
      color={color}
      onChange={color => updateColorField(color, name)}
      onCancel={() => handleCancelColorPicker(name)}
      onAccept={() => handleAcceptColorPicker(name)}
    />
  );
};

export default ColorPicker;
