import BLACK_LOGO_ICON from "../assets/images/svg/logo-black.svg";
import DOWN_ARROW_ICON from "../assets/images/svg/arrow-down.svg";
import DESCENDING_ICON from "../assets/images/svg/descending.svg";
import ASCENDING_ICON from "../assets/images/svg/ascending.svg";
import SEARCH_ICON from "../assets/images/svg/search-icon.svg";
import WHITE_LOGO_ICON from "../assets/images/white-logo.png";
import EYE_CLOSED_ICON from "../assets/images/eye-closed.png";
import DELETE_ICON from "../assets/images/svg/delete.svg";
import SUCCESS from "../assets/images/success@2x.png";
import EDIT_ICON from "../assets/images/svg/edit.svg";
import QR_ICON from "../assets/images/qr-code.jpg";
import EYE from "../assets/images/eye.png";
import BLINTZ_LOGO_ICON from "../assets/images/blintz.png";
import AUDIO_SOUND from "../assets/audio/notification.mp3";

export const WHITE_LOGO = WHITE_LOGO_ICON;
export const EYE_CLOSED = EYE_CLOSED_ICON;
export const BLACK_LOGO = BLACK_LOGO_ICON;
export const DOWN_ARROW = DOWN_ARROW_ICON;
export const DESCENDING = DESCENDING_ICON;
export const ASCENDING = ASCENDING_ICON;
export const SUCCESS_ICON = SUCCESS;
export const SEARCH = SEARCH_ICON;
export const DELETE = DELETE_ICON;
export const EDIT = EDIT_ICON;
export const QR_CODE = QR_ICON;
export const EYE_ICON = EYE;
export const BLINTZ_LOGO = BLINTZ_LOGO_ICON;
export const AUDIO_SOUND_PLAY = AUDIO_SOUND;
