import { beginLoaderCall, endLoaderCall } from ".";
import {
  fetchSponsorList,
  getSponsorInfo,
  addSponsor,
  updateSponsorInfo,
  deleteSponsorInfo,
} from "../services";
import {
  SPONSOR_LIST,
  SPONSOR_DETAILS,
  SPONSOR_CREATED,
  SPONSOR_DELETED,
  SPONSOR_UPDATED,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

export const getSponsorList = (
  search,
  page,
  offset,
  sortKey,
  sortType,
  status = true
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    fetchSponsorList(search, page, offset, sortKey, sortType)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: SPONSOR_LIST,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for get Sponsor details
 */
export const getSponsorDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getSponsorInfo(id)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: SPONSOR_DETAILS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for create Sponsor
 */
export const createSponsor = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addSponsor(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: SPONSOR_CREATED,
          payload: response,
        });
        successToast(1, MESSAGE.SPONSOR_CREATED);
        resolve(true);
      })
      .catch(() => {
        errorToast(1, MESSAGE.SPONSOR_CREATED_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for update Sponsor details
 */
export const updateSponsorDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updateSponsorInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: SPONSOR_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.SPONSOR_UPDATED);
        resolve(true);
      })
      .catch(() => {
        errorToast(2, MESSAGE.SPONSOR_UPDATED_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for delete Sponsor details
 */
export const deleteSponsorDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    deleteSponsorInfo(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: SPONSOR_DELETED,
          payload: id,
        });
        successToast(2, MESSAGE.SPONSOR_DELETED);
        resolve(true);
      })
      .catch(() => {
        errorToast(1, MESSAGE.SPONSOR_DELETED_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};
