import axios from 'axios';

import { PARTNER_API_URL } from '../constants';

/**
 * Function to get partner api list
 */
export const getPartnersApi = (search, page, offset, sortKey, sorttype) =>
  axios.get(`${PARTNER_API_URL}?search=${search || ''}&page=${page || 0}&limit=${offset || 10}${sorttype ? `&${sortKey}=${sorttype}` : ''}`);

/**
 * Function to add partner
 * @param  {Object} payload
 */
export const addPartnerApi = payload => axios.post(PARTNER_API_URL, payload);

/**
 * Function to update partner details
 * @param  {Object} payload
 */
export const updatePartnerInfoApi = (id, payload) =>
  axios.put(PARTNER_API_URL + '/' + id, payload);

/**
 * Function to delete partner details
 * @param  {Object} payload
 */
export const deletePartnerInfoApi = id => axios.delete(PARTNER_API_URL + '/' + id);
