import { beginLoaderCall, endLoaderCall } from ".";
import { forgotPasswordApi } from "../services";
import { errorToast } from "../components/blintzbox/ToastNotification";
/**
 * Action for forgot user password
 * @param  {Object} payload
 * @param  {Object} history
 */
export const forgotPassword = (email) => (dispatch) => {
  dispatch(beginLoaderCall());
  return forgotPasswordApi(email)
    .then(() => {
      dispatch(endLoaderCall());
      return true;
    })
    .catch((error) => {
      dispatch(endLoaderCall());
      errorToast(15, "Email id does not exist");
    });
};
