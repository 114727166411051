import React, { Component } from "react";

const AmenitiesHoc = (WrapperComponent) => {
  class AmenitiesParent extends Component {
    render() {
      return <></>;
    }
  }

  return AmenitiesParent;
};

export default AmenitiesHoc;
