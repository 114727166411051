import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import EyeImg from "../../assets/images/eye.png";
import * as actions from "../../actions";
import changeImg from "../../assets/images/change-password-lock.svg";
import {
  getClassName,
  passwordClass,
  getHtmlClass,
  MESSAGE,
  passRegex,
} from "../../constants";
import { errorToast } from "./ToastNotification";

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classStatus: passwordClass,
      passwordData: null,
      passwordType: {
        oldPassword: "password",
        confirmNewPassword: "password",
        newPassword: "password",
      },
    };
    this.initialState = this.state;
  }
  updateField = (e) => {
    let { name, value } = e.target;
    this.setState((prevState) => ({
      passwordData: { ...prevState["passwordData"], [name]: value },
    }));
  };

  updateClassName = async (e) => {
    const newName = e.target.name;
    let { setAttrName, attriName } = this.props;
    let { passwordData, classStatus } = this.state;
    if (newName !== attriName) {
      setAttrName(newName);
      let data = getHtmlClass(passwordData, classStatus, attriName, newName);
      await this.setState((prevState) => ({
        classStatus: { ...prevState["classStatus"], data },
      }));
    }
  };

  handleUpdatePassword = () => {
    if (this.state.passwordData) {
      let {
        oldPassword,
        confirmNewPassword,
        newPassword,
      } = this.state.passwordData;
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        errorToast(7, MESSAGE.EMPTY_FIELDS);
      } else if (
        oldPassword &&
        confirmNewPassword &&
        newPassword !== confirmNewPassword
      ) {
        errorToast(5, MESSAGE.INVALID_PASS);
      } else if (
        oldPassword &&
        confirmNewPassword &&
        newPassword === confirmNewPassword &&
        !passRegex.test(confirmNewPassword)
      ) {
        errorToast(5, MESSAGE.CORECT_PASS);
      } else if (
        oldPassword &&
        newPassword &&
        confirmNewPassword &&
        newPassword === confirmNewPassword &&
        passRegex.test(confirmNewPassword)
      ) {
        let data = {
          password: oldPassword,
          newPassword: confirmNewPassword,
        };
        this.props.updatePassword(data);
      }
    } else errorToast(7, MESSAGE.EMPTY_FIELDS);
  };

  updatePasswordType = (e) => {
    if (this.state.passwordType[e] === "text")
      this.setState((prevState) => ({
        passwordType: { ...prevState.passwordType, [e]: "password" },
      }));
    else
      this.setState((prevState) => ({
        passwordType: { ...prevState.passwordType, [e]: "text" },
      }));
  };

  handleClose = () => {
    this.setState(this.initialState);
    this.props.openChangePassword();
  };

  render() {
    let { classStatus, passwordType } = this.state;
    return (
      <Modal
        show={this.props.hideShowModal}
        className="form-modal change-password-modal"
        onHide={this.handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={changeImg} alt="change-password-lock" />
            <h3 className="change-password-heading">Change Password</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div
              className={`floating-input ${getClassName(
                classStatus.oldPassword.status,
                null,
                classStatus.oldPassword.isFilled
              )}`}
            >
              <label for="oldPassword">Current Password</label>
              <input
                type={passwordType.oldPassword}
                name="oldPassword"
                onChange={(e) => this.updateField(e)}
                onClick={(e) => this.updateClassName(e)}
                className="form-control password-input"
              />
            </div>
            <img
              className="input-icon"
              onClick={() => this.updatePasswordType("oldPassword")}
              src={EyeImg}
              alt="password icon"
            />
          </div>
          <div className="form-group">
            <div
              className={`floating-input ${getClassName(
                classStatus.newPassword.status,
                null,
                classStatus.newPassword.isFilled
              )}`}
            >
              <label for="newPassword">New Password</label>
              <input
                type={passwordType.newPassword}
                name="newPassword"
                onChange={(e) => this.updateField(e)}
                onClick={(e) => this.updateClassName(e)}
                className="form-control password-input"
                id="newPassword"
              />
            </div>
            <img
              className="input-icon"
              onClick={() => this.updatePasswordType("newPassword")}
              src={EyeImg}
              alt="password icon"
            />
          </div>
          <div className="form-group">
            <div
              className={`floating-input ${getClassName(
                classStatus.confirmNewPassword.status,
                null,
                classStatus.confirmNewPassword.isFilled
              )}`}
            >
              <label for="confirmNewPassword">Confirm New Password</label>
              <input
                type={passwordType.confirmNewPassword}
                name="confirmNewPassword"
                onChange={(e) => this.updateField(e)}
                onClick={(e) => this.updateClassName(e)}
                className="form-control password-input"
              />
            </div>
            <img
              className="input-icon"
              onClick={() => this.updatePasswordType("confirmNewPassword")}
              src={EyeImg}
              alt="password icon"
            />
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button
            className="btn secondary-btn primary-main"
            data-dismiss="modal"
            onClick={() => this.handleClose()}
          >
            Cancel
          </button>
          <button
            className="btn primary-btn primary-main btn-right-gap"
            onClick={() => {
              this.handleUpdatePassword();
            }}
          >
            Update
          </button>{" "}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  attriName: state.attriName.attriName,
});

export default connect(mapStateToProps, actions)(ChangePasswordModal);
