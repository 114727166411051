import { BEGIN_LOADER_CALL, END_LOADER_CALL } from '../actions';

export const loaderStatus = (state = { loading: false }, action = {}) => {
    const { type, loading } = action;
    switch (type) {
        case BEGIN_LOADER_CALL:
            return { ...state, loading: loading };
        case END_LOADER_CALL:
            return { ...state, loading: loading };
        default:
            return state;
    }
};