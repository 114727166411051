import React from 'react';
import { Modal } from 'react-bootstrap';

function ChangeStatusMdal({
  heading,
  addRemoveStatusModal,
  changeStatus,
  changeStatusData,
  openCancelModal,
  isInside,
  bookingDetailsHeader,
  action
}) {
  return (
    <Modal show={addRemoveStatusModal} className='delete-modal' centered>
      <Modal.Body>
        <h3 className='delete-modal-heading'>Change Status
        </h3>
         <p className='delete-modal-text'>
                Are you sure you want to change the status?
          </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn secondary-btn primary-main'
          data-dismiss='modal'
          onClick={() => changeStatus(true, changeStatusData)}
        >
          Yes
        </button>
        <button
          className='btn primary-btn primary-main btn-right-gap'
          onClick={() => changeStatus(false, changeStatusData)}
        >
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeStatusMdal;
