import React, { Fragment } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import NavigationBar from "../components/navigationBar/NavigationBar";
import PricingPlanHoc from "../container/PricingPlanHoc";
import BlintzboxHoc from "../container/BlintzboxHoc";
import AmenitiesHoc from "../container/AmenitiesHoc";
import LocationHoc from "../container/LocationHoc";
import SeasonalPromoCodeHoc from "../container/SeasonalPromoCodeHoc";
import RightPanel from "../components/rightPanel";
import Header from "../components/header/Header";
import PartnerHoc from "../container/PartnerHoc";
import SponsorHoc from "../container/SponsorHoc";
import UserHoc from "../container/UserHoc";
import BookingHoc from "../container/BookingHoc";
import * as constants from "../constants";
import { getItem } from "../utils";
import QrCode from "../components/qrCode/QrCode";
import PartnerApiHoc from "../container/PartnerApiHoc";
import DashboardHoc from "../container/DashboardHoc";
import MenuHoc from "../container/MenuHoc";
import pastOrders from "../container/pastOrders";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = getItem(constants.AUTH_TOKEN);
  if (isAuthenticated) {
    return (
      <Fragment>
        <Header />
        <div className="admin-main-wrapper">
          <NavigationBar />
          <div className="admin-right-wrapper">
            <Route {...rest} render={(props) => <Component {...props} />} />
          </div>
        </div>
      </Fragment>
    );
  }
  return <Redirect to={constants.SIGN_IN} />;
};

const adminRoutes = () => (
  <Switch>
    <PrivateRoute
      exact
      path={constants.DASHBOARD_ROUTE}
      component={DashboardHoc(RightPanel)}
    />
    <PrivateRoute
      exact
      path={constants.BLINTZBOX_ROUTE}
      component={BlintzboxHoc}
    />
    <PrivateRoute exact path={constants.MENU_ROUTE} component={MenuHoc} />
    <PrivateRoute
      exact
      path={constants.USER_ROUTE}
      component={UserHoc(RightPanel)}
    />
    {/* <PrivateRoute
      exact
      path={constants.MENU_ROUTE}
      component={MenuHoc(RightPanel)}
    /> */}
    <PrivateRoute
      exact
      path={constants.SPONSORS_ROUTE}
      component={SponsorHoc(RightPanel)}
    />
    <PrivateRoute
      exact
      path={constants.PRICING_ROUTE}
      component={PricingPlanHoc(RightPanel)}
    />
    <PrivateRoute
      exact
      path={constants.PARTNER_ROUTE}
      component={PartnerHoc(RightPanel)}
    />
    <PrivateRoute
      exact
      path={constants.AMENITIES_ROUTE}
      component={AmenitiesHoc(RightPanel)}
    />
    <PrivateRoute
      exact
      path={constants.LOCATION_ROUTE}
      component={LocationHoc(RightPanel)}
    />
    <PrivateRoute
      exact
      path={constants.BOOKING_ROUTE}
      component={BookingHoc(RightPanel)}
    />
    <PrivateRoute exact path={constants.ORDER_ROUTE} component={pastOrders} />
    <PrivateRoute
      exact
      path={constants.SEASONAL_PROMO_CODE_ROUTE}
      component={SeasonalPromoCodeHoc}
    />
    <PrivateRoute
      exact
      path={constants.PARTNERS_API_ROUTE}
      component={PartnerApiHoc(RightPanel)}
    />
    <PrivateRoute exact path={constants.QR_ROUTE} component={QrCode} />
    <Redirect to={constants.NOPAGEFOUND} />
  </Switch>
);

export default adminRoutes;
