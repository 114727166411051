import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import history from "../../store/history";
import { BOOKING_ROUTE, USER_ROUTE } from "../../constants";

const BookingTable = (props) => {
  const {
    users: { list: listUser = [] },
    commingBooking: { list: listComm = [] },
  } = props;

  const handleRedirect = (route) => {
    history.push(route);
  };

  const getDate = (value) => {
    let date = moment(value),
      dayN = date.format("D"),
      day = date.year(value, '"YYYY-MM-DD HH:mm:ss"').format("dddd"),
      month = moment(value)
        .subtract(0, "month")
        .startOf("month")
        .format("MMMM"),
      year = date.format("YYYY"),
      time = date.format("HH:mm A");
    return `${day.substring(0, 3)}, ${month.substring(
      0,
      3
    )} ${dayN} ,${year} ,${time}`;
  };
  return (
    <div className="dashboard-table-wrapper">
      <div className="col-dashboard-table-box">
        <div className="dashboard-table-box">
          <div className="dashboard-table-box-head">
            <h2>Latest Bookings</h2>
            <span onClick={() => handleRedirect(BOOKING_ROUTE)}>View All</span>
          </div>
          <div className="admin-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div className="table-heading-with-up-down">Booking ID</div>
                  </th>
                  <th>
                    <div className="table-heading-with-up-down">
                      blintzbox Name
                    </div>
                  </th>
                  <th>
                    <div className="table-heading-with-up-down">
                      Date & Time
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listComm.length > 0 ? (
                  listComm.map((ele, index) => {
                    if (index < 5) {
                      return (
                        <tr key={index}>
                          <th onClick={() => props.handleCustomer(ele, false)}>
                            <strong>{ele.transactionId}</strong>
                          </th>
                          <td>{ele.blintzbox && ele.blintzbox.name}</td>
                          <td>{getDate(ele.datetimeStart)}</td>
                        </tr>
                      );
                    } else return false;
                  })
                ) : (
                  <tr>
                    <td className="dashboard-empty-text-wrapper">
                      <div className="empty-text dashboard-page">
                        No Data Found ...
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-dashboard-table-box">
        <div className="dashboard-table-box">
          <div className="dashboard-table-box-head">
            <h2>New Customers</h2>
            <span onClick={() => handleRedirect(USER_ROUTE)}>View All</span>
          </div>
          <div className="admin-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div className="table-heading-with-up-down">Name</div>
                  </th>
                  <th>
                    <div className="table-heading-with-up-down">Email</div>
                  </th>
                  <th>
                    <div className="table-heading-with-up-down">Country</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listUser.length > 0 ? (
                  listUser.map((ele, index) => {
                    if (index < 5) {
                      return (
                        <tr key={index}>
                          <th onClick={() => props.handleCustomer(ele, true)}>
                            <strong>{ele.firstName || "N/A"}</strong>
                          </th>
                          <td>{ele.email}</td>
                          <td>
                            {(!isEmpty(ele.userAddress) &&
                              ele.userAddress.country) ||
                              "N/A"}
                          </td>
                        </tr>
                      );
                    } else return false;
                  })
                ) : (
                  <tr>
                    <td className="dashboard-empty-text-wrapper dashboard-empty-text-wrapper-2">
                      <div className="empty-text dashboard-page">
                        No Data Found ...
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  commingBooking: state.bookingList.commingBooking || {},
  users: state.userList || {},
});

export default connect(mapStateToProps, actions)(BookingTable);
