import {
  USER_DETAIL,
  USER_LIST,
  USER_DETAIL_BY_ID,
  USER_DATA_CSV,
} from "../actions";

export const user = (state = {}, action) => {
  const { type, payload } = action;
  if (type === USER_DETAIL) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};

export const userList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === USER_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const userById = (state = {}, action) => {
  const { type, payload } = action;
  if (type === USER_DETAIL_BY_ID) {
    return {
      ...state,
      ...payload,
    };
  } else {
    return state;
  }
};

export const userCSVList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === USER_DATA_CSV) {
    return payload;
  } else {
    return state;
  }
};
