import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';

class GraphHeader extends Component{
    render(){
        const {locList, startDate, endDate, handleDateChange, getFilteredList, handleSubmit} = this.props;
        return(
            <>
            <div className='custom-dropdown-with-values floating-input'>
            <Select
              options={
                locList &&
                locList.map(e => {
                  return { value: e.id, label: e.name, name: 'locationId' };
                })
              }
              defaultValue={
                {
                  value: '',
                  label: 'All Locations',
                  name: 'locationId'
                }
              }
              onChange={e => getFilteredList(e)}
              name='timezone'
              placeholder='Locations'
            />
          </div>
          <div className="form-group small-calendar-form-group">
            <DatePicker
              placeholderText='Start Date'
              selected={startDate}
              minDate={new Date(moment(endDate).subtract(1, 'year'))}
              maxDate={new Date(moment(endDate).subtract(1, 'days'))}
              className='form-control'
              onChange={e =>
                handleDateChange(
                  e,
                  'startDate'
                )
              }
            />
          </div>
          <div className="form-group small-calendar-form-group">
            <DatePicker
              placeholderText='End Date'
              selected={endDate}
              className='form-control'
              minDate={new Date(moment(startDate).add(1, 'days'))}
              maxDate={new Date()}
              onChange={e =>
                handleDateChange(
                  e,
                  'endDate',
                )
              }
            />
          </div>
          <button className="btn primary-btn btn-block graph-apply-btn" onClick={() => handleSubmit()}>Apply</button>
            </>
        )
    }
}

export default GraphHeader;