import { BOOKING_UPCOMMING, BOOKING_IMAGES, BOOKING_CURRENT, BOOKING_PAST, BOOKING_CANCELED, BOOKING_DETAIL, BOOKING_PRICE, BOOKING_CSV, BOOKING_REFUND } from '../actions';

export const bookingList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === BOOKING_UPCOMMING) {
    return {
      ...state,
      commingBooking: payload || []
    }
  } else if (type === BOOKING_PAST) {
    return {
      ...state,
      pastBooking: payload || []
    };
  } else if (type === BOOKING_CSV) {
    return {
      ...state,
      csvBooking: payload || []
    };
  } else if (type === BOOKING_CURRENT) {
    return {
      ...state,
      currentBooking: payload || []
    };
  } else if (type === BOOKING_CANCELED) {
    return {
      ...state,
      cancelBooking: payload || []
    };
  } else {
    return state;
  }
};

export const bookingDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === BOOKING_DETAIL) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};

export const bookingPriceDetails = (state = { bookingPriceDetails: null }, action) => {
  const { type, payload } = action;
  if (type === BOOKING_PRICE) {
    return { ...state, bookingPriceDetails: payload };
  } else {
    return state;
  }
};

export const refundDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === BOOKING_REFUND) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};

export const bookingImages = (state = { bookingImages: [] }, action) => {
  const { type, payload } = action;
  if (type === BOOKING_IMAGES) {
    return { ...state, bookingImages: payload };
  } else {
    return state;
  }
};
