import React from "react";
import * as constants from "../../constants";
import { handleListValue } from "../../helpers";
import Pagination from "../pagination/Pagination";
import DeleteModal from "../blintzbox/DeleteModal";
import RefundModal from "../blintzbox/RefundModal";
import CANCEL_ICON from "../../assets/images/svg/cancel.svg";
import ChangeStatusMdal from "../blintzbox/ChangeStatusMdal";

const ListWrapper = (props) => {
  const date = new Date();
  const {
    showDetails,
    headerList = [],
    data: { totalPages, totalCount, list = [], currentPage },
    displayPage,
    deleteDataDetails,
    openDeleteModal,
    addRemoveDeleteModal,
    addButton,
    getPaginationDetails,
    heading,
    tablist,
    bookingDetailsHeader,
    refundStatus,
    openCancelModal,
    action,
    refundModalStatus,
    bookingData,
    openRefundModal,
    userBooking,
    removeFooter,
    sortList,
    showStatus,
    isAmneties,
    refundDetails,
    emptyState,
    emptyRefundState,
    changeStatus,
    addRemoveStatusModal,
    openStatusModal,
    changeStatusData,
  } = props;

  const getTabList = (data) => {
    if (showStatus) {
      return refundStatus ? (
        <td>
          <span
            className="refund-text"
            onClick={() => openRefundModal(false, data)}
          >
            Refund
          </span>
        </td>
      ) : (
        <td>
          <span className="cancel-icon">
            <img
              src={CANCEL_ICON}
              alt="cancel"
              onClick={() =>
                openDeleteModal(
                  false,
                  data,
                  bookingDetailsHeader && bookingDetailsHeader[0],
                  bookingDetailsHeader && bookingDetailsHeader[1]
                )
              }
            />
          </span>
        </td>
      );
    }
  };

  const getAction = () => {
    const header =
      bookingDetailsHeader &&
      bookingDetailsHeader[0] === "PAST BOOKING" &&
      userBooking
        ? false
        : true;
    return heading === "USER MANAGEMENT" || header;
  };

  const listData = (headerList, sortList) => {
    return headerList.map((header) => (
      <th key={header.id}>
        {header.hasSortIcon ? (
          <div className="table-heading-with-up-down">
            {header.label}
            <span className="up-down-combine">
              <span>
                <img
                  src={constants.ASCENDING}
                  alt="ascending"
                  onClick={() => sortList(header.label, "ASC")}
                />
              </span>
              <span>
                <img
                  src={constants.DESCENDING}
                  alt="descending"
                  onClick={() => sortList(header.label, "DESC")}
                />
              </span>
            </span>
          </div>
        ) : (
          header.label
        )}
      </th>
    ));
  };
  const condition = (bookingDetailsHeader, userBooking) => {
    return (
      bookingDetailsHeader &&
      bookingDetailsHeader[0] === "PAST BOOKING" &&
      userBooking
    );
  };
  return (
    <>
      <DeleteModal
        heading={addButton}
        addRemoveDeleteModal={addRemoveDeleteModal}
        openDeleteModal={openDeleteModal}
        deleteDataDetails={deleteDataDetails}
        openCancelModal={openCancelModal}
        bookingDetailsHeader={bookingDetailsHeader}
        action={action}
      />
      <ChangeStatusMdal
        heading={addButton}
        addRemoveStatusModal={addRemoveStatusModal}
        changeStatus={changeStatus}
        changeStatusData={changeStatusData}
        openCancelModal={openCancelModal}
        bookingDetailsHeader={bookingDetailsHeader}
        action={action}
      />
      <RefundModal
        refundModalStatus={refundModalStatus}
        openRefundModal={openRefundModal}
        bookingData={bookingData}
        refundDetails={refundDetails}
        emptyState={emptyState}
        emptyRefundState={emptyRefundState}
      />
      <div
        className={`admin-right-data-wrapper ${
          tablist && "inner-tab-table-wrapper"
        }`}
      >
        <div
          className={`admin-table-wrapper ${
            !tablist && "blintzbox-management-table-wrapper"
          }`}
        >
          <table className="table">
            <thead>
              <tr>
                {listData(headerList, sortList)}
                {getAction() && heading !== "USER MANAGEMENT" && showStatus && (
                  <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((data) => (
                  <tr key={data.key}>
                    {handleListValue(
                      headerList,
                      data,
                      showDetails,
                      openDeleteModal
                      // bookingDetailsHeader && bookingDetailsHeader[0],
                      // isAmneties,
                      // openStatusModal,
                      // changeStatus
                    )}
                    {condition(bookingDetailsHeader, userBooking) ||
                    heading === "USER MANAGEMENT" ? (
                      false
                    ) : tablist ? (
                      getTabList(data)
                    ) : (
                      <td>
                        <span
                          className="edit-icon"
                          onClick={() => displayPage("Edit", data)}
                        >
                          <img src={constants.EDIT} alt="edit" />
                        </span>
                        <span
                          className="delete-icon"
                          onClick={() => openDeleteModal(false, data)}
                        >
                          <img src={constants.DELETE} alt="delete" />
                        </span>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="empty-text-wrapper">
                    <div className="empty-text">No Data Found ...</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {list.length > 0 && totalPages && totalPages > 1 && (
            <div className="pagination-wrapper">
              <p className="pagination-text">
                Showing{" "}
                <span>
                  {`${currentPage * 100 + 1}-${
                    (currentPage + 1) * 100 >= totalCount
                      ? totalCount
                      : (currentPage + 1) * 100
                  }`}
                </span>{" "}
                of <span>{totalCount || 0}</span>
              </p>
              <nav aria-label="..."></nav>
              <Pagination
                className="pagination"
                onChange={(page) =>
                  getPaginationDetails(
                    page,
                    bookingDetailsHeader && bookingDetailsHeader[1],
                    action
                  )
                }
                hideFirstAndLastPageLinks={true}
                hideEllipsis={true}
                currentPage={currentPage + 1 || 1}
                totalPages={totalPages || 1}
              />
            </div>
          )}
        </div>
      </div>
      {removeFooter ? (
        false
      ) : (
        <div className="admin-right-footer">
          <p className="admin-right-footer-text">
            © {date.getFullYear()} Blintz, All Rights Reserved.
          </p>
        </div>
      )}
    </>
  );
};

export default ListWrapper;
