import { beginLoaderCall, endLoaderCall } from ".";
import {
  addAmenity,
  fetchAmenitiesList,
  getAmenityInfo,
  updateAmenityInfo,
  deleteAmenityInfo,
} from "../services";
import {
  AMENITIES_LIST,
  AMENITIES_DETAILS,
  AMENITIES_CREATED,
  AMENITIES_UPDATED,
  AMENITIES_DELETED,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

export const responseCheck = (response, messageConstant) => {
  if (response.response) {
    errorToast(1, response.response.data.message);
  } else {
    errorToast(1, messageConstant);
  }
};

export const getAmenityList = (
  search,
  page,
  limit,
  sortKey,
  sortType,
  status = true
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return fetchAmenitiesList(search, page, limit, sortKey, sortType)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: AMENITIES_LIST,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for create amenity
 */

export const createAmenity = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addAmenity(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: AMENITIES_CREATED,
          payload: response,
        });
        successToast(1, MESSAGE.AMENTY_CREATE);
        resolve(true);
      })
      .catch((response) => {
        responseCheck(response, MESSAGE.AMENTY_CREATE_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for get amenity details
 */
export const getAmenityDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getAmenityInfo(id)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: AMENITIES_DETAILS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for update amenity details
 */
export const updateAmenityDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updateAmenityInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: AMENITIES_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.AMENTY_UPDATE);
        resolve(true);
      })
      .catch((response) => {
        responseCheck(response, MESSAGE.AMENTY_UPDATE_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for delete amenity details
 */
export const deleteAmenityDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    deleteAmenityInfo(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: AMENITIES_DELETED,
          payload: id,
        });
        successToast(1, MESSAGE.AMENTY_DELETE);
        resolve(true);
      })
      .catch((response) => {
        responseCheck(response, MESSAGE.AMENITY_DELETE_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};
