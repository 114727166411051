import React from "react";
import LOADER_IMAGE from '../../assets/images/Loader.gif';

export class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <img src={LOADER_IMAGE} alt='Loader' />
      </div >
    );
  }
}

export default Loader;
