import React from 'react';
import { Link } from 'react-router-dom';

import * as constants from '../constants';
import { TextBox } from './input';
import Banner from './Banner';

export const Login = props => {
  const {
    fields,
    errors,
    showPassword,
    handleOnBlur,
    handleSubmit,
    handleOnChange,
    passwordToggling
  } = props;
  return (
    <div className='signin-wrapper'>
      <div className='main-container signup-section'>
        <div className='signin-container'>
          <Banner />
          <div className='login-container'>
            <div className='heading-wrap'>
              <h1>Sign in to account</h1>
            </div>
            <div className='form-group'>
              <TextBox
                name='email'
                label='Email'
                className='form-control'
                autoFocus={true}
                value={fields.email || ''}
                error={errors.email}
                onBlur={event => handleOnBlur(event)}
                onChange={event => handleOnChange(event)}
              />
              <span className='error'>{errors.email}</span>
            </div>

            <div className='form-group'>
              <TextBox
                type='password'
                name='password'
                id='password'
                label='Password'
                maxLength='25'
                value={fields.password || ''}
                className='form-control password-input '
                error={errors.password}
                onBlur={event => handleOnBlur(event)}
                onChange={event => handleOnChange(event)}
              />
              <img
                className='input-icon'
                name='password'
                src={!showPassword ? constants.EYE_ICON : constants.EYE_CLOSED}
                onClick={event => passwordToggling(event)}
                alt='password icon'
              />
              <span className='error'>{errors.password}</span>
            </div>

            <div className='form-action btn-text-group form-action-mb'>
              <button
                id='submitCall'
                className=' btn primary-btn btn-block'
                onClick={() => handleSubmit()}
              >
                Sign In
              </button>
              <Link
                className='forgot-text'
                to={constants.FORGOT_PASSWORD}
                title='Forgot Password?'
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
