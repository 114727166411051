import { MENU_IMAGES } from "../actions";

export const menuImagesReducer = (state = {}, action) => {
  const { type, payload } = action;
  if (type === MENU_IMAGES) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
