import axios from 'axios';

import { SPONSORS_URL } from '../constants';

/**
 * Function to get sponsors list
 */
export const fetchSponsorList = (search, page, offset, sortKey, sorttype) =>
  axios.get(`${SPONSORS_URL}?search=${search || ''}&page=${page || 0}&limit=${offset || 10}${sortKey ? `&${sortKey}=${sorttype}` : ''}`);

/**
 * Function to get sponsors details
 * @param  {Object} payload
 */
export const getSponsorInfo = id => axios.get(SPONSORS_URL + '/' + id);

/**
 * Function to add sponsors
 * @param  {Object} payload
 */
export const addSponsor = payload => axios.post(SPONSORS_URL, payload);

/**
 * Function to update sponsors details
 * @param  {Object} payload
 */
export const updateSponsorInfo = (id, payload) =>
  axios.put(SPONSORS_URL + '/' + id, payload);

/**
 * Function to delete sponsors details
 * @param  {Object} payload
 */
export const deleteSponsorInfo = id => axios.delete(SPONSORS_URL + '/' + id);
