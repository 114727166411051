import React from 'react';
import * as constants from '../../constants';

export const SearchInput = ({
  searchListDetails,
  numberType,
  searchListName,
  searchedValue
}) => {
  return (
    <div className='form-group search-form-group'>
      <input
        type={numberType}
        className='form-control'
        placeholder='Search by keywords'
        onChange={e => searchListDetails(e.target.value, searchListName)}
        value={searchedValue || ''}
      />
      <span className='search-icon'>
        <img src={constants.SEARCH} alt='search-icon' />
      </span>
    </div>
  );
};
