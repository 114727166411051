import React from "react";
import { SearchInput } from "../../input";
import CsvButton from "../../input/CsvButton";
import Select from "react-select";

const Filters = (props) => {
  const {
    addButton,
    isCsvExport,
    displayPage,
    numberType,
    searchListDetails,
    tablist,
    bookingDetailsHeader,
    searchedValue,
    locations,
    isLocationFilter,
    getFilteredList,
    isPartnerApiFilter,
    partnersAPi,
    action,
    isPartnerApi,
  } = props;

  const getOptions = (dataList, key) => {
    let data = [];
    dataList.list &&
      dataList.list.forEach((e, i) => {
        if (i === 0) data.push({ value: "", label: "All", name: key });
        data.push({ value: e.id, label: e.name, name: key });
      });
    return data;
  };
  return (
    <div
      className={`admin-right-header-bottom ${
        tablist ? "inner-tab-header-bottom" : ""
      }`}
    >
      <SearchInput
        searchListDetails={searchListDetails}
        numberType={numberType}
        searchListName={bookingDetailsHeader && bookingDetailsHeader[1]}
        searchedValue={searchedValue}
      />
      <div className="admin-right-header-bottom-right">
        {isPartnerApiFilter && (
          <div className="custom-react-dropdown">
            <Select
              options={getOptions(partnersAPi, "businessPartnerId")}
              onChange={(e) =>
                getFilteredList(
                  e,
                  bookingDetailsHeader && bookingDetailsHeader[1],
                  action
                )
              }
              name="timezone"
              className="basic-multi-select"
              placeholder="Partners Api"
            />
          </div>
        )}
        {isLocationFilter && (
          <div className="custom-react-dropdown">
            <Select
              options={getOptions(locations, "locationId")}
              onChange={(e) =>
                getFilteredList(
                  e,
                  bookingDetailsHeader && bookingDetailsHeader[1],
                  action
                )
              }
              name="timezone"
              className="basic-multi-select"
              placeholder="Locations"
            />
          </div>
        )}
        {isPartnerApi && (
          <button
            className="btn primary-btn btn-block"
            onClick={() => {
              window.open("https://api.blintzbox.site/api/v2/api-docs/#");
            }}
          >
            API Document{" "}
          </button>
        )}
        {addButton && (
          <button
            className="btn primary-btn btn-block add-partner-api-btn"
            onClick={() => displayPage()}
          >
            Add {addButton}
          </button>
        )}
        {isCsvExport && <CsvButton {...props} />}
      </div>
    </div>
  );
};

export default Filters;
