import React, { Fragment, useEffect, useState } from "react";
import Header from "../../rightPanel/Header";
import Filters from "../../rightPanel/filters";
import ListWrapper from "../../listWrapper";
import firebase from "../../../config/FirebaseConfig";
import { useDispatch } from "react-redux";
import { AssemblyContainer } from "./AssemblyContainer";
import { firestore } from "firebase";

const AssemlbyContainer = (props) => {
  const { heading, tablist, data, listOrders, containerType } = props;

  const handleClick = async (key, liveStatus) => {
    let status = null;
    if (liveStatus === "BAKING") {
      status = "PACKED";
    } else {
      return;
    }
    console.log("key", key, status);
    if (status === null) {
      return;
    }
    try {
      await firebase.firestore().collection("orders").doc(key).update({
        liveStatus: status,
      });
    } catch (e) {
      console.log("ERROR IN SAVE", e);
    }
  };

  return (
    <Fragment>
      <Header heading={heading} data={data} />
      <div className="row" style={{ marginTop: "20px" }}>
        {listOrders.map((item, index) => {
          const key = "assembly_" + item.cellKey + (index + Math.random());
          return (
            <AssemblyContainer
              containerType={containerType}
              handleClick={handleClick}
              order={item}
              key={key}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default AssemlbyContainer;
