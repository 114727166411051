import {
  DASHBOARD_PANEL_REPORT,
  DASHBOARD_ORDER_COUNT,
  BLINTZBOX_STATICS,
  REVENUE_STATICS,
  DASHBOARD_CUSTOMER_COUNT,
} from "../actions";

export const panelReportList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === DASHBOARD_PANEL_REPORT) {
    return payload;
  } else {
    return state;
  }
};

export const totalOrderCount = (state = 0, action) => {
  const { type } = action;
  if (type === DASHBOARD_ORDER_COUNT) {
    return action.data;
  } else {
    return state;
  }
};

export const totalCustomers = (state = 0, action) => {
  const { type } = action;
  if (type === DASHBOARD_CUSTOMER_COUNT) {
    return action.data;
  } else {
    return state;
  }
};

export const blintzbox_statics = (state = [], action) => {
  const { type, payload } = action;
  if (type === BLINTZBOX_STATICS) {
    return payload;
  } else {
    return state;
  }
};

export const revenue_statics = (state = [], action) => {
  const { type, payload } = action;
  if (type === REVENUE_STATICS) {
    return payload;
  } else {
    return state;
  }
};
