import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as constants from '../constants';
import moment from 'moment';
import * as actions from '../actions';
import { connect } from 'react-redux';

class PromoCode extends Component {
  constructor(props) {
  super(props);
    this.state = {
     name : "",
     amount : "",
     startDate: "",
     endDate: "",
     maxUseLimit : "",
     id : "",
     active : false,
     isEditable : false,
     clickName: false,
     clickAmount: false,
     clickDate: false,
     clickEndDate : false,
     clickLimit: false,
     classStatus: {...constants.promoCodeClass}
   }
}

componentDidMount = async () => {
  const { getPromoCodeDetail} = this.props;
  await getPromoCodeDetail();
  const { promodetail } = this.props;
  const details = promodetail && promodetail.list[0]
  this.setState({
    name : details && details.name,
    amount : details && details.amount,
    endDate : details && moment(details.expiredAt).toDate("MM/DD/YYYY"),
    startDate : details && moment(details.fromDate).toDate("MM/DD/YYYY"),
    maxUseLimit : details && details.maxUseLimit,
    active : details && details.isActive,
    id : details && details.id
  }) 
};

checkPromoActive() {
  this.setState({
   active : !this.state.active 
  })
} 

updatePromo = (e) => {
  e.preventDefault();
  this.setState({isEditable : !this.state.isEditable})
  const { name , amount , endDate , maxUseLimit , active , startDate , id} = this.state;
  const newAmount = amount.toString().replace('$ ', '');
  const newMaxLimit = maxUseLimit;
  const data = {
   name,
   amount: newAmount,
   fromDate : moment(startDate).utc().format(),
   expiredAt: moment(endDate).utc().format(),
   maxUseLimit: newMaxLimit === '' ? 0 : parseInt(newMaxLimit),
   isActive : active
    };
  {this.state.isEditable &&
   this.props.editPromoCodeDetail(id ,data);
  }
};

updateClassName = async (e) => {
  const newName = e.target.name;
  let { setAttrName, attriName } = this.props;
  const { classStatus, name, amount, startDate, endDate , maxUseLimit } = this.state;
  let promoData = {
    clickName: name,
    clickAmount: amount,
    clickDate: startDate,
    clickEndDate: endDate,
    clickLimit: maxUseLimit
  }
  if (newName !== attriName && this.state.isEditable ) {
    setAttrName(newName);
    let data = constants.getHtmlClass(promoData, classStatus, attriName, newName)
    await this.setState(prevState => ({
      classStatus: { ...prevState['classStatus'], data }
    }));
  }
};

assignValue = (field, newValue) => {
 let valueReturn = '';
  switch (field) {
   case 'name':
    valueReturn = newValue.toUpperCase();
    break;
   case 'amount':
     if (newValue.length > 0) valueReturn = '$ ' + newValue;
     else valueReturn = newValue;
     break;
     default:
      valueReturn = newValue
    }
    return valueReturn;
  };

  handleReqexCheck = (regex, newValue, field) => {
 let value = '';
  if (
    (newValue.length > 0 && regex.test(newValue)) ||
     newValue.length === 0
    ) {
      value = this.assignValue(field, newValue);
    } else {
      value = this.state[field];
    }
    return value;
  };

handleChange = (e, field) => {
  let value = '';
  if (field === 'name') {
    const newValue = e.target.value;
    value = this.handleReqexCheck(constants.promocodeReqex, newValue, field);
  }  else if (field === 'amount') {
    const newValue = e.target.value.replace('$ ', '');
    value = this.handleReqexCheck(constants.discountRegex, newValue, field);}
      else {
    const upcomingValue = e.target.value;
    const newValue = upcomingValue
    value = this.handleReqexCheck(constants.maxLimitRegex, newValue, field);
  }
  this.setState({
        [field]: value
      })
};

handleAmount=(e ) => {
  const value = e.target.value.replace('$ ', '');
    if (parseInt(value) >= 0 || value === '') {
      if (constants.discountRegex.test(value)) {
        this.setState({
          amount: (value.length > 0 ? '$ ' : '') + value
        });
      }
    }
  };

handleStartDateChange = date => {
    this.setState({
     startDate: date
    });
   };
   
handleEndDateChange = date => {
    this.setState({
     endDate: date
    });
   };
   
render() {
const { active , classStatus} = this.state;
  return (
     <div className="col-md-6">
        <div className="outer-box-wrapper credit">
          <div className="box-header d-flex align-items-center justify-content-between">
            <h3 className="mb-0">Promo Code</h3>
            <div className="outerDivFull d-flex align-items-center">
            <span className={active ? "active-toggle mr-3" : "inactive-toggle mr-3"}>
              {active ? `Active` : `Inactive`}</span>
              <div className="switchToggle">
                  <input type="checkbox" id="switch1" checked={active}
                   disabled={!this.state.isEditable} onClick={() =>this.checkPromoActive()}/>
                  <label for="switch1">Toggle</label>
              </div>
            </div>
          </div>
          <div className="promo-form">
            <form action="">
              <div class="form-group">
              <div className={`floating-input ${constants.getClassName(
                classStatus.clickName.status,
                null, classStatus.clickName.isFilled
              )}`}>
                   <label>Promo Code</label>
                   <input type="text"
                   placeholder="Promo Code"
                   class="form-control" 
                   name='clickName' 
                   readOnly={!this.state.isEditable}
                   value={this.state.name}
                   onChange={e => this.handleChange(e, 'name')}
                   onFocus={e => this.updateClassName(e)}
                  />
                </div>
              </div>
              <div class="form-group">
              <div className={`floating-input edit-floating-input ${constants.getClassName(
                classStatus.clickAmount.status,
                null, classStatus.clickAmount.isFilled
              )}`}>
                  <label>Amount</label>
                  <input type="text"
                  placeholder="Amount"
                  class="form-control"
                  name='clickAmount'
                  readOnly={!this.state.isEditable}
                  value={this.state.amount}
                  onChange={e => this.handleAmount(e)}
                  onFocus={e => this.updateClassName(e)}
                />
                </div>
                </div>
             <div class="form-group small-calendar-form-group">
             <div className={`floating-input edit-floating-input ${constants.getClassName(
                classStatus.clickDate.status,
                null, classStatus.clickDate.isFilled
              )}`}
            >     
                <label>From Date</label>
                <DatePicker
                placeholderText="From Date"
                dateFormat='MM/dd/yyyy'
                name='clickDate'
                readOnly={!this.state.isEditable}
                className='form-control custom-date-picker'
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                onFocus={e => this.updateClassName(e)}
                minDate={new Date()}
              />
                </div>
              </div>
              <div class="form-group small-calendar-form-group">
              <div className={`floating-input edit-floating-input ${constants.getClassName(
                classStatus.clickEndDate.status,
                null, classStatus.clickEndDate.isFilled
              )}`}
            >
                <label>To Date</label>
                <DatePicker
                placeholderText="To Date"
                dateFormat='MM/dd/yyyy'
                name='clickEndDate'
                readOnly={!this.state.isEditable}
                className='form-control custom-date-picker'
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
               onFocus={e => this.updateClassName(e)}
               />
               </div>
              </div>
              <div class="form-group">
              <div
              className={`floating-input edit-floating-input ${constants.getClassName(
                classStatus.clickLimit.status,
                null, classStatus.clickLimit.isFilled
              )}`}
            >            
                <label>Number of Redeem</label>
                <input type="text"
                class="form-control" 
                placeholder="Number of Redeem"
                name='clickLimit'
                readOnly={!this.state.isEditable}
                value={this.state.maxUseLimit}
                onChange={e => this.handleChange(e, 'maxUseLimit')}
                onFocus={e => this.updateClassName(e)}
                />
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="action-btn">
                <button className="btn primary-btn"
                onClick={(e) => this.updatePromo(e)}>{!this.state.isEditable ? `Edit` : `Save`}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
       )
    }
}

const mapStateToProps = state => ({
  promodetail : state.seasonalpromo.promodetail,
  userDetail: state.user,
  attriName: state.attriName.attriName,
});

export default connect(mapStateToProps, actions)(PromoCode);
