import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ForgotPassword from "../components/password/ForgotPassword";
import Success from "../components/success/Success";
import LoginHoc from "../container/LoginHoc";
import * as constants from "../constants";
import AdminRoutes from "./adminRoutes";
import NoPageFound from "../components/noPageFound/NoPageFound";

const Routes = () => (
  <Switch>
    <Route exact path={constants.SIGN_IN} component={LoginHoc} />
    <Route exact path={constants.FORGOT_PASSWORD} component={ForgotPassword} />
    <Route exact path={constants.SUCCESS} component={Success} />
    <Route exact path={constants.NOPAGEFOUND} component={NoPageFound} />
    <Route path={constants.ADMIN_ROUTE} component={AdminRoutes} />
    <Redirect to={constants.NOPAGEFOUND} />
  </Switch>
);

export default Routes;
