/*eslint-disable*/
import React from 'react';
import {
  createUltimatePagination,
  ITEM_TYPES
} from 'react-ultimate-pagination';

const WrapperComponent = ({ children }) => (
  <div className='pagination'>{children}</div>
);

const withPreventDefault = fn => event => {
  event.preventDefault();
  fn();
};

const Ellipsis = ({ onClick }) => (
  <a className='page-link' onClick={withPreventDefault(onClick)}>
    ...
  </a>
);

const Page = ({ value, isActive, onClick }) => (
  <a
    className={`${isActive && 'active'}`}
    onClick={withPreventDefault(onClick)}
  >
    {value}
  </a>
);

const PreviousPageLink = ({ onClick }) => (
  <a className='prev' onClick={withPreventDefault(onClick)}>
    Previous
  </a>
);

const NextPageLink = ({ onClick }) => (
  <a className='next' onClick={withPreventDefault(onClick)}>
    Next
  </a>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink
};

const Pagination = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent
});

export default Pagination;
