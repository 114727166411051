import axios from 'axios';

import { LOCATION_URL, COUNTRIES, STATES, CITIES } from '../constants';

/**
 * Function to get location list
 */
export const getLocations = (search, page, offset, searchKey, searchValue) =>
  axios.get(`/admin${LOCATION_URL}?search=${search}&page=${page}&limit=${offset}${searchKey ? `&${searchKey}=${searchValue}` : ''}`);

/**
 * Function to add location
 * @param  {Object} payload
 */
export const addLocationInfo = payload => axios.post(LOCATION_URL, payload);

/**
 * Function to get location details
 * @param  {integer} location id
 */
export const getLocationInfo = id => axios.get(LOCATION_URL + '/' + id);

/**
 * Function to update location details
 * @param  {Object} payload
 */
export const updateLocationInfo = (id, payload) =>
  axios.put(LOCATION_URL + '/' + id, payload);

/**
 * Function to delete location details
 * @param  {integer} id
 */
export const deleteLocationInfo = id => axios.delete(LOCATION_URL + '/' + id);

/**
 * Function to add location
 * @param  {Object} payload
 */
export const getLatLongOfAddressApi = address =>
  axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyB9f7-j0n4pOD2SQjLJEmIX40PfxwIZSzo`
  );

/**
 * Function to add location
 * @param  {Object} payload
 */
export const getCountriesApi = (search, page, offset) =>
  axios.get(`${COUNTRIES}?search=${search}&page=${page}&limit=${offset}`);

/**
* Function to add location
* @param  {Object} payload
*/
export const getStatesApi = (id, search, page, offset) =>
  axios.get(`${STATES}?id=${id}&page=${page}&limit=${offset}`);
/**
 * Function to add location
 * @param  {Object} payload
 */
export const getCitiesApi = (id, search, page, offset) =>
  axios.get(`${CITIES}?id=${id}&page=${page}&limit=${offset}`);