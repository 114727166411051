import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { SPONSOR_HEADERS, getPageNumber, SPONSORS_ROUTE } from "../constants";
import * as actions from "../actions";
import Header from "../components/rightPanel/Header";
import AddEditSponsorModal from "../components/blintzbox/AddEditSponsorModal";
import SponsorDetail from "../components/blintzbox/SponsorDetail";
import Loader from "../components/loader/Loader";

const SponsorHoc = (WrapperComponent) => {
  class SponsorParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        addSponsorboxStatus: false,
        sponsorData: null,
        editSponsorStatus: false,
        addRemoveDeleteModal: false,
        sponsorDetailId: "",
        sortKey: null,
        sortType: null,
      };
      this.initialState = this.state;
      this.props.setPaginationPage(0);
    }

    componentDidMount = async () => {
      const { getSponsorList } = this.props;
      if (this.props.location.state) {
        await this.setState({
          searchedValue: this.props.location.state.search,
        });
      }
      getSponsorList(this.state.searchedValue);
      this.props.setPaginationPage(0);
    };

    async showDetails(data) {
      await this.setState({ sponsorData: data });
      this.setState({ sponsorDetailId: data.id });
    }
    sortSponsorList = (header, type) => {
      const { searchedValue } = this.state;
      const { getSponsorList } = this.props;
      const headerKey = "sortByName";
      this.setState({ sortKey: headerKey, sortType: type });
      getSponsorList(searchedValue, 0, 10, headerKey, type);
    };
    // open delete sponsor modal
    deleteSponsorPage = async (status, data, deleteStatus) => {
      let {
          searchedValue,
          addRemoveDeleteModal,
          sortKey,
          sortType,
        } = this.state,
        { sponsors, paginationPage } = this.props;
      if (status) {
        await this.props.deleteSponsorDetails(data.id);
        await this.props.getSponsorList(
          searchedValue,
          getPageNumber(sponsors.totalCount, paginationPage),
          10,
          sortKey,
          sortType
        );
        if (deleteStatus) this.emptyState();
      } else await this.setState({ sponsorData: data });
      await this.setState({ addRemoveDeleteModal: !addRemoveDeleteModal });
    };

    addSponsorboxPage = async (status, data) => {
      if (status === "Edit") {
        await this.setState({ sponsorData: data, editSponsorStatus: true });
      }
      await this.setState({
        addSponsorboxStatus: !this.state.addSponsorboxStatus,
      });
    };

    getSponsorDetails = async (page) => {
      const { sortKey, sortType } = this.state;
      await this.props.setPaginationPage(page - 1);
      this.props.getSponsorList(
        this.state.searchedValue,
        page - 1,
        10,
        sortKey,
        sortType
      );
    };

    //searchSponsor list
    searchSponsor = (value) => {
      const { sortKey, sortType } = this.state;
      this.setState({ searchedValue: value });
      setTimeout(
        () => this.props.getSponsorList(value, 0, 10, sortKey, sortType, false),
        300
      );
    };

    deleteDetail = () => {
      const { searchedValue, sortKey, sortType } = this.state,
        { sponsors, paginationPage } = this.props;
      this.props.getSponsorList(
        searchedValue,
        getPageNumber(sponsors.totalCount, paginationPage),
        10,
        sortKey,
        sortType
      );
    };

    emptyState = async (status) => {
      if (status) await this.setState({ searchedValue: "" });
      await this.setState(this.initialState);
    };

    render() {
      const {
          sponsors,
          createSponsor,
          updateSponsorDetails,
          getSponsorList,
          paginationPage,
          loading,
        } = this.props,
        {
          addSponsorboxStatus,
          sponsorData,
          editSponsorStatus,
          addRemoveDeleteModal,
          searchedValue,
          sponsorDetailId,
        } = this.state;
      return (
        <>
          {addSponsorboxStatus ? (
            <Fragment>
              <Header
                heading="SPONSORS"
                routeLink={SPONSORS_ROUTE}
                subHeading={editSponsorStatus ? "Edit Sponsor" : "Add Sponsor"}
                searchedValue={searchedValue}
              />
              <AddEditSponsorModal
                sponsors={sponsorData} // sponsor row value to be edited
                createSponsor={createSponsor}
                editSponsorStatus={editSponsorStatus}
                addSponsorboxPage={this.addSponsorboxPage}
                updateSponsorDetails={updateSponsorDetails}
                paginationPage={paginationPage}
                getSponsorList={getSponsorList}
                emptyState={this.emptyState}
              />
            </Fragment>
          ) : (
            false
          )}
          {!sponsorDetailId && !addSponsorboxStatus && (
            <WrapperComponent
              heading="SPONSORS"
              addButton="Sponsor"
              data={sponsors} // sponsor list
              headerList={SPONSOR_HEADERS}
              deleteDataDetails={sponsorData} //sponsor row value  to be deteled
              addRemoveDeleteModal={addRemoveDeleteModal}
              paginationPage={paginationPage}
              searchedValue={searchedValue} // searched value
              searchListDetails={this.searchSponsor} // search sponsor list
              openDeleteModal={this.deleteSponsorPage} // open delete sponsor modalin sponsor list
              displayPage={this.addSponsorboxPage}
              getPaginationDetails={this.getSponsorDetails}
              showDetails={this.showDetails.bind(this)}
              numberType="text"
              sortList={this.sortSponsorList}
              showStatus={true}
            />
          )}
          {sponsorDetailId && sponsorData && (
            <Fragment>
              <Header
                heading="SPONSORS"
                routeLink={SPONSORS_ROUTE}
                subHeading={sponsorData.name}
                searchedValue={searchedValue}
              />
              <SponsorDetail
                heading="SPONSORS"
                details={sponsorData}
                deleteDetail={this.deleteDetail}
                emptyState={this.emptyState}
                deleteSponsorDetails={this.props.deleteSponsorDetails}
                addSponsorboxPage={this.addSponsorboxPage}
              />
            </Fragment>
          )}
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    sponsors: state.sponsorList,
    blintzboxDetail: state.blintzboxDetails,
    paginationPage: state.paginationPage.paginationPage,
    loading: state.loaderStatus.loading,
  });

  return connect(mapStateToProps, actions)(SponsorParent);
};

export default SponsorHoc;
