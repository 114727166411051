import React, { Component } from "react";
import { connect } from "react-redux";
import { PARTNERS_HEADERS, getPageNumber } from "../constants";
import * as actions from "../actions";
import AddEditPartnerModal from "../components/blintzbox/AddEditPartnerModal";
import Loader from "../components/loader/Loader";

const PartnerHoc = (WrapperComponent) => {
  class PartnerParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        addPartnerHocStatus: false,
        addRemoveDeleteModal: false,
        editPartnerStatus: false,
        partnerData: null,
        scrolledList: [],
        searchedValue: "",
        sortKey: null,
        sortType: null,
      };
      this.initialState = this.state;
      this.props.setPaginationPage(0);
    }

    async componentDidMount() {
      const { getPartnerList, getPricingList } = this.props;
      await getPartnerList();
      if (this.props.location.state) {
        await this.setState({
          searchedValue: this.props.location.state.search,
        });
      }
      await getPricingList(this.state.searchedValue, 0, 50);
      this.setState({ scrolledList: this.props.plans.list });
    }
    sortPartnerList = (header, type) => {
      let headerKey;
      if (header === "Name") {
        headerKey = "sortByName";
      } else {
        headerKey = "sortByPromo";
      }
      this.setState({
        sortKey: headerKey,
        sortType: type,
      });
      this.props.getPartnerList(
        this.state.searchedValue,
        0,
        10,
        headerKey,
        type
      );
    };
    getPartnerDetails = async (page) => {
      const { sortKey, sortType } = this.state;
      await this.props.setPaginationPage(page - 1);
      this.props.getPartnerList(
        this.state.searchedValue,
        page - 1,
        10,
        sortKey,
        sortType
      );
    };
    // search location list
    searchPartner = (value) => {
      const { sortKey, sortType } = this.state;
      this.setState({ searchedValue: value });
      setTimeout(
        () => this.props.getPartnerList(value, 0, 10, sortKey, sortType, false),
        500
      );
    };

    // open add/update location modal
    addEditPartnerPage = async (status, data) => {
      if (status === "Edit") {
        await this.setState({
          partnerData: data,
          editPartnerStatus: true,
        });
      }
      await this.setState({
        addPartnerHocStatus: !this.state.addPartnerHocStatus,
      });
    };

    // open delete sponsor modal
    deletePartnerPage = async (status, data) => {
      let {
          searchedValue,
          addRemoveDeleteModal,
          sortKey,
          sortType,
        } = this.state,
        { partners, paginationPage } = this.props;
      if (status) {
        await this.props.deletePartner(data.id);
        await this.props.getPartnerList(
          searchedValue,
          getPageNumber(partners.totalCount, paginationPage),
          10,
          sortKey,
          sortType
        );
      } else await this.setState({ partnerData: data });
      await this.setState({ addRemoveDeleteModal: !addRemoveDeleteModal });
    };

    handleScrolledList = async () => {
      let { totalPages, currentPage, totalCount } = this.props.plans;
      if (totalCount > 50 && currentPage + 1 < totalPages) {
        await this.props.getPricingList("", currentPage + 1, 50);
        this.setState({
          scrolledList: [...this.state.scrolledList, ...this.props.plans.list],
        });
      }
    };

    emptyState = async (status) => {
      if (status) await this.setState({ searchedValue: "" });
      await this.setState({
        addPartnerHocStatus: false,
        addRemoveDeleteModal: false,
        editPartnerStatus: false,
        partnerData: null,
        sortKey: null,
        sortType: null,
      });
    };

    render() {
      const {
          partners,
          plans,
          paginationPage,
          getPartnerList,
          createPartner,
          updatePartner,
          getPricingList,
          setAttrName,
          attriName,
          loading,
        } = this.props,
        {
          addPartnerHocStatus,
          editPartnerStatus,
          partnerData,
          searchedValue,
          addRemoveDeleteModal,
          scrolledList,
        } = this.state;
      return (
        <>
          <WrapperComponent
            heading="PARTNERS"
            addButton="Partner"
            displayPage={this.addEditPartnerPage} // open add location modal and edit modal in location list
            headerList={PARTNERS_HEADERS}
            data={partners}
            searchListDetails={this.searchPartner}
            getPaginationDetails={this.getPartnerDetails}
            addRemoveDeleteModal={addRemoveDeleteModal}
            deleteDataDetails={partnerData} //sponsor row value  to be deteled
            openDeleteModal={this.deletePartnerPage} // open delete location modalin location list
            numberType="text"
            sortList={this.sortPartnerList}
            showStatus={true}
            searchedValue={searchedValue}
          />
          <AddEditPartnerModal
            plans={plans}
            createPartner={createPartner}
            partnerData={partnerData}
            updatePartner={updatePartner}
            searchedValue={searchedValue}
            getPartnerList={getPartnerList}
            paginationPage={paginationPage}
            emptyState={this.emptyState}
            addEditPartnerPage={this.addEditPartnerPage}
            addPartnerHocStatus={addPartnerHocStatus}
            editPartnerStatus={editPartnerStatus}
            getPricingList={getPricingList}
            handleScrolledList={this.handleScrolledList}
            scrolledList={scrolledList}
            setAttrName={setAttrName}
            attriName={attriName}
          />
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    partners: state.partnerList || {},
    plans: state.pricingList || {},
    paginationPage: state.paginationPage.paginationPage,
    attriName: state.attriName.attriName,
    loading: state.loaderStatus.loading,
  });

  return connect(mapStateToProps, actions)(PartnerParent);
};

export default PartnerHoc;
