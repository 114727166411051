import React from "react";
import * as constants from "../constants";
import { BLINTZ_LOGO } from "../constants";

const Banner = () => {
  return (
    <div className="login-banner">
      <div className="black-shadow"></div>
      <img alt="login banner" className="img-responsive" src={BLINTZ_LOGO} />
    </div>
  );
};

export default Banner;
