import React from 'react';
import ReactJson from 'react-json-view';
import { Modal } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { MESSAGE } from '../../constants';

const AddEditConfig = props => {
  let {
    updatedConfigDetails,
    editJboxStatus,
    hideShowConfig,
    openSaveConfigModal,
    isdetailPage,
    updateRawText,
    handleChangeTab,
    updatedRawConfigDetails,
    hideShowErr
  } = props;
  return (
    <Modal
      show={hideShowConfig}
      className='form-modal addeditconfigmodal'
      onHide={() => openSaveConfigModal(null, null, true)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {editJboxStatus ? 'Edit Config' : 'Add Config'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={`simple-text ${hideShowErr}`}
          style={hideShowErr && { display: "block" }}>
          {`${hideShowErr === 'green-text' ?
            MESSAGE.VALID_JSON : (hideShowErr === 'red-text') ?
              MESSAGE.INVALID_JSON : ''}`} </p>
        <Tabs
          defaultActiveKey='Pretty'
          className='custom-tabs-wrapper'
          onSelect={_ => handleChangeTab()}
        >
          <Tab eventKey='Pretty' title='Pretty'>
            <ReactJson
              src={updatedConfigDetails}
              iconStyle='circle'
              indentWidth='5'
              collapsed={false}
              onAdd={add => openSaveConfigModal(add, false)}
              onEdit={edit => openSaveConfigModal(edit, false)}
            />
          </Tab>
          <Tab eventKey='Raw' title='Raw'>
            <textarea style={{ width: '100%', height: '1000px' }}
              disabled={isdetailPage ? true : false}
              onChange={(e) => updateRawText(e)}
              value={updatedRawConfigDetails}>
            </textarea>
          </Tab>
        </Tabs>
      </Modal.Body>
      <div className='modal-footer'>
        <button
          onClick={() => openSaveConfigModal(null, null, true)}
          className='btn secondary-btn primary-main'
          data-dismiss='modal'
        >
          Cancel
        </button>
        {isdetailPage ? (
          false
        ) : (
            <button
              onClick={() => openSaveConfigModal(null, true, true)}
              className='btn primary-btn primary-main btn-right-gap'>
              {editJboxStatus ? 'Update' : 'Add'}
            </button>
          )}{' '}
      </div>
    </Modal >
  );
};

export default AddEditConfig;
