import { beginLoaderCall, endLoaderCall } from ".";
import {
  getLocations,
  getLocationInfo,
  addLocationInfo,
  getLatLongOfAddressApi,
  updateLocationInfo,
  deleteLocationInfo,
  getCountriesApi,
  getStatesApi,
  getCitiesApi,
} from "../services";
import {
  LOCATION_LIST,
  LOCATION_DETAILS,
  LOCATION_CREATED,
  LOCATION_DELETED,
  LOCATION_UPDATED,
  LAT_LONG,
  LOCATION_COUNTRIES,
  LOCATION_STATES,
  LOCATION_CITIES,
  LOCATION_CSV,
} from "../actions";
import {
  successToast,
  errorToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

export const getLocationList = (
  search = "",
  page = 0,
  offset = 10,
  status = true,
  searchKey = null,
  searchValue = null,
  isCsv = null
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    // getLocations(search, page, offset, searchKey, searchValue)
    //   .then(response => {
    //     dispatch(endLoaderCall());
    //     dispatch({
    //       type: isCsv ? LOCATION_CSV : LOCATION_LIST,
    //       payload: response
    //     });
    //     resolve(true);
    //   })
    //   .catch(() => {
    //     dispatch(endLoaderCall());
    //     reject(false);
    //   });
    reject(false);
  });
};

/**
 * Action for create location details
 */
export const createLocation = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addLocationInfo(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: LOCATION_CREATED,
          payload: response,
        });
        successToast(1, MESSAGE.LOC_CREATE);
        resolve(true);
      })
      .catch((error) => {
        if (error.response.data.status === 400) errorToast(1);
        else errorToast(1, MESSAGE.LOC_CREATE_ERROR);
        dispatch(endLoaderCall());
        reject(error);
      });
  });
};

/**
 * Action for get location details
 */
export const getLocationDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getLocationInfo(id)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: LOCATION_DETAILS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for update blintzbox details
 */
export const updateLocationDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updateLocationInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: LOCATION_UPDATED,
          payload: response,
        });
        successToast(1, MESSAGE.LOC_UPDATE);
        resolve(true);
      })
      .catch((error) => {
        if (error.response.data.status === 400)
          errorToast(1, MESSAGE.LOC_INAVLID_NAME);
        else errorToast(2, MESSAGE.LOC_UPDATE);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for delete blintzbox details
 */
export const deleteLocationDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    deleteLocationInfo(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: LOCATION_DELETED,
          payload: id,
        });
        successToast(2, MESSAGE.LOC_DELETE);
        resolve(true);
      })
      .catch((error) => {
        errorToast(2, error.response.data.message);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action get address lattitue longitute
 */
export const getLatLongOfAddress = (address) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    getLatLongOfAddressApi(address)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: LAT_LONG,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action get countries
 */
export const getCountries = (search = "", page = 0, offset = 1000) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    getCountriesApi(search, page, offset)
      .then((response) => {
        dispatch({
          type: LOCATION_COUNTRIES,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};

/**
 * Action get countries
 */
export const getStates = (id, search = "", page = 0, offset = 10000000) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    getStatesApi(id, search, page, offset)
      .then((response) => {
        dispatch({
          type: LOCATION_STATES,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};

/**
 * Action get countries
 */
export const getCities = (id, search = "", page = 0, offset = 10000000) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    getCitiesApi(id, search, page, offset)
      .then((response) => {
        dispatch({
          type: LOCATION_CITIES,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};
