import React from 'react';

import { SUCCESS_ICON, SIGN_IN } from '../../constants';
import Banner from '../Banner';

const Success = props => {
  const email = props.location.state && props.location.state.email;
  return (
    <div className='signin-wrapper'>
      <div className='main-container signup-section'>
        <div className='signin-container'>
          <Banner />
          <form className='login-container'>
            <div className='heading-wrap text-center'>
              <img
                className='mgb30 success-img'
                src={SUCCESS_ICON}
                alt='Success images'
              />

              <h1>{email ? 'Email Sent' : 'Password Changed'} Successfully!</h1>
              {email ? (
                <span className='caption'>
                  We have sent a reset password email to{' '}
                  <a className='extra-bold' href={`mailto:${email}`}>
                    {email}
                  </a>
                  . Please click the reset password link to set your new
                  password.
                </span>
              ) : (
                <span>
                  Congratulations! Your password has been
                  <br />
                  successfully changed.{' '}
                </span>
              )}
            </div>
            <div className='form-action center form-action-mb'>
              <button
                className='btn primary-btn'
                onClick={() => props.history.push(SIGN_IN)}
              >
                Back to Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Success;
