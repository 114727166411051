import React, { Fragment, useEffect, useState } from "react";
import firebase from "../../../config/FirebaseConfig";
import { useDispatch } from "react-redux";
import { firestore } from "firebase";
import Header from "../../rightPanel/Header";
import { AssemblyContainer } from "../assembly/AssemblyContainer";
import { AUDIO_SOUND_PLAY } from "../../../constants/images";
import useSound from "use-sound";
import { useCallback } from "react";

const soundUrl = AUDIO_SOUND_PLAY;

const WorkTable = (props) => {
  const { heading, tablist, data, listOrders } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [play, { stop }] = useSound(soundUrl, { volume: 1 });

  const handleClick = async (key, liveStatus) => {
    const datahave = selectedItems.filter((data) => key === data.key);
    if (datahave.length > 0) {
      const newArray = selectedItems.filter((item) => item.key !== key);
      setSelectedItems(newArray);
    } else {
      setSelectedItems([...selectedItems, { key, liveStatus }]);
    }
  };

  useEffect(() => {
    setOrderList(listOrders);
  }, [listOrders, play, stop]);

  useEffect(() => {
    if (listOrders.length > orderList.length) {
      play();
    }
  }, [orderList, play, stop, orderList.length, listOrders.length]);

  const startPreparing = () => {
    selectedItems.forEach((element, index) => {
      updateStatus(element.liveStatus, element.key);
      if (index + 1 === selectedItems.length) {
        setSelectedItems([]);
      }
    });
  };

  const updateStatus = async (liveStatus, key) => {
    let status = null;
    if (liveStatus === "RECEIVED") {
      status = "PREPARING";
    }
    if (status === null) {
      return;
    }
    console.log("key", key, status);
    try {
      // const batch = firebase.firestore().batch()
      await firebase.firestore().collection("orders").doc(key).update({
        liveStatus: status,
      });
    } catch (e) {
      console.log("ERROR IN SAVE", e);
    }
  };

  return (
    <Fragment>
      <Header heading={heading} data={data} />
      <div className="row" style={{ marginTop: "20px" }}>
        {orderList.map((item, index) => {
          const key = "assembly_" + item.cellKey + (index + Math.random());
          const datahave = selectedItems.filter(
            (data) => item.key === data.key
          );
          return (
            <AssemblyContainer
              type={"worktable"}
              handleClick={handleClick}
              order={item}
              key={key}
              isContain={datahave && datahave.length > 0}
            />
          );
        })}
      </div>
      <div
        style={{
          height: 70,
          marginTop: 30,
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <button
          onClick={startPreparing}
          disabled={selectedItems.length < 1}
          style={{
            backgroundColor: selectedItems.length > 0 ? "green" : "gray",
            width: 140,
            height: 55,
            borderRadius: 4,
            color: "white",
            fontWeight: "800",
            fontSize: 20,
          }}
        >
          Ready
        </button>
      </div>
    </Fragment>
  );
};

export default WorkTable;
