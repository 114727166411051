import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import * as constants from '../../constants';

class AssignPromoCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      enableButton: true,
      amount: '',
      expiredAt: '',
      maxUseLimit: '',
      availableToAll: false,
      clickName: false,
      clickAmount: false,
      clickDate: false,
      clickLimit: false,
      minimumDate: new Date(),
      classStatus: {...constants.promoCodeClass}
    };
  }
  componentDidMount() {
    const { minimumDate } = this.state;
    const x = 2147483648;
    const promocode =
      Math.floor(Math.random() * x).toString(36) +
      Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);
    this.setState({
      name: promocode.toUpperCase(),
      minimumDate: minimumDate.setDate(minimumDate.getDate() + 1)
    });
  }
  handleSubmit = () => {
    const { handleAssignPromoCode, userDetailId } = this.props;
    const { name, amount, expiredAt, maxUseLimit, availableToAll } = this.state;
    const newAmount = amount.replace('$ ', '');
    const newMaxLimit = maxUseLimit;
    const dataString = {
      name,
      amount: parseInt(newAmount).toString(),
      expiredAt: moment(expiredAt)
        .utc()
        .format(),
      userId: Number(userDetailId),
      maxUseLimit: newMaxLimit === '' ? 0 : parseInt(newMaxLimit),
      availableToAll
    };
    handleAssignPromoCode(dataString);
  };

  updateClassName = async (e) => {
    const newName = e.target.name;
    let { setAttrName, attriName } = this.props;
    const { classStatus, name, amount, expiredAt, maxUseLimit } = this.state;
    let promoData = {
      clickName: name,
      clickAmount: amount,
      clickDate: expiredAt,
      clickLimit: maxUseLimit
    }
    if (newName !== attriName) {
      setAttrName(newName);
      let data = constants.getHtmlClass(promoData, classStatus, attriName, newName)
      await this.setState(prevState => ({
        classStatus: { ...prevState['classStatus'], data }
      }));
    }
  };

  assignValue = (field, newValue) => {
    let valueReturn = '';
    switch (field) {
      case 'name':
        valueReturn = newValue.toUpperCase();
        break;
      case 'amount':
        if (newValue.length > 0) valueReturn = '$ ' + newValue;
        else valueReturn = newValue;
        break;
      default:
        valueReturn = newValue
    }
    return valueReturn;
  };
  handleReqexCheck = (regex, newValue, field) => {
    let value = '';
    if (
      (newValue.length > 0 && regex.test(newValue)) ||
      newValue.length === 0
    ) {
      value = this.assignValue(field, newValue);
    } else {
      value = this.state[field];
    }
    return value;
  };
  handleChange = (e, field) => {
    let value = '';
    if (field === 'name') {
      const newValue = e.target.value;
      value = this.handleReqexCheck(constants.promocodeReqex, newValue, field);
    } else if (field === 'availableToAll') {
      value = e.target.checked;
    } else if (field === 'expiredAt') {
      value = new Date(e);
    } else if (field === 'amount') {
      const newValue = e.target.value.replace('$ ', '');
      value = this.handleReqexCheck(constants.discountRegex, newValue, field);
    } else {
      const upcomingValue = e.target.value;
      const newValue = upcomingValue
      value = this.handleReqexCheck(constants.maxLimitRegex, newValue, field);
    }
    this.setState(
      {
        [field]: value
      },
      () => {
        this.enableCheck();
      }
    );
  };
  enableCheck = () => {
    const { name, amount, expiredAt } = this.state;
    this.setState({
      enableButton: name && amount && expiredAt ? false : true
    });
  };
  render() {
    const { openModal, openPromoCodeModal } = this.props;
    const {
      name,
      enableButton,
      amount,
      expiredAt,
      maxUseLimit,
      availableToAll,
      minimumDate,
      classStatus
    } = this.state;
    return (
      <Modal
        show={openModal}
        className='assign-promo-modal form-modal'
        onHide={openPromoCodeModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign PromoCode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <div className={`floating-input ${constants.getClassName(
                classStatus.clickName.status,
                null, classStatus.clickName.isFilled
              )}`}>
              <label>Promo Code</label>
              <input
                type='text'
                className='form-control'
                name='clickName'
                value={name}
                onChange={e => this.handleChange(e, 'name')}
                onFocus={e => this.updateClassName(e)}
              />
            </div>
          </div>
          <div className='form-group'>
            <div
              className={`floating-input ${constants.getClassName(
                classStatus.clickAmount.status,
                null, classStatus.clickAmount.isFilled
              )}`}
            >
              <label>Dollar Amount</label>
              <input
                type='text'
                className='form-control'
                name='clickAmount'
                value={amount}
                onChange={e => this.handleChange(e, 'amount')}
                onFocus={e => this.updateClassName(e)}
              />
            </div>
          </div>
          <div className='form-group calendar-form-group'>
            <div
              className={`floating-input ${constants.getClassName(
                classStatus.clickDate.status,
                null, classStatus.clickDate.isFilled
              )}`}
            >
              <label>Valid Upto</label>
              <DatePicker
                dateFormat='MMM dd, yyyy'
                name='clickDate'
                className='form-control custom-date-picker'
                onChange={e => this.handleChange(e, 'expiredAt')}
                selected={expiredAt}
                onFocus={e => this.updateClassName(e)}
                minDate={minimumDate}
              />
            </div>
          </div>
          <div className='form-group'>
            <div
              className={`floating-input ${constants.getClassName(
                classStatus.clickLimit.status,
                null, classStatus.clickLimit.isFilled
              )}`}
            >
              <label>No of Time Redeem</label>
              <input
                type='text'
                className='form-control'
                name='clickLimit'
                value={maxUseLimit}
                onChange={e => this.handleChange(e, 'maxUseLimit')}
                onFocus={e => this.updateClassName(e)}
              />
            </div>
          </div>
          <div className='custom-checkbox'>
            <label>
              <input
                type='checkbox'
                value={availableToAll}
                onChange={e => this.handleChange(e, 'availableToAll')}
              />
              <span>Assign to All Users</span>
            </label>
          </div>
        </Modal.Body>
        <div className='modal-footer'>
          <button
            className='btn secondary-btn primary-main'
            data-dismiss='modal'
            onClick={() => {
              openPromoCodeModal();
            }}
          >
            Cancel
          </button>
          <button
            className='btn primary-btn primary-main btn-right-gap'
            disabled={enableButton}
            onClick={() => this.handleSubmit()}
          >
            Send
          </button>
        </div>
      </Modal>
    );
  }
}

export default AssignPromoCodeModal;
