import {
  BLINTZBOX_LIST,
  BLINTZBOX_DETAIL,
  PAGINATION_PAGE,
  SIGNED_URL_DATA,
  ATTRIBUTE_NAME,
} from "../actions";

export const blintzboxList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === BLINTZBOX_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const blintzboxDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === BLINTZBOX_DETAIL) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};

export const paginationPage = (state = { paginationPage: 0 }, action) => {
  const { type, payload } = action;
  if (type === PAGINATION_PAGE) {
    return Object.assign({}, state, {
      paginationPage: payload,
    });
  } else {
    return state;
  }
};

export const signedUrl = (state = [], action) => {
  const { type, payload } = action;
  if (type === SIGNED_URL_DATA) {
    return payload ? [...state, payload.split("?")[0]] : [];
  } else {
    return state;
  }
};

export const attriName = (state = { attriName: "" }, action) => {
  const { type, payload } = action;
  if (type === ATTRIBUTE_NAME) {
    return {
      ...state,
      attriName: payload,
    };
  } else {
    return state;
  }
};
