import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import AssignCreditModal from "./AssignCreditModal";
import DefaultImage from "../../assets/images/svg/default-user.svg";
import AssignPromoCodeModal from "./AssignPromoCodeModal";
import { connect } from "react-redux";
import * as actions from "../../actions";
import isEmpty from "lodash/isEmpty";
import ListWrapper from "../listWrapper";
import Filters from "../rightPanel/filters";
import BookingDetail from "./BookingDetail";
import Header from "../rightPanel/Header";
import {
  BOOKING_ROUTE,
  COMING_USER_HEADERS,
  PAST_USER_HEADERS,
  CANCEL_USER_HEADERS,
  UPCOMING_BOOKING,
  BOOKING_STATUS,
  getActionValue,
} from "../../constants";
import moment from "moment-timezone";
import history from "../../store/history";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openCodeModal: false,
      bookingDetailId: null,
      refundModalStatus: false,
      bookingTabVal: history.location.state
        ? history.location.state.bookingTabVal
        : "current",
      bookingData: null,
      openCancelModal: false,
      bookingDetailsHeader: UPCOMING_BOOKING,
      addRemoveDeleteModal: false,
      sortKey: "sortByStartDate",
      sortType: "DESC",
      upcomingSearch: "",
      pastSearch: "",
      cancelledSearch: "",
      dateType: { startDate: "", endDate: "" },
      businessPartnerId: null,
      currentSearch: "",
    };
    this.initialState = this.state;
  }

  componentWillUnmount() {
    this.props.emptyState();
  }

  async componentDidMount() {
    const { getBookingList, getUserDetails, userDetailId } = this.props;
    const { sortKey, sortType } = this.state;
    await getUserDetails(userDetailId, true);
    let state = history.location.state
      ? [history.location.state.bookingTabVal]
      : ["current"];
    getBookingList(
      actions.BOOKING_CURRENT,
      state,
      0,
      10,
      sortKey,
      sortType,
      userDetailId
    );
  }
  openCreditModal = async () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };
  openPromoCodeModal = async () => {
    this.setState({
      openCodeModal: !this.state.openCodeModal,
    });
  };
  handleAssignCredit = async (amount) => {
    const { assignCredit, userDetailId } = this.props;
    await assignCredit({ userId: userDetailId, amount });
    this.openCreditModal();
    await this.props.getUserDetails(
      this.props.userDetailId,
      true,
      userDetailId
    );
  };
  handleAssignPromoCode = async (data) => {
    const { assignPromoCode } = this.props;
    await assignPromoCode(data);
    this.openPromoCodeModal();
  };
  topView = (data) => {
    return (
      <div className="user-info-top-wrapper">
        <div className="user-info-head-section">
          <h3 className="user-info-heading">
            {data.firstName || "N/A"} {`${data.lastName ? data.lastName : ""}`}
          </h3>
          <p className="user-info-text">{data.email || "N/A"}</p>
        </div>
        <div className="user-info-top-right">
          <button
            className="btn primary-btn btn-block"
            onClick={this.openPromoCodeModal}
          >
            Assign Promo Code
          </button>
          <button
            className="btn primary-btn btn-block"
            onClick={this.openCreditModal}
          >
            Assign Credits
          </button>
        </div>
      </div>
    );
  };
  addressView = (data) => {
    return (
      <div className="admin-detail-small-boxex-row">
        <div className="admin-detail-small-box">
          <h3>Address</h3>
          <p>{(!isEmpty(data.address) && data.address.address) || "N/A"}</p>
        </div>
        <div className="admin-detail-small-box">
          <h3>City</h3>
          <p>{(!isEmpty(data.address) && data.address.city) || "N/A"}</p>
        </div>
        <div className="admin-detail-small-box">
          <h3>State</h3>
          <p>{(!isEmpty(data.address) && data.address.state) || "N/A"}</p>
        </div>
      </div>
    );
  };

  getBookingDetails = async (page, filter, action) => {
    const { sortKey, sortType } = this.state;
    await this.props.setPaginationPage(page - 1);
    this.props.getBookingList(
      action,
      [filter],
      page - 1,
      10,
      sortKey,
      sortType,
      this.props.userDetailId,
      "",
      "",
      this.state[`${filter}Search`]
    );
  };

  handleChangeTab = (filter) => {
    const { sortKey, sortType } = this.state;
    this.props.setPaginationPage(0);
    const action = getActionValue(filter, filter);
    this.props.getBookingList(
      action,
      [filter],
      0,
      10,
      sortKey,
      sortType,
      this.props.userDetailId,
      "",
      "",
      this.state[`${filter}Search`]
    );
    this.setState({ bookingTabVal: filter });
  };

  async showDetails(data, header) {
    let {
      getUserDetails,
      getBookingDetails,
      getBookingPriceDetails,
    } = this.props;
    await getBookingDetails(data.id);
    getUserDetails(this.props.bookingDetails.userId, true);
    getBookingPriceDetails(data.id);
    this.setState({
      bookingData: this.props.bookingDetails,
      bookingDetailId: this.props.bookingDetails.id,
      bookingDetailsHeader: [header, this.props.bookingDetails.id],
      dateType: { startDate: "", endDate: "" },
      businessPartnerId: null,
    });
  }

  sortBookingList = async (header, type) => {
    const { bookingTabVal } = this.state;
    let headerKey;
    switch (header) {
      case "Booking Start Date":
        headerKey = "sortByStartDate";
        break;
      case "blintzbox Name":
        headerKey = "sortByName";
        break;
      case "Payment Value":
        headerKey = "sortByAmount";
        break;
      case "Refund Amount":
        headerKey = "sortByAmount";
        break;
      default:
        headerKey = "sortByStatus";
    }
    this.setState({ sortKey: headerKey, sortType: type });
    const value = bookingTabVal !== "" ? bookingTabVal : "upcoming";
    const action = getActionValue(value, bookingTabVal);
    this.props.getBookingList(
      action,
      [value],
      0,
      10,
      headerKey,
      type,
      this.props.userDetailId,
      "",
      "",
      this.state[`${value}Search`]
    );
  };

  searchBooking = async (value, searchListName) => {
    // search booking list
    const { sortKey, sortType, businessPartnerId } = this.state;
    this.setState({ [`${searchListName}Search`]: value });
    const action = getActionValue(searchListName, searchListName);
    setTimeout(
      this.props.getBookingList(
        action,
        [searchListName],
        0,
        10,
        sortKey,
        sortType,
        this.props.userDetailId,
        "",
        "",
        value,
        businessPartnerId,
        false
      ),
      1000
    );
  };

  cancelBooking = async (status, data, filter) => {
    let {
      addRemoveDeleteModal,
      openCancelModal,
      sortKey,
      sortType,
      upcomingSearch,
    } = this.state;
    const { userDetailId } = this.props;
    if (status) {
      await this.props.deleteBookingDetails(data.id);
      await this.props.getBookingList(
        "BOOKING_UPCOMMING",
        ["upcoming"],
        0,
        10,
        sortKey,
        sortType,
        userDetailId,
        "",
        "",
        upcomingSearch
      );
    } else await this.setState({ bookingData: data });
    await this.setState({
      addRemoveDeleteModal: !addRemoveDeleteModal,
      openCancelModal: !openCancelModal,
    });
  };

  openRefundModal = async (status, data, type, amount) => {
    const { refundBooking, getRefundDetails } = this.props;
    const { refundModalStatus } = this.state;
    const finalAmount = amount && amount.replace("$", "");
    if (status) {
      await refundBooking(data.id, {
        type: type,
        amount: parseFloat(finalAmount),
      });
    } else data && (await getRefundDetails(data.id));
    await this.setState({ bookingData: data });
    this.setState({ refundModalStatus: !refundModalStatus });
  };

  emptyState = async () => {
    await this.setState(this.initialState);
  };

  getDate = (value) => {
    let date = moment(value),
      dayN = date.format("D"),
      day = date.year(value, '"YYYY-MM-DD HH:mm:ss"').format("dddd"),
      month = moment(value)
        .subtract(0, "month")
        .startOf("month")
        .format("MMMM"),
      year = date.format("YYYY"),
      time = date.format("HH:mm A");
    return `${day.substring(0, 3)}, ${month.substring(
      0,
      3
    )} ${dayN} ,${year} ,${time}`;
  };

  countryView = (data) => {
    return (
      <div className="admin-detail-small-boxex-row">
        <div className="admin-detail-small-box">
          <h3>Country</h3>
          <p>{(!isEmpty(data.address) && data.address.country) || "N/A"}</p>
        </div>
        <div className="admin-detail-small-box">
          <h3>Zip Code</h3>
          <p>{(!isEmpty(data.address) && data.address.zipcode) || "N/A"}</p>
        </div>
        <div className="admin-detail-small-box">
          <h3>Phone Number</h3>
          <p>
            {data.phone
              ? ((data.countryCode || "") + " " + (data.phone || "")).replace(
                  /[()-]/gi,
                  ""
                )
              : "N/A"}
          </p>
        </div>
      </div>
    );
  };

  getFilteredList = async (e, action, type) => {
    const { sortKey, sortType, dateType } = this.state;
    this.setState({ businessPartnerId: e.value });
    this.props.getBookingList(
      type,
      [action],
      0,
      10,
      sortKey,
      sortType,
      null,
      dateType.startDate,
      dateType.endDate,
      this.state[`${action}Search`],
      e.value
    );
    await this.props.getBookingList(
      "BOOKING_CSV",
      [action],
      0,
      100000,
      sortKey,
      sortType,
      null,
      dateType.startDate,
      dateType.endDate,
      this.state[`${action}Search`],
      e.value
    );
    this.bookingNewData(action);
  };

  detail = (data) => {
    return (
      <div className="admin-right-detail-box user-management-detail-detail-box">
        <div className="user-detail-box">
          <div className="user-detail-box-left">
            <div className="user-detail-image-box">
              <img src={data.profilePic || DefaultImage} alt="user" />
            </div>
          </div>
          <div className="user-detail-box-right">
            {this.topView(data)}
            {this.addressView(data)}
            {this.countryView(data)}
            <div className="admin-detail-small-boxex-row">
              <div className="admin-detail-small-box">
                <h3>Number of bookings made</h3>
                <p>{data.totalBooking || "0"}</p>
              </div>
              <div className="admin-detail-small-box">
                <h3>Credit Amount</h3>
                <p>${parseFloat(data.wallet).toFixed(2) || "0.0"}</p>
              </div>
              <div className="admin-detail-small-box">
                <h3>Status</h3>
                <p className={data.isActive ? "active" : "inactive"}>
                  {data.isActive ? "Active" : "Inactive"}
                </p>
              </div>
            </div>
            <div className="admin-detail-small-boxex-row">
              <div className="admin-detail-small-box">
                <h3>Signup date</h3>
                <p>{data.createdAt && this.getDate(data.createdAt)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleDateChange = async (date, dateTypeVal, action, type) => {
    await this.setState((prevState) => ({
      dateType: { ...prevState.dateType, [dateTypeVal]: date },
    }));
    let { dateType, sortKey, sortType, businessPartnerId } = this.state;
    this.props.getBookingList(
      type,
      [action],
      0,
      10,
      sortKey,
      sortType,
      null,
      dateType.startDate,
      dateType.endDate,
      this.state[`${action}Search`],
      businessPartnerId
    );
    await this.props.getBookingList(
      "BOOKING_CSV",
      [action],
      0,
      100000,
      sortKey,
      sortType,
      null,
      dateType.startDate,
      dateType.endDate,
      this.state[`${action}Search`],
      businessPartnerId
    );
    this.bookingNewData(action);
  };

  bookingNewData = (filter) => {
    const { bookingCsv } = this.props,
      userArray = [];
    bookingCsv.list &&
      bookingCsv.list.length !== 0 &&
      bookingCsv.list.forEach((ele) => {
        !filter || filter === "upcoming"
          ? userArray.push({
              id: ele.id,
              transactionId: ele.transactionId,
              datetimeStart: ele.datetimeStart,
              datetimeEnd: ele.datetimeEnd,
              name: ele.blintzbox.name || "N/A",
              amount: ele.amount,
              status: BOOKING_STATUS[ele.status],
            })
          : userArray.push({
              id: ele.id,
              transactionId: ele.transactionId,
              datetimeStart: ele.datetimeStart,
              datetimeEnd: ele.datetimeEnd,
              name: ele.blintzbox.name || "N/A",
              amount: ele.amount,
            });
      });
    this.setState({ csvData: userArray });
  };
  render() {
    const date = new Date();
    const data = this.props.userDetail;
    const {
      userDetailId,
      commingBooking,
      pastBooking,
      cancelBooking,
      paginationPage,
      bookingPriceDetails,
      bookingDetails,
      currentBooking,
      partnersAPi,
      setAttrName,
      attriName,
    } = this.props;
    const {
      openModal,
      openCodeModal,
      bookingDetailId,
      bookingDetailsHeader,
      bookingData,
      bookingTabVal,
      refundModalStatus,
      openCancelModal,
      addRemoveDeleteModal,
      upcomingSearch,
      pastSearch,
      cancelledSearch,
      dateType,
      currentSearch,
    } = this.state;
    return (
      <>
        {bookingDetailId ? (
          <>
            <Header
              heading="BOOKINGS"
              subHeading={bookingDetailsHeader}
              routeLink={BOOKING_ROUTE}
              bookingStatus={true}
              bookingTabVal={bookingTabVal}
            />
            <BookingDetail
              bookingDetails={bookingDetails || {}}
              userDetail={data || {}}
              bookingPriceDetails={bookingPriceDetails || []}
              emptyState={this.props.emptyState}
              bookingTabVal={bookingTabVal}
            />
          </>
        ) : (
          <>
            <div className="admin-right-detail-wrapper">
              {this.detail(data)}
              <div className="admin-right-data-wrapper user-management-detail-data-wrapper">
                <h2 className="user-management-detail-heading">Bookings</h2>
                <Tabs
                  defaultActiveKey={bookingTabVal}
                  onSelect={(e) => this.handleChangeTab(e)}
                  className="custom-tabs-wrapper"
                >
                  <Tab eventKey="current" title="CURRENT BOOKINGS">
                    <Filters
                      getFilteredList={this.getFilteredList}
                      // isCsvExport={true}
                      dateType={dateType}
                      partnersAPi={partnersAPi}
                      showDate={true}
                      isPartnerApiFilter={true}
                      action={actions.BOOKING_CURRENT}
                      handleDateChange={this.handleDateChange.bind(this)}
                      tablist={true}
                      searchListDetails={this.searchBooking}
                      bookingDetailsHeader={["CURRENT BOOKING", "current"]}
                      searchedValue={currentSearch}
                    />
                    <ListWrapper
                      paginationPage={paginationPage}
                      data={currentBooking || {}}
                      tablist={true}
                      headerList={COMING_USER_HEADERS}
                      showDetails={this.showDetails.bind(this)}
                      getPaginationDetails={this.getBookingDetails}
                      bookingDetailsHeader={["CURRENT BOOKING", "current"]}
                      action={actions.BOOKING_UPCOMMING}
                      removeFooter={true}
                      addRemoveDeleteModal={addRemoveDeleteModal}
                      openDeleteModal={this.cancelBooking}
                      openCancelModal={openCancelModal}
                      deleteDataDetails={bookingData}
                      actionStatus={true}
                      showStatus={true}
                      sortList={this.sortBookingList}
                    />
                  </Tab>
                  <Tab eventKey="upcoming" title={UPCOMING_BOOKING}>
                    <Filters
                      getFilteredList={this.getFilteredList}
                      // isCsvExport={true}
                      action={actions.BOOKING_UPCOMMING}
                      handleDateChange={this.handleDateChange.bind(this)}
                      dateType={dateType}
                      partnersAPi={partnersAPi}
                      showDate={true}
                      isPartnerApiFilter={true}
                      tablist={true}
                      searchListDetails={this.searchBooking}
                      bookingDetailsHeader={[UPCOMING_BOOKING, "upcoming"]}
                      searchedValue={upcomingSearch}
                    />
                    <ListWrapper
                      // isCsvExport={true}
                      paginationPage={paginationPage}
                      data={commingBooking || {}}
                      tablist={true}
                      headerList={COMING_USER_HEADERS}
                      showDetails={this.showDetails.bind(this)}
                      getPaginationDetails={this.getBookingDetails}
                      bookingDetailsHeader={[UPCOMING_BOOKING, "upcoming"]}
                      action={actions.BOOKING_UPCOMMING}
                      removeFooter={true}
                      addRemoveDeleteModal={addRemoveDeleteModal}
                      openDeleteModal={this.cancelBooking}
                      openCancelModal={openCancelModal}
                      deleteDataDetails={bookingData}
                      actionStatus={true}
                      showStatus={true}
                      sortList={this.sortBookingList}
                    />
                  </Tab>
                  <Tab eventKey="past" title="PAST BOOKINGS">
                    <Filters
                      getFilteredList={this.getFilteredList}
                      // isCsvExport={true}
                      dateType={dateType}
                      partnersAPi={partnersAPi}
                      showDate={true}
                      isPartnerApiFilter={true}
                      handleDateChange={this.handleDateChange.bind(this)}
                      tablist={true}
                      searchListDetails={this.searchBooking}
                      bookingDetailsHeader={["PAST BOOKING", "past"]}
                      searchedValue={pastSearch}
                      action={actions.BOOKING_PAST}
                    />
                    <ListWrapper
                      paginationPage={paginationPage}
                      data={pastBooking || {}}
                      headerList={PAST_USER_HEADERS}
                      getPaginationDetails={this.getBookingDetails}
                      tablist={true}
                      showDetails={this.showDetails.bind(this)}
                      bookingData={bookingData}
                      userBooking={false}
                      removeFooter={true}
                      openRefundModal={this.openRefundModal}
                      bookingDetailsHeader={["PAST BOOKING", "past"]}
                      action={actions.BOOKING_PAST}
                      actionStatus={false}
                      showStatus={false}
                      sortList={this.sortBookingList}
                    />
                  </Tab>
                  <Tab eventKey="cancelled" title="CANCELLED BOOKINGS">
                    <Filters
                      getFilteredList={this.getFilteredList}
                      // isCsvExport={true}
                      dateType={dateType}
                      partnersAPi={partnersAPi}
                      isPartnerApiFilter={true}
                      handleDateChange={this.handleDateChange.bind(this)}
                      showDate={true}
                      tablist={true}
                      searchListDetails={this.searchBooking}
                      bookingDetailsHeader={["CANCELLED BOOKING", "cancelled"]}
                      searchedValue={cancelledSearch}
                      action={actions.BOOKING_CANCELED}
                    />
                    <ListWrapper
                      partnersAPi={partnersAPi}
                      paginationPage={paginationPage}
                      removeFooter={true}
                      data={cancelBooking || {}}
                      getPaginationDetails={this.getBookingDetails}
                      showDetails={this.showDetails.bind(this)}
                      headerList={CANCEL_USER_HEADERS}
                      userBooking={false}
                      refundModalStatus={refundModalStatus}
                      openRefundModal={this.openRefundModal}
                      emptyRefundState={this.emptyState}
                      emptyState={this.emptyState}
                      bookingData={bookingData}
                      refundStatus={true}
                      actionStatus={true}
                      tablist={true}
                      bookingDetailsHeader={["CANCELLED BOOKING", "cancelled"]}
                      action={actions.BOOKING_CANCELED}
                      showStatus={true}
                      sortList={this.sortBookingList}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="admin-right-footer">
              <p className="admin-right-footer-text">
                © {date.getFullYear()} Blintzz, All Rights Reserved.
              </p>
            </div>
            {openModal && (
              <AssignCreditModal
                openModal={openModal}
                openCreditModal={this.openCreditModal}
                handleAssignCredit={this.handleAssignCredit}
              />
            )}
            {openCodeModal && (
              <AssignPromoCodeModal
                attriName={attriName}
                setAttrName={setAttrName}
                openModal={openCodeModal}
                openPromoCodeModal={this.openPromoCodeModal}
                userDetailId={userDetailId}
                handleAssignPromoCode={this.handleAssignPromoCode}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.userById || {},
  paginationPage: state.paginationPage.paginationPage,
  pastBooking: state.bookingList.pastBooking || {},
  commingBooking: state.bookingList.commingBooking || {},
  bookingCsv: state.bookingList.csvBooking || {},
  cancelBooking: state.bookingList.cancelBooking || {},
  bookingDetails: state.bookingDetails || {},
  bookingPriceDetails: state.bookingPriceDetails.bookingPriceDetails || [],
  currentBooking: state.bookingList.currentBooking || {},
  attriName: state.attriName.attriName,
});

export default connect(mapStateToProps, actions)(UserDetail);
