export const ADMIN_ROUTE = "/admin";
export const PRICING_ROUTE = ADMIN_ROUTE + "/pricing-plans";
export const BLINTZBOX_ROUTE = ADMIN_ROUTE + "/blintzbox";
export const DASHBOARD_ROUTE = ADMIN_ROUTE + "/dashboard";
export const AMENITIES_ROUTE = ADMIN_ROUTE + "/amenities";
export const LOCATION_ROUTE = ADMIN_ROUTE + "/locations";
export const SEASONAL_PROMO_CODE_ROUTE = ADMIN_ROUTE + "/seasonal-promo-code";
export const SPONSORS_ROUTE = ADMIN_ROUTE + "/sponsors";
export const PARTNER_ROUTE = ADMIN_ROUTE + "/partners";
export const BOOKING_ROUTE = ADMIN_ROUTE + "/booking";
export const ORDER_ROUTE = ADMIN_ROUTE + "/orders";
export const USER_ROUTE = ADMIN_ROUTE + "/users";
export const MENU_ROUTE = ADMIN_ROUTE + "/menu";
export const QR_ROUTE = ADMIN_ROUTE + "/qr-code";
export const FORGOT_PASSWORD = "/forgotPassword";
export const SUCCESS = "/success";
export const SIGN_IN = "/";
export const PARTNERS_API_ROUTE = ADMIN_ROUTE + "/partners-api";
export const NOPAGEFOUND = "/no-page-found";
