import React, { Component } from "react";
import { connect } from "react-redux";

import { PARTNERS_API_HEADER, getPageNumber } from "../constants";
import * as actions from "../actions";
import AddEditPartnerApiModal from "../components/blintzbox/AddEditPartnerApiModal";
import Loader from "../components/loader/Loader";

const PartnerApiHoc = (WrapperComponent) => {
  class PartnerApiParent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        searchedValue: "",
        editPartnerApiStatus: false,
        sortKey: null,
        sortType: null,
        partnerApiData: null,
        addRemoveDeleteModal: false,
        addPartnerApiHocStatus: false,
      };
      this.initialState = this.state;
      this.props.setPaginationPage(0);
    }

    async componentDidMount() {
      const { getPartnerApiList } = this.props;
      if (this.props.location.state) {
        await this.setState({
          searchedValue: this.props.location.state.search,
        });
      }
      getPartnerApiList(this.props.searchedValue);
    }

    searchPartnerApi = (value) => {
      this.setState({ searchedValue: value });
      setTimeout(
        () => this.props.getPartnerApiList(value, 0, 10, null, null, false),
        500
      );
    };

    sortPartnerApiList = async (header, type) => {
      let headerKey = "sortByCode";
      this.setState({
        sortKey: headerKey,
        sortType: type,
      });
      this.props.getPartnerApiList(
        this.state.searchedValue,
        0,
        10,
        headerKey,
        type
      );
    };

    addPartnerApiPage = async (status, data) => {
      if (status === "Edit") {
        await this.setState({
          partnerApiData: data,
          editPartnerApiStatus: true,
        });
      }
      await this.setState({
        addPartnerApiHocStatus: !this.state.addPartnerApiHocStatus,
      });
    };

    // open delete Pricing Plan modal
    deletePartnerApiPage = async (status, data) => {
      let {
        searchedValue,
        addRemoveDeleteModal,
        sortKey,
        sortType,
      } = this.state;
      if (status) {
        await this.props.deletePartnerApi(data.id);
        await this.props.getPartnerApiList(
          searchedValue,
          null,
          10,
          sortKey,
          sortType
        );
      } else await this.setState({ partnerApiData: data });
      await this.setState({ addRemoveDeleteModal: !addRemoveDeleteModal });
    };

    getPartnerApiDetails = async (page) => {
      await this.props.setPaginationPage(page - 1);
      this.props.getPartnerApiList(this.state.searchedValue, page - 1, 10);
    };
    emptyState = () => {
      this.setState(this.initialState);
    };

    render() {
      const {
          partnersAPi,
          createPartnerApi,
          getPartnerApiList,
          updatePartnerApi,
          loading,
        } = this.props,
        {
          searchedValue,
          addRemoveDeleteModal,
          partnerApiData,
          addPartnerApiHocStatus,
          editPartnerApiStatus,
        } = this.state;
      return (
        <>
          <WrapperComponent
            data={partnersAPi}
            addButton="Partner Api"
            heading="PARTNERS API"
            headerList={PARTNERS_API_HEADER}
            sortList={this.sortPartnerApiList}
            searchListDetails={this.searchPartnerApi}
            displayPage={this.addPartnerApiPage}
            addRemoveDeleteModal={addRemoveDeleteModal}
            openDeleteModal={this.deletePartnerApiPage}
            deleteDataDetails={partnerApiData}
            getPaginationDetails={this.getPartnerApiDetails}
            searchedValue={searchedValue}
            showStatus={true}
            isPartnerApi={true}
          />
          <AddEditPartnerApiModal
            addPartnerApiHocStatus={addPartnerApiHocStatus}
            addPartnerApiPage={this.addPartnerApiPage}
            editPartnerApiStatus={editPartnerApiStatus}
            partnerApiData={partnerApiData}
            updatePartnerApi={updatePartnerApi}
            openDeleteModal={this.deletePricingPage}
            createPartnerApi={createPartnerApi}
            getPartnerApiList={getPartnerApiList}
            emptyState={this.emptyState}
          />
          {loading && <Loader />}
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    partnersAPi: state.partnerApiList || {},
    loading: state.loaderStatus.loading,
  });

  return connect(mapStateToProps, actions)(PartnerApiParent);
};

export default PartnerApiHoc;
