import { PARTNERS_LIST, PARTNERS_DETAILS } from '../actions';

export const partnerList = (state = {}, action) => {
  const { type, payload } = action;
  if (type === PARTNERS_LIST) {
    return payload;
  } else {
    return state;
  }
};

export const partnerDetails = (state = {}, action) => {
  const { type, payload } = action;
  if (type === PARTNERS_DETAILS) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
