import { beginLoaderCall, endLoaderCall } from ".";
import {
  fetchPricingList,
  getPricingInfo,
  addPricingPlan,
  updatePricingInfo,
  deletePricingInfo,
} from "../services";
import {
  PRICING_PLANES_LIST,
  PRICING_PLAN_DETAIL,
  PRICING_PLAN_CREATED,
  PRICING_PLAN_REMOVED,
  PRICING_PLAN_UPDATED,
} from "../actions";
import {
  errorToast,
  successToast,
} from "../components/blintzbox/ToastNotification";
import { MESSAGE } from "../constants";

export const getPricingList = (
  search,
  page,
  offset,
  sortKey,
  sortType,
  status = true
) => (dispatch) => {
  if (status) dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    fetchPricingList(search, page, offset, sortKey, sortType)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PRICING_PLANES_LIST,
          payload: response,
        });
        resolve(true);
      })
      .catch(() => {
        reject(false);
        dispatch(endLoaderCall());
      });
  });
};

/**
 * Action for get Pricing plan details
 */
export const getPricingDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return getPricingInfo(id)
    .then((response) => {
      dispatch(endLoaderCall());
      dispatch({
        type: PRICING_PLAN_DETAIL,
        payload: response,
      });
    })
    .catch(() => {
      dispatch(endLoaderCall());
    });
};

/**
 * Action for create Pricing plan
 */
export const createPricingDetails = (paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    addPricingPlan(paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PRICING_PLAN_CREATED,
          payload: response,
        });
        resolve(response);
        successToast(1, MESSAGE.PRICE_CREATE);
      })
      .catch((error) => {
        if (error.response.data.status === 400)
          errorToast(1, MESSAGE.PRICE_INAVLID_NAME);
        else errorToast(1, MESSAGE.PRICE_CREATE_ERROR);
        dispatch(endLoaderCall());
        reject(error);
      });
  });
};

/**
 * Action for update Pricing plan
 */
export const updatePricingDetails = (id, paylaod) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    updatePricingInfo(id, paylaod)
      .then((response) => {
        dispatch(endLoaderCall());
        dispatch({
          type: PRICING_PLAN_UPDATED,
          payload: response,
        });
        resolve(response);
        successToast(1, MESSAGE.PRICE_UPDATE);
      })
      .catch((error) => {
        if (error.response.data.status === 400)
          errorToast(1, MESSAGE.PRICE_INAVLID_NAME);
        else errorToast(1, MESSAGE.PRICE_UPDATE_ERROR);
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};

/**
 * Action for delete Pricing plan
 */
export const deletePricingDetails = (id) => (dispatch) => {
  dispatch(beginLoaderCall());
  return new Promise((resolve, reject) => {
    deletePricingInfo(id)
      .then(() => {
        dispatch(endLoaderCall());
        dispatch({
          type: PRICING_PLAN_REMOVED,
          payload: id,
        });
        resolve(true);
        successToast(1, MESSAGE.PRICE_DELETE);
      })
      .catch(() => {
        dispatch(endLoaderCall());
        reject(false);
      });
  });
};
