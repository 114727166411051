import React, { Fragment, useEffect, useState } from "react";
import firebase from "../../../config/FirebaseConfig";
import { useDispatch } from "react-redux";
import { firestore } from "firebase";
import Header from "../../rightPanel/Header";
import { AssemblyContainer } from "../assembly/AssemblyContainer";

const MakeLine = (props) => {
  const { heading, tablist, data, listOrders, containerType } = props;

  const handleClick = async (key, liveStatus) => {
    console.log("key", key);
    let status = null;
    if (liveStatus === "PREPARING") {
      status = "BAKING";
    } else {
      return;
    }
    console.log("key", key, status);
    if (status === null) {
      return;
    }
    try {
      const response = await firebase
        .firestore()
        .collection("orders")
        .doc(key)
        .update({
          liveStatus: status,
        });
      console.log("[RESPOMSE ]", response);
    } catch (e) {
      console.log("ERROR IN SAVE", e);
    }
  };

  return (
    <Fragment>
      <Header heading={heading} data={data} />
      <div className="row" style={{ marginTop: "20px" }}>
        {listOrders.map((item, index) => {
          return (
            <AssemblyContainer
              containerType={containerType}
              handleClick={handleClick}
              order={item}
              key={"makeline_" + item.cellKey + index}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default MakeLine;
