import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { getClassName } from '../../constants';

function AddEditPriceModal(props) {
  // Declare a new state variable, which we'll call "pricingData"
  const [pricingData, setLocationState] = useState(props),
    [addressClass, setAddressClass] = useState(false),
    [nameClass, setNameClass] = useState(true);


  // sync props pricingData to state pricingData
  useEffect(() => {
    setLocationState(props.pricingData);
  }, [props]);

  // update textinputs
  const updateField = e => {
    let { value, name } = e.target;
    if (value.charAt(value.length - 1) !== '.' && /^\d*$/.test(value))
      setLocationState({ ...pricingData, [name]: value });
  };

  const addEditPricingPlan = async () => {
    const data = {
      duration: pricingData.duration,
      cost: pricingData.cost,
      name: `${pricingData.duration} min $${pricingData.cost}`
    };
    if (props.editPricingStatus)
      //editPricingStatus===true updating pricing plan
      await props.updatePricingDetails(props.pricingData.id, data);
    else await props.createPricingDetails(data);

    await props.getPricingList(props.searchedValue, props.paginationPage, 10);
    setNameClass('')
    setAddressClass('');
    props.emptyState();

  };

  const handleClose = () => {
    setNameClass('')
    setAddressClass('');
    props.emptyState();
  };

  return (
    <Modal
      show={props.addPriceHocStatus}
      className='add-location-modal form-modal'
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editPricingStatus ? 'Edit Plan' : 'Add Plan'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          <div
            className={`floating-input ${getClassName(
              nameClass,
              props.editPricingStatus
            )}`}
          >
            <label>Plan Duration (Minutes)</label>
            <input
              type='text'
              className='form-control edit-floating-input'
              onChange={e => updateField(e)}
              onFocus={() => setNameClass('active label-heightlight')}
              value={pricingData && pricingData.duration}
              name='duration'
            />
          </div>
        </div>
        <div className='form-group'>
          <div
            className={`floating-input ${getClassName(
              addressClass,
              props.editPricingStatus
            )}`}>
            <label>Plan Cost</label>
            <input
              type='text'
              className='form-control'
              onChange={e => updateField(e)}
              onFocus={() => setAddressClass('active label-heightlight')}
              value={pricingData && pricingData.cost}
              name='cost'
            />
          </div>
        </div>
      </Modal.Body>
      <div className='modal-footer'>
        <button
          className='btn secondary-btn primary-main'
          data-dismiss='modal'
          onClick={() => handleClose()}
        >
          Cancel
        </button>
        <button
          className='btn primary-btn primary-main btn-right-gap'
          disabled={
            pricingData && pricingData.duration && pricingData.cost
              ? false
              : true
          }
          onClick={() => addEditPricingPlan()}
        >
          {props.editPricingStatus ? 'Update' : 'Add'}
        </button>{' '}
      </div>
    </Modal>
  );
}

export default AddEditPriceModal;
